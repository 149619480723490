import {
  ButtonColor,
  ButtonComponent,
  ButtonSize,
  ButtonState,
  IconComponent,
  IconType,
  isEmpty
} from '@cck/common'
import { Spin } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ChatReference } from 'components/chat/message/ChatMessageReferenceComponent'
import { getMoveReferenceDocument } from 'components/chat/util/ChatUtil'
import { getContent } from 'services/standard_client'

interface ChatContentReferenceItemComponentProps {
  reference: ChatReference
}

const ChatContentReferenceItemComponent: React.FC<ChatContentReferenceItemComponentProps> = ({
  reference
}) => {
  const [isShowOption, setIsShowOption] = useState<boolean>(false)
  const [isMoreView, setIsMoreView] = useState<boolean>(false)
  const descriptionWrapperRef = useRef<HTMLDivElement>(null)
  const [content, setContent] = useState<string>()
  const navigate = useNavigate()

  useEffect(() => {
    if (descriptionWrapperRef.current) {
      setIsShowOption(
        descriptionWrapperRef.current.scrollHeight > descriptionWrapperRef.current.clientHeight
      )
    }
  }, [descriptionWrapperRef, content])

  useEffect(() => {
    if (descriptionWrapperRef.current) {
      setIsShowOption(
        descriptionWrapperRef.current.scrollHeight > descriptionWrapperRef.current.clientHeight
      )
    }

    getContent(reference.metadata.standard_id, reference.metadata.id)
      .then((res) => {
        const newContent = res.contents[0].content
        setContent(newContent)
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Failed to post request')
      })
  }, [descriptionWrapperRef, reference])

  if (isEmpty(content)) {
    return <Spin />
  }

  return (
    <div className="chat-content-sider-reference-wrapper">
      <div className="chat-content-sider-reference-title-wrapper">
        <div className="chat-content-sider-reference-index-wrapper">{`${reference.index}`}</div>
        <div className="chat-content-sider-reference-title">{reference.metadata.title}</div>
      </div>
      <div
        className={classNames({
          'chat-content-sider-reference-description-wrapper': true,
          'chat-content-sider-reference-description-short': !isMoreView
        })}
        ref={descriptionWrapperRef}
      >
        {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
      </div>
      {isShowOption && (
        <div
          className="chat-content-sider-reference-description-option-wrapper"
          onClick={() => {
            setIsMoreView((prev) => !prev)
          }}
        >
          {isMoreView ? '간략히 보기' : '더보기'}
        </div>
      )}
      <div className="chat-content-sider-reference-option-wrapper">
        <div>
          <ButtonComponent
            clickCallbackFunc={() => {
              const moveProps = getMoveReferenceDocument(reference.metadata)
              navigate('/standard/link', {
                state: moveProps
              })
            }}
            color={ButtonColor.MARINE}
            rightIcon={<IconComponent iconType={IconType.FOLDED_BOOK} size={18} />}
            size={ButtonSize.SMALL}
            states={ButtonState.NORMAL}
            text="기준서 원문 이동"
          />
        </div>
        <div>
          <ButtonComponent
            color={ButtonColor.MARINE}
            rightIcon={<IconComponent iconType={IconType.OPENED_BOOK} size={22} />}
            size={ButtonSize.SMALL}
            states={ButtonState.NORMAL}
            text="원문 맥락 파악"
          />
        </div>
      </div>
    </div>
  )
}

export default ChatContentReferenceItemComponent
