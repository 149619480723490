import { validate as validateEmail } from 'email-validator'

export function allEmptyStrings(arr: string[]): boolean {
  return arr.every((element) => element === '')
}

// 2차원 배열을 인자로 받아, 모든 요소들이 빈 스트링인 배열 요소를 제거
export function removeEmptyStringArrays(arr: string[][]): string[][] {
  return arr.filter((subArr) => !allEmptyStrings(subArr))
}

export const convertToBusinessNumber = (input: string): string => {
  const result = input.replace(/[\s-]/g, '')

  if (result.length < 4) {
    return result
  }
  if (result.length < 6) {
    return result.slice(0, 3) + '-' + result.slice(3)
  }
  return result.slice(0, 3) + '-' + result.slice(3, 5) + '-' + result.slice(5, 10)
}

export const convertToCorporateRegistrationNumber = (input: string): string => {
  const result = input.replace(/[\s-]/g, '')

  if (result.length < 6) {
    return result
  }
  return result.slice(0, 6) + '-' + result.slice(6, 13)
}

// https://www.npmjs.com/package/email-validator
// export const emailRegex =
// eslint-disable-next-line no-useless-escape, max-len
//   /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
// // backend email validation regex

// 사용성을 위해 input 시 zero space는 포함시키고 trim
// 체크 이후 서버에 반영하기 전에 zero space와 공백을 없애고 반영할 필요가 있음
export const emailVaildCheckBeforeInput = (email: string): boolean => {
  const trimedEmail = email.trim().replace(/\u200B/g, '')
  return validateEmail(trimedEmail)
}

export const formatEmailAddress = (email: string): string => {
  return email.trim().replace(/\u200B/g, '')
}
