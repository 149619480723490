import { LoadingOutlined } from '@ant-design/icons'
import { AutoComplete, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import _ from 'lodash'
import React, { useState, useRef } from 'react'

import { NewInputControlComponentProps } from 'components/input/NewInputControlComponent'
import { useCreateInput } from 'components/input/useCreateInput'
import { notEmpty } from 'utils/common_utils'

import './input.scss'
import './new_input.scss'

const getTextNodes = (node: React.ReactNode): string => {
  if (typeof node === 'string') {
    return node
  }
  if (Array.isArray(node)) {
    return node.map(getTextNodes).join(' ')
  }
  if (React.isValidElement(node)) {
    return getTextNodes(node.props.children)
  }
  return ''
}

const searchResult = (results: React.ReactNode[]) => {
  return _.map(results, (result) => {
    return {
      value: getTextNodes(result),
      label: <div className="auto-dropdown-item">{result}</div>
    }
  })
}

interface AutoCompleteInputComponentProps extends NewInputControlComponentProps {
  children?: React.ReactNode[]
  autoCompleteFunc?: (text: string) => Promise<React.ReactNode[]>
  autoCompleteDropdownCustomClassName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  completeAutoInputFunc?: (text: string, option?: any) => void
  shouldSelectFirstItemOnEnter?: boolean
  maxLength?: number
}

const NewAutoCompleteInputComponent: React.FC<AutoCompleteInputComponentProps> = ({
  children,
  initText,
  validateState,
  blurInputFunc,
  onKeyDownFunc,
  inputControlTypeProps,
  initFocus,
  inputType,
  autoCompleteFunc,
  changeInputFunc,
  completeAutoInputFunc,
  autoCompleteDropdownCustomClassName,
  shouldSelectFirstItemOnEnter = true,
  maxLength = 50
}) => {
  // ref variables for handle axios race condition
  const requestIdRef = useRef(0)
  const latestResponseIdRef = useRef(0)

  const [options, setOptions] = useState<SelectProps<object>['options']>(
    children ? searchResult(children) : []
  )
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined)
  const refInput = useRef(null)
  const antIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />
  const [callCount, setCallCount] = useState(0)
  const [receiveCount, setReceiveCount] = useState(0)
  const callAutoCompleteFunc = (value: string) => {
    if (!autoCompleteFunc) {
      return
    }

    if (timer) {
      clearTimeout(timer)
      setTimer(undefined)
    }

    requestIdRef.current += 1
    const currentRequestId = requestIdRef.current

    setTimer(
      setTimeout(async () => {
        setCallCount((prev) => prev + 1)
        const result = await autoCompleteFunc(value)
        if (currentRequestId > latestResponseIdRef.current) {
          setOptions(notEmpty(result) ? searchResult(result) : [])
          latestResponseIdRef.current = currentRequestId
        }

        setReceiveCount((prev) => prev + 1)
      }, 300)
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelect = (value: string, option: any) => {
    completeAutoInputFunc && completeAutoInputFunc(value, option)
    changeInputFunc && changeInputFunc(value)
    // callAutoCompleteFunc(value)
  }

  const handleChange = async (text: string) => {
    changeInputFunc && changeInputFunc(text)
    callAutoCompleteFunc(text)
  }

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const text = (e.target as HTMLInputElement).value
    if (!text) {
      return
    }

    if (!shouldSelectFirstItemOnEnter) {
      completeAutoInputFunc && completeAutoInputFunc(text)
      return
    }
    if (notEmpty(options) && options[0].value) {
      const newValue = options[0].value.toString()
      onSelect(newValue, options[0])
      return
    }

    completeAutoInputFunc && completeAutoInputFunc(text)
    // callAutoCompleteFunc(text)
  }
  const input = useCreateInput({
    inputControlTypeProps,
    initText,
    validateState,
    completeInputFunc: handleEnter,
    changeInputFunc: handleChange,
    blurInputFunc,
    onKeyDownFunc,
    initFocus,
    inputType,
    maxLength,
    needMaxLength: false
  })

  return (
    <div className="new-auto-input">
      <AutoComplete
        // allowClear
        className="new-auto-input-text-frame"
        dropdownMatchSelectWidth={252}
        maxLength={maxLength}
        options={options}
        popupClassName={`cck-auto-complete ${autoCompleteDropdownCustomClassName}`}
        ref={refInput}
        value={initText}
        onSelect={onSelect}
      >
        {input}
      </AutoComplete>
      {receiveCount !== callCount && (
        <div className="new-auto-input-loading-progress">
          <Spin indicator={antIcon} />
        </div>
      )}
    </div>
  )
}

export default NewAutoCompleteInputComponent
