import React from 'react'

import { IconType } from 'common/components/icon/IconBaseType'
import IconComponent from 'common/components/icon/IconComponent'
import NewInputControlComponent from 'components/input/NewInputControlComponent'
import { useNewInputControl, NewInputControlType } from 'components/input/useNewInputControl'

import './ccktable.scss'

type CCKEmptyTableComponentProps = {
  optionalButtons?: React.ReactNode
  backgroundColor?: string
  guideText?: string
}

// (hyeonseok 23.08.21):
// parent component에서 row data를 만들 데이터가 없을 경우
// 빈 테이블을 보여주기 위한 컴포넌트
const CCKEmptyTableComponent: React.FC<CCKEmptyTableComponentProps> = ({
  optionalButtons,
  backgroundColor,
  guideText
}) => {
  const inputControlTypeProps = useNewInputControl(NewInputControlType.SEARCH)

  return (
    <div className="cck-table-frame">
      <div className="cck-table-buttons-frame">
        <div className="cck-table-search-input">
          <NewInputControlComponent inputControlTypeProps={inputControlTypeProps} />
        </div>
        {optionalButtons}
      </div>
      <div className="table-content-empty" style={{ backgroundColor }}>
        <div className="table-content-empty-image">
          <IconComponent isSvg enable="enable" iconType={IconType.ACCIO_CRY} size={[93, 54]} />
        </div>
        <div className="table-content-empty-text">{guideText}</div>
      </div>
    </div>
  )
}

export default CCKEmptyTableComponent
