import { ColDef, Column, GridApi, RowNode } from 'ag-grid-community'
import React from 'react'

import { IconBaseComponentProps } from 'common/components/icon/IconBaseType'
import { ButtonComponentProps } from 'components/button/ButtonComponent'
import {
  LabelAgGridTypoStyle,
  LabelAhGridColor,
  LabelTextAlign
} from 'components/ccktable/control/LabelAgGridCellRendererComponent'
import { DragPosition } from 'components/ccktable/control/ModalAgGridCellEditorComponent'
import { RefinedLabelType } from 'components/ccktable/util/CCKTableUtil'
import { DropdownMenuItemComponentProps } from 'components/dropdown/DropdownMenuItemComponent'

// Ag-grid에서 사용하는 cellRenderer를 custom component로 wrapping하기 위한 type 정의
export const AgGridCellRenderer = {
  Checkbox: 'checkBox',
  Label: 'label',
  Dropdown: 'dropdown',
  SingleButton: 'singleButton',
  DoubleButton: 'doubleButton',
  Upload: 'upload',
  Custom: 'custom',
  Icon: 'icon',
  AntdDropdown: 'antdDropdown'
} as const

// export const AgGridCellEditor = {
//   Dropdown: 'dropdown'
// } as const

export type AgGridCellRenderer = typeof AgGridCellRenderer[keyof typeof AgGridCellRenderer]

export const AgGridCellEditor = {
  CCKBasic: 'CCKBasic',
  Modal: 'modal',
  AutoComplete: 'autoComplete',
  OnlyPaste: 'onlyPaste'
} as const

export type AgGridCellEditor = typeof AgGridCellEditor[keyof typeof AgGridCellEditor]

export const AgGridHeader = {
  CustomHeader: 'customHeader',
  CheckBoxHeader: 'checkBoxHeader'
} as const

export type AgGridHeader = typeof AgGridHeader[keyof typeof AgGridHeader]

export const AgGridDateEditor = {
  Date: 'date',
  DateRange: 'dateRange'
} as const

export type AgGridDateEditor = typeof AgGridDateEditor[keyof typeof AgGridDateEditor]

// ag-grid에서 Column Definition을 생성할 때 공통적으로 사용하는 Common Props
export interface CommonColumnDefProps extends ColDef {
  // TODO (jaegon 23.07.27)
  // CommonAgGridCellRendererProps에서 정의하는 것과 동일한 형태를 유지해야함. 추후 개선 필요
  // check specific cell is visiable or not
  callbackIsContentVisible?: (node: RowNode, column: Column) => boolean
  // check specific cell is disable or not
  callbackIsContentDisable?: (node: RowNode, column: Column) => boolean

  // view cell left border
  cellLeftBorder?: boolean

  // view column left border
  columnLeftBorder?: boolean

  // view cell bottom border highlight
  cellBottomBorderHighlight?: boolean

  customHeaderType?: AgGridHeader

  customColumnValue?: React.ReactNode

  isHeaderCenterAlign?: boolean

  isCellCenterAlign?: boolean
}

// Checkbox column definition 생성 시 사용하는 Props
export interface CheckboxColumnDefProps extends CommonColumnDefProps {
  checked?: boolean
  // Checkbox가 변경되었을 때 호출되는 callback
  // TODO (jaegon 23.07.27)
  // CheckBoxAgGridRendererComponent의 Prop에서 정의하는 것과 동일한 형태를 유지해야함. 추후 개선 필요
  callbackCheckboxChange?: (
    checked: boolean,
    gridApi: GridApi,
    targetColumnNode: RowNode,
    column: Column
  ) => void
  checkboxClickDisabled?: boolean
}

// Label column definition 생성 시 사용하는 Props
export interface LabelColumnDefProps extends CommonColumnDefProps {
  color?: LabelAhGridColor
  typoStyle?: LabelAgGridTypoStyle
  placeholder?: string | ((node: RowNode, column: Column) => string | undefined)
  postfixPlaceholder?: string
  refinedValueType?: RefinedLabelType
  // Render Params : Label의 색상을 변경하기 위한 callback
  getLabelClassNamesCallbackFunc?: (node: RowNode, column: Column) => LabelAhGridColor | undefined
  // Render Params : Row Data상의 값이랑 다른 값으로 Label을 표시하기 위한 callback
  convertValueCallbackFunc?: (value: string, node?: RowNode) => string
  // Editor Params : unique value check를 수행할지 여부
  checkUniqueValueInColumn?: boolean
  // Editor Params : input 값 validation check를 위한 callback
  isValidateCheckCallbackFunc?: (
    input: string,
    node: RowNode,
    column: Column,
    api: GridApi
  ) => boolean
  // Editor Params : input 값이 최종적으로 Cell에 입력되기 전애 보정하기 위한 callback
  refinedInputValueCallbackFunc?: (input: string, node: RowNode, column: Column) => string
  // Editor Params : input 값이 변경될 때마다 호출되는 callback
  changeInputValueFilterCallbackFunc?: (input: string, node: RowNode, column: Column) => string
  useModalEditor?: boolean
  isDraggable?: boolean
  dragPosition?: DragPosition
  useScroll?: boolean
  useTextAreaInputEditor?: boolean
  labelTextAlign?: LabelTextAlign
  maxLength?: number
  isGroupLabel?: boolean
  moveNextAfterEdit?: boolean
  isTextOverflowEllipsis?: boolean
  appendPostfixCallbackFunc?: (value: string, node?: RowNode) => string
}

export interface DropdownColumnDefProps extends CommonColumnDefProps {
  getDropdownMenuItemPropCallbackFunc?: (
    node: RowNode,
    column: Column
  ) => DropdownMenuItemComponentProps[]
  // Render Params : Row Data상의 값이랑 다른 값으로 Label을 표시하기 위한 callback
  convertValueCallbackFunc?: (value: string) => string
  placeholder?: string
  convertNodeCallbackFunc?: (value: string) => React.ReactNode
  menuWidth?: number
}

export interface SingleButtonColumnDefProps extends CommonColumnDefProps {
  buttonComponentProps: ButtonComponentProps
  getButtonClickCallbackFunc?: (node: RowNode, column: Column) => void
  buttonPropsByValueCallbackFunc?: (
    node: RowNode,
    column: Column
  ) => ButtonComponentProps | undefined
  buttonWidth?: string
}

export interface DoubleButtonColumnDefProps extends CommonColumnDefProps {
  leftButtonComponentProps: ButtonComponentProps
  getLeftButtonClickCallbackFunc?: (node: RowNode, column: Column) => void
  rightButtonComponentProps: ButtonComponentProps
  getRightButtonClickCallbackFunc?: (node: RowNode, column: Column) => void
}

export interface UploadButtonColumnDefProps extends CommonColumnDefProps {
  buttonComponentProps: ButtonComponentProps
  getButtonClickCallbackFunc?: (node: RowNode, column: Column) => void
  fileUploadCallbackFunc?: (node: RowNode, file: File) => void
  cancelUploadCallbackFunc?: (node: RowNode) => void
  maxCharLength?: number
  checkFileTypeCallbackFunc?: (file: File) => Promise<boolean>
  accept?: string
}

export interface CustomColumnDefProps extends CommonColumnDefProps {
  childNode?: React.ReactNode
  isSearchBlocked?: boolean
}

export interface AutoCompleteColumnDefProps extends LabelColumnDefProps {
  autoCompleteFunc?: (text: string) => Promise<React.ReactNode[]>
  isDropdownSelect?: boolean
}

export interface IconAgGridCellRendererProps extends CommonColumnDefProps {
  buttonClickCallbackFunc?: (node: RowNode, column: Column) => void
  iconProp: IconBaseComponentProps
  iconPropsByValueCallbackFunc?: (
    node: RowNode,
    column: Column
  ) => IconBaseComponentProps | undefined
}

export interface DateAgGridCellRendererProps extends CommonColumnDefProps {
  showTime?: boolean
  disabledDirection?: boolean
  disabledDate?: string
}

export interface DateRangeAgGridCellRendererProps extends CommonColumnDefProps {
  showTime?: boolean
  minDate?: string
  maxDate?: string
}

export interface AntdDropdownItemType {
  key: string
  label: React.ReactNode | string
  onClickCallbackFunc?: (node: RowNode, column: Column, key: string) => void
}

export interface AntdDropdownAgGridCellRendererProps extends CommonColumnDefProps {
  items?: AntdDropdownItemType[]
  placeholder?: string
  isDisableDropdownCallbackFunc?: (node: RowNode, column: Column) => boolean
  getDropdownItemsCallbackFunc?: (node: RowNode, column: Column) => AntdDropdownItemType[]
}
