import 'dayjs/locale/ko'

import { DatePicker } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import locale from 'antd/es/date-picker/locale/ko_KR'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'

import { IconType } from 'components/icon/IconBaseType'
import IconComponent from 'components/icon/IconComponent'

import './date.scss'

type DatePickerComponetProps = {
  date?: Date
  disabledDate?: Date
  disabledDirection?: boolean
  onChange?: (cellText: string) => void
  showTime?: boolean
  disabled?: boolean
}

const DatePickerComponent: React.FC<DatePickerComponetProps> = ({
  date,
  onChange,
  disabledDate,
  disabledDirection,
  showTime = false,
  disabled = false
}) => {
  const handleChange = (value: Dayjs | null, dateString: string) => {
    onChange && onChange(dateString)
  }
  const dateShowTime = showTime ? { defaultValue: dayjs('00:00:00', 'HH:mm:ss') } : undefined
  const commonProps = {
    allowClear: false,
    bordered: false,
    className: 'date-picker-frame',
    defaultValue: date ? dayjs(date) : undefined,
    format: showTime ? 'YYYY-MM-DD HH:mm:ss' : ['YYYY-MM-DD', 'YYYYMMDD'],
    locale,
    placeholder: showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD',
    showTime: dateShowTime,
    suffixIcon: <IconComponent enable="disable" iconType={IconType.DATE_RANGE} size={16} />,
    onChange: handleChange,
    disabled
  }

  // const dateShowTime = undefined
  if (disabledDate && disabledDirection !== undefined) {
    // (currentDate: dayjs) => boolean
    const disabledDateFunc: RangePickerProps['disabledDate'] = (current: Dayjs) => {
      if (disabledDirection) {
        return current && current < dayjs(disabledDate)
      }

      return current && current > dayjs(disabledDate)
    }

    return <DatePicker {...commonProps} disabledDate={disabledDateFunc} />
  }

  return <DatePicker {...commonProps} />
}

export default DatePickerComponent
