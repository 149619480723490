import { Column, GridApi, ICellEditorParams, RowNode } from 'ag-grid-community'
import React, { useState, useImperativeHandle, forwardRef } from 'react'

import ButtonComponent, {
  ButtonColor,
  ButtonSize,
  ButtonState
} from 'components/button/ButtonComponent'
import { showFlashCellsSimple } from 'components/ccktable/util/CCKTableUtil'
import { IconType } from 'components/icon/IconBaseType'
import IconComponent from 'components/icon/IconComponent'
import NewModalComponent from 'components/modal/NewModalComponent'

export enum DragPosition {
  RIGHT_TOP = 'rightTop',
  CENTER = 'center'
}

interface ModalAgGridCellEditorComponentProps extends ICellEditorParams {
  // unique value check를 수행할지 여부
  checkUniqueValueInColumn?: boolean
  // input 값 validation check를 위한 callback
  isValidateCheckCallbackFunc?: (
    input: string,
    node: RowNode,
    column: Column,
    api: GridApi
  ) => boolean
  // input 값이 최종적으로 Cell에 입력되기 전애 보정하기 위한 callback
  refinedInputValueCallbackFunc?: (input: string, node: RowNode, column: Column) => string
  // input 값이 변경될 때마다 호출되는 callback
  changeInputValueFilterCallbackFunc?: (input: string, node: RowNode, column: Column) => string
  isDraggable?: boolean
  dragPosition?: DragPosition
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalAgGridCellEditorComponent = forwardRef<any, ModalAgGridCellEditorComponentProps>(
  (props, ref) => {
    const { value, node, api, isDraggable, dragPosition } = props

    // text input value
    const [inputValue, setInputValue] = useState(value)

    // ag-grid cell editor를 사용하기 위해선 useImperativeHandle을 사용해야 함 + forwardRef
    // 호출 순서
    // Edit 창이 열리면
    // (1) isCancelBeforeStart(true 반환 시 편집 취소) -> (2) afterGuiAttached
    // Enter 입력 시
    // (3) handleInputComplete callbackFunc 실행. 내부에서 api.stopEditing() 호출로 편집 종료 call
    // Edit 창이 닫히면
    // (4) isCancelAfterEnd(true 반환 시 편집 결과 무시) -> (5) getValue(반환된 수정 값을 Cell에 반영)
    useImperativeHandle(ref, () => {
      return {
        // 편집이 시작되기 전에 한번 호출됨. 편집을 취소할 수 있는 기회를 주기 위함
        // true 반환 시 편집 취소
        isCancelBeforeStart() {
          // eslint-disable-next-line no-console
          console.log('isCancelBeforeStart')
          return false
        },
        // 이 컴포넌트가 만들어져 Dom에 붙고 난 후 호출됨
        afterGuiAttached() {
          // eslint-disable-next-line no-console
          console.log('afterGuiAttached')
        },

        // 편집이 완료되면 한번 호출됨(ex. Enter 입력 등). 편집 결과를 무시하고 싶을 때 사용
        // true 반환 시 편집 결과 무시
        isCancelAfterEnd() {
          // eslint-disable-next-line no-console
          console.log('isCancelAfterEnd')

          // 입력 값이 이전과 동일한 경우, 편집 취소
          if (inputValue === value) {
            return true
          }

          return false
        },

        // Edit 최종 결과값을 반환
        getValue() {
          // eslint-disable-next-line no-console
          console.log('getValue')

          showFlashCellsSimple({ api, rowNodes: [node] })
          return inputValue
        }
      }
    })

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const textarea = event.target as HTMLTextAreaElement
      const textAreaValue = textarea.value
      setInputValue(textAreaValue)
    }
    const getDraggableStyle = (): React.CSSProperties => {
      if (dragPosition === DragPosition.RIGHT_TOP) {
        return { width: '448px', cursor: 'move', left: 'calc(50% - 300px)', top: '60px' }
      }
      return {}
    }
    return (
      <NewModalComponent
        // drag 초기 위치값이 바뀔 경우 style을 계산해서 나눌것!
        dragStyles={getDraggableStyle()}
        hasBottomPadding={false}
        isDraggable={isDraggable}
      >
        <div className="cck-table-editor-text-area-wrapper">
          <textarea
            autoFocus
            className="cck-table-editor-text-area"
            onChange={handleTextAreaChange}
          >
            {inputValue}
          </textarea>
          <div className="cck-table-editor-text-area-buttons">
            <ButtonComponent
              clickCallbackFunc={() => api.stopEditing(true)}
              color={ButtonColor.SECONDARY}
              size={ButtonSize.MEDIUM}
              states={ButtonState.NORMAL}
              text="취소"
            />
            <ButtonComponent
              clickCallbackFunc={() => api.stopEditing()}
              color={ButtonColor.PRIMARY}
              leftIcon={<IconComponent iconType={IconType.EDIT_SHARP} />}
              size={ButtonSize.MEDIUM}
              states={ButtonState.NORMAL}
              text="저장"
            />
          </div>
        </div>
      </NewModalComponent>
    )
  }
)

ModalAgGridCellEditorComponent.displayName = 'ModalAgGridCellEditorComponent'

export default ModalAgGridCellEditorComponent
