import _ from 'lodash'
import React from 'react'

import ButtonComponent, { ButtonComponentProps } from 'components/button/ButtonComponent'
import './button.scss'

export const WrapperButtonSize = {
  large: 'large',
  middle: 'middle',
  small: 'small'
} as const

export type WrapperButtonSize = typeof WrapperButtonSize[keyof typeof WrapperButtonSize]

export interface ButtonWrapperProp {
  buttonProp: ButtonComponentProps
  wrapperSize: WrapperButtonSize
}

export interface ButtonListComponentProps {
  align: 'center' | 'right' | 'between'
  buttons: ButtonWrapperProp[]
}

const ButtonListComponent: React.FC<ButtonListComponentProps> = ({ align, buttons }) => {
  return (
    <div className={`button-list-frame button-list-frame-align-${align}`}>
      {_.map(buttons, (buttonWrapper) => {
        const buttonProp = buttonWrapper.buttonProp
        return (
          <div className={`button-wrapper-${buttonWrapper.wrapperSize}`} key={buttonProp.text}>
            <ButtonComponent {...buttonProp} />
          </div>
        )
      })}
    </div>
  )
}

export default ButtonListComponent
