import React from 'react'

import { IconBaseComponentProps } from 'components/icon/IconBaseType'
import { useCreateInput } from 'components/input/useCreateInput'
import './input.scss'
import './new_input.scss'

export type NewInputState = 'Default' | 'Valid' | 'Invalid' | 'Warning'

export interface NewInputControlTypeProps {
  placeholderText: string
  prefixIconProp?: IconBaseComponentProps
  suffixIconProp?: IconBaseComponentProps
  className?: string
  inputTextValidateFunc?: (text: string) => boolean // input type에 맞는지 검사
  predefinedChangeInputTextFunc?: (text: string) => string // input type에 맞게 변환
}

export interface NewInputControlComponentProps {
  initText?: string
  validateState?: NewInputState
  completeInputFunc?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  changeInputFunc?: (input: string) => void
  blurInputFunc?: () => void
  onKeyDownFunc?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  inputControlTypeProps?: NewInputControlTypeProps
  initFocus?: boolean
  inputType?: string
  disabled?: boolean
  className?: string
  useTextArea?: boolean
  maxLength?: number
  needMaxLength?: boolean
}

const NewInputControlComponent: React.FC<NewInputControlComponentProps> = ({
  initText,
  validateState = 'Default',
  completeInputFunc,
  changeInputFunc,
  blurInputFunc,
  onKeyDownFunc,
  inputControlTypeProps,
  initFocus = false,
  inputType,
  disabled = false,
  className,
  useTextArea = false,
  maxLength,
  needMaxLength = true
}) => {
  const input = useCreateInput({
    inputControlTypeProps,
    initText,
    validateState,
    completeInputFunc,
    changeInputFunc,
    blurInputFunc,
    onKeyDownFunc,
    initFocus,
    inputType,
    disabled,
    className,
    useTextArea,
    maxLength,
    needMaxLength
  })
  return <>{input}</>
}

export default NewInputControlComponent
