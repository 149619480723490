import { Layout } from 'antd'
import React from 'react'

import MainChatViewHeaderComponent from 'components/chat//MainChatViewHeaderComponent'
import ChatContentWrapperViewComponent from 'components/chat/ChatContentWrapperViewComponent'
import MainChatLeftSiderViewComponent from 'components/chat/sider/MainChatLeftSiderViewComponent'

const MainChatViewComponent: React.FC = () => {
  return (
    <div className="chat-main-view-frame">
      <Layout className="chat-main-view-layout">
        <MainChatLeftSiderViewComponent />
        <Layout>
          <MainChatViewHeaderComponent />
          <ChatContentWrapperViewComponent />
        </Layout>
      </Layout>
    </div>
  )
}

export default MainChatViewComponent
