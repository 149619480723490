import { isEmpty } from '@cck/common'
import _ from 'lodash'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { searchStandardCountMapAtom } from 'atoms/search/Search'
import NewSearchKeywordItemComponent from 'components/new_search/NewSearchKeywordItemComponent'

type NewSearchKeywordListComponentProps = {
  renderItemBySpan?: boolean
}

const NewSearchKeywordListComponent: React.FC<NewSearchKeywordListComponentProps> = ({
  renderItemBySpan = false
}) => {
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)

  const renderKeywordListItem = () => {
    if (isEmpty(searchStandardCountMap)) {
      return null
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return _.map(Array.from(searchStandardCountMap), ([key, value]) => {
      return <NewSearchKeywordItemComponent keyword={key} renderItemBySpan={renderItemBySpan} />
    })
  }

  return (
    <div className="main-view-dropdown-header-search-history-frame">{renderKeywordListItem()}</div>
  )
}

export default NewSearchKeywordListComponent
