import React from 'react'

import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'

interface CustomAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  value: React.ReactNode
  autoHeight?: boolean
}

const CustomAgGridCellRendererComponent: React.FC<CustomAgGridCellRendererComponentProps> = ({
  value,
  eGridCell,
  autoHeight = false
}) => {
  return (
    <div
      className="cck-table-custom-cell-frame"
      style={{
        height: autoHeight ? undefined : eGridCell.clientHeight,
        width: eGridCell.clientWidth
      }}
    >
      {value}
    </div>
  )
}

export default CustomAgGridCellRendererComponent
