import { ColDef } from 'ag-grid-community'
import classNames from 'classnames'

import {
  AgGridCellEditor,
  AgGridCellRenderer,
  CheckboxColumnDefProps,
  CommonColumnDefProps,
  DropdownColumnDefProps,
  LabelColumnDefProps,
  SingleButtonColumnDefProps,
  DoubleButtonColumnDefProps,
  UploadButtonColumnDefProps,
  CustomColumnDefProps,
  AgGridHeader,
  AgGridDateEditor,
  AutoCompleteColumnDefProps,
  IconAgGridCellRendererProps,
  DateAgGridCellRendererProps,
  DateRangeAgGridCellRendererProps,
  AntdDropdownAgGridCellRendererProps
} from 'components/ccktable/column/ColumnDefTypes'
import AntdDropdownAgGridCellRendererComponent from 'components/ccktable/control/AntdDropdownAgGridCellRendererComponent'
import AutoCompleteAgGridCellEditorComponent from 'components/ccktable/control/AutoCompleteAgGridCellEditorComponent'
import CCKBasicAgGridCellEditorComponent from 'components/ccktable/control/CCKBasicAgGridCellEditorComponent'
import CheckboxAgGridCellRendererComponent from 'components/ccktable/control/CheckboxAgGridCellRendererComponent'
import CheckboxAgGridHeaderComponent from 'components/ccktable/control/CheckboxAgGridHeaderComponent'
import CustomAgGridCellRendererComponent from 'components/ccktable/control/CustomAgGridCellRendererComponent'
import CustomAgGridHeaderComponent from 'components/ccktable/control/CustomAgGridHeaderComponent'
import DateAgGridCellRendererComponent from 'components/ccktable/control/DateAgGridCellRendererComponent'
import DateRangeAgGridCellRendererComponent from 'components/ccktable/control/DateRangeAgGridCellRendererComponent'
import DoubleButtonAgGridCellRendererComponent from 'components/ccktable/control/DoubleButtonAgGridCellRendererComponent'
import DropdownAgGridCellRendererComponent from 'components/ccktable/control/DropdownAgGridCellRendererComponent'
import IconAgGridCellRendererComponent from 'components/ccktable/control/IconAgGridCellRendererComponent'
import LabelAgGridCellRendererComponent from 'components/ccktable/control/LabelAgGridCellRendererComponent'
import ModalAgGridCellEditorComponent from 'components/ccktable/control/ModalAgGridCellEditorComponent'
import OnlyPasteAgGridCellEditorComponent from 'components/ccktable/control/OnlyPasteAgGridCellEditorComponent'
import SingleButtonAgGridCellRendererComponent from 'components/ccktable/control/SingleButtonAgGridCellRendererComponent'
import UploadButtonAgGridCellRendererComponent from 'components/ccktable/control/UploadButtonAgGridCellRendererComponent'

// ag-grid에서 사용하는 cellRenderer를 custom component로 wrapping
export const CCKAgGridFrameworkComponents: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
  [key in AgGridCellRenderer | AgGridCellEditor | AgGridHeader | AgGridDateEditor]: any
} = {
  [AgGridCellRenderer.Checkbox]: CheckboxAgGridCellRendererComponent,
  [AgGridCellRenderer.Label]: LabelAgGridCellRendererComponent,
  [AgGridCellRenderer.Dropdown]: DropdownAgGridCellRendererComponent,
  [AgGridCellRenderer.SingleButton]: SingleButtonAgGridCellRendererComponent,
  [AgGridCellRenderer.DoubleButton]: DoubleButtonAgGridCellRendererComponent,
  [AgGridCellRenderer.Upload]: UploadButtonAgGridCellRendererComponent,
  [AgGridCellRenderer.Custom]: CustomAgGridCellRendererComponent,
  [AgGridCellRenderer.Icon]: IconAgGridCellRendererComponent,
  [AgGridCellEditor.CCKBasic]: CCKBasicAgGridCellEditorComponent,
  [AgGridCellEditor.Modal]: ModalAgGridCellEditorComponent,
  [AgGridCellEditor.AutoComplete]: AutoCompleteAgGridCellEditorComponent,
  [AgGridCellEditor.OnlyPaste]: OnlyPasteAgGridCellEditorComponent,
  [AgGridHeader.CustomHeader]: CustomAgGridHeaderComponent,
  [AgGridHeader.CheckBoxHeader]: CheckboxAgGridHeaderComponent,
  [AgGridDateEditor.Date]: DateAgGridCellRendererComponent,
  [AgGridDateEditor.DateRange]: DateRangeAgGridCellRendererComponent,
  [AgGridCellRenderer.AntdDropdown]: AntdDropdownAgGridCellRendererComponent
}

// Column Header Class Name 생성
function getColumnHeaderClassNames(
  colDef: CommonColumnDefProps,
  inputClassNames: string[]
): string {
  // default column header class name
  const defaultColumnHeaderClassNames = classNames({
    'cck-table-column-header-default': true,
    'cck-table-column-header-align-center': colDef?.isHeaderCenterAlign ?? false,
    'cck-table-left-border-default': colDef?.columnLeftBorder ?? false
  })

  // merge
  const columnHeaderClassNames = [
    // default
    defaultColumnHeaderClassNames,
    // input as className
    ...inputClassNames,
    // input as headerClass
    ...(colDef.headerClass?.toString().split(' ') ?? [])
  ]

  // to string
  return columnHeaderClassNames.join(' ')
}

function getCellClassNames(colDef: CommonColumnDefProps, inputClassNames: string[] = []): string {
  // default column header class name
  const defaultCellClassNames = classNames({
    'cck-table-cell-default': true,
    'cck-table-cell-align-center': colDef?.isCellCenterAlign ?? false,
    'cck-table-left-border-default': colDef?.columnLeftBorder ?? true
  })

  // merge
  const cellClassNames = [
    // default
    defaultCellClassNames,
    // input as className
    ...inputClassNames,
    // input as headerClass
    ...(colDef.cellClass?.toString().split(' ') ?? [])
  ]

  // to string
  return cellClassNames.join(' ')
}

function getCommonColumnDefs(
  commonColDefInfo: CommonColumnDefProps,
  type?: AgGridCellRenderer
): ColDef {
  const getDefaultSortable = () => {
    switch (type) {
      case 'dropdown':
      case 'label':
        return true
      default:
        return false
    }
  }

  return {
    suppressMenu: commonColDefInfo?.suppressMenu ?? true,
    // width가 지정된 경우에 suppressSizeToFit을 true로 설정해서 해당 column의 width를 고정시킴
    suppressSizeToFit: commonColDefInfo?.width !== undefined,
    sortable: commonColDefInfo?.sortable ?? getDefaultSortable(),
    headerComponent: commonColDefInfo.customHeaderType,
    headerComponentParams: {
      value: commonColDefInfo.customColumnValue
    },
    suppressMovable: commonColDefInfo?.suppressMovable ?? true,
    autoHeight: commonColDefInfo?.autoHeight ?? false,
    wrapText: commonColDefInfo?.wrapText ?? commonColDefInfo?.autoHeight ?? false
  }
}

// Checkbox column definition 생성
export function createCheckboxColumnDef(checkboxDefInfo: CheckboxColumnDefProps): ColDef {
  return {
    // headerName: header name
    // field: row data와 매핑되는 field name
    // cellStyle: cell에 적용하는 style
    // headerClass: header에 적용하는 style classname
    // cellClassRules: cell에 적용하는 style classname
    // suppressMenu: column header icon 제거하기
    ...checkboxDefInfo,
    // cellRenderer: renderer를 custom component로 교체(name으로 mapping)
    cellRenderer: AgGridCellRenderer.Checkbox,
    // cellRendererParams: renderer에 전달할 parameter
    cellRendererParams: {
      // value에 대해 검색이 불가능한 column으로 설정(custom prameter)
      isSearchBlocked: true,
      callbackCheckboxChange: checkboxDefInfo.callbackCheckboxChange,
      callbackIsContentVisible: checkboxDefInfo.callbackIsContentVisible,
      callbackIsContentDisable: checkboxDefInfo.callbackIsContentDisable,
      checkboxClickDisabled: checkboxDefInfo.checkboxClickDisabled
    },
    headerClass: getColumnHeaderClassNames(checkboxDefInfo, [
      'cck-table-column-header-checkbox-default'
    ]),
    cellStyle: checkboxDefInfo.cellStyle,
    cellClass: getCellClassNames(checkboxDefInfo, ['cck-table-cell-checkbox-default']),
    ...getCommonColumnDefs(checkboxDefInfo, 'checkBox')
  }
}

// Label column definition
export function createLabelColumnDef(labelDefInfo: LabelColumnDefProps): ColDef {
  const columnDef = getCommonColumnDefs(labelDefInfo, 'label')
  if (labelDefInfo.useScroll) {
    columnDef.wrapText = true
  }
  columnDef.tooltipField = labelDefInfo.tooltipField ?? labelDefInfo.field
  return {
    ...labelDefInfo,
    cellRenderer: AgGridCellRenderer.Label,
    cellRendererParams: {
      color: labelDefInfo.color,
      typoStyle: labelDefInfo.typoStyle,
      refinedValueType: labelDefInfo.refinedValueType,
      getLabelClassNamesCallbackFunc: labelDefInfo.getLabelClassNamesCallbackFunc,
      convertValueCallbackFunc: labelDefInfo.convertValueCallbackFunc,
      placeholder: labelDefInfo.placeholder,
      postfixPlaceholder: labelDefInfo.postfixPlaceholder,
      labelTextAlign: labelDefInfo.labelTextAlign,
      isGroupLabel: labelDefInfo.isGroupLabel,
      isTextOverflowEllipsis: labelDefInfo.isTextOverflowEllipsis,
      appendPostfixCallbackFunc: labelDefInfo.appendPostfixCallbackFunc
    },
    cellEditor: labelDefInfo.useModalEditor ? AgGridCellEditor.Modal : AgGridCellEditor.CCKBasic,
    cellEditorParams: {
      useTextAreaInputEditor: labelDefInfo.useTextAreaInputEditor,
      refinedValueType: labelDefInfo.refinedValueType,
      checkUniqueValueInColumn: labelDefInfo.checkUniqueValueInColumn,
      isValidateCheckCallbackFunc: labelDefInfo.isValidateCheckCallbackFunc,
      refinedInputValueCallbackFunc: labelDefInfo.refinedInputValueCallbackFunc,
      changeInputValueFilterCallbackFunc: labelDefInfo.changeInputValueFilterCallbackFunc,
      maxLength: labelDefInfo.maxLength,
      isDraggable: labelDefInfo.isDraggable,
      dragPosition: labelDefInfo.dragPosition,
      moveNextAfterEdit: labelDefInfo.moveNextAfterEdit
    },
    headerClass: getColumnHeaderClassNames(labelDefInfo, []),
    cellClass: getCellClassNames(labelDefInfo),
    cellClassRules: {
      ...labelDefInfo.cellClassRules,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      'cck-table-bottom-border-highlight': (params) => {
        // cellClassRules가 변경될 때마다 cellRenderer를 다시 호출
        // cellClass에 넣으면 ag-grid 최적화 로직에 따라 다시 안그려질 수 있으므로,
        // 동적으로 변해야하는 스타일에 대해서는 cellClassRules에 넣는 것이 좋음
        return labelDefInfo.cellBottomBorderHighlight ?? false
      },
      'cck-table-cell-label-scroll': (params) => {
        // row data의 span이 있고 텍스트가 클 경우 scroll이 생기도록 css class 추가
        return labelDefInfo.useScroll ?? false
      }
    },
    ...columnDef
  }
}

// Dropdown column definition
export function createDropdownColumnDef(dropdownDefInfo: DropdownColumnDefProps): ColDef {
  return {
    ...dropdownDefInfo,
    cellRenderer: AgGridCellRenderer.Dropdown,
    cellRendererParams: {
      getDropdownMenuItemPropCallbackFunc: dropdownDefInfo.getDropdownMenuItemPropCallbackFunc,
      convertValueCallbackFunc: dropdownDefInfo.convertValueCallbackFunc,
      callbackIsContentVisible: dropdownDefInfo.callbackIsContentVisible,
      placeholder: dropdownDefInfo.placeholder,
      convertNodeCallbackFunc: dropdownDefInfo.convertNodeCallbackFunc,
      menuWidth: dropdownDefInfo.menuWidth
    },
    cellEditor: AgGridCellEditor.OnlyPaste,
    headerClass: getColumnHeaderClassNames(dropdownDefInfo, []),
    cellClass: getCellClassNames(dropdownDefInfo, ['cck-table-cell-dropdown-default']),
    ...getCommonColumnDefs(dropdownDefInfo, 'dropdown')
  }
}

// button(single) column definition
export function createSingleButtonColumnDef(
  singleButtonDefInfo: SingleButtonColumnDefProps
): ColDef {
  singleButtonDefInfo.isCellCenterAlign = singleButtonDefInfo.isCellCenterAlign ?? true

  return {
    ...singleButtonDefInfo,
    cellRenderer: AgGridCellRenderer.SingleButton,
    cellRendererParams: {
      // value에 대해 검색이 불가능한 column으로 설정(custom prameter)
      isSearchBlocked: true,
      buttonComponentProps: singleButtonDefInfo.buttonComponentProps,
      buttonClickCallbackFunc: singleButtonDefInfo.getButtonClickCallbackFunc,
      callbackIsContentVisible: singleButtonDefInfo.callbackIsContentVisible,
      buttonPropsByValueCallbackFunc: singleButtonDefInfo.buttonPropsByValueCallbackFunc,
      buttonWidth: singleButtonDefInfo.buttonWidth
    },
    headerClass: getColumnHeaderClassNames(singleButtonDefInfo, []),
    cellClass: getCellClassNames(singleButtonDefInfo, ['cck-table-button-column']),
    ...getCommonColumnDefs(singleButtonDefInfo, 'singleButton')
  }
}

export function createDoubleButtonColumnDef(
  doubleButtonDefInfo: DoubleButtonColumnDefProps
): ColDef {
  return {
    ...doubleButtonDefInfo,
    cellRenderer: AgGridCellRenderer.DoubleButton,
    cellRendererParams: {
      isSearchBlocked: true,
      leftButtonComponentProps: doubleButtonDefInfo.leftButtonComponentProps,
      rightButtonComponentProps: doubleButtonDefInfo.rightButtonComponentProps,
      leftButtonClickCallbackFunc: doubleButtonDefInfo.getLeftButtonClickCallbackFunc,
      rightButtonClickCallbackFunc: doubleButtonDefInfo.getRightButtonClickCallbackFunc
    },
    headerClass: getColumnHeaderClassNames(doubleButtonDefInfo, []),
    cellClass: getCellClassNames(doubleButtonDefInfo, ['cck-table-button-column']),
    ...getCommonColumnDefs(doubleButtonDefInfo, 'doubleButton')
  }
}

export function createUploadButtonColumnDef(
  updloadButtonDefInfo: UploadButtonColumnDefProps
): ColDef {
  return {
    ...updloadButtonDefInfo,
    cellRenderer: AgGridCellRenderer.Upload,
    cellRendererParams: {
      // value에 대해 검색이 불가능한 column으로 설정(custom prameter)
      isSearchBlocked: true,
      buttonComponentProps: updloadButtonDefInfo.buttonComponentProps,
      buttonClickCallbackFunc: updloadButtonDefInfo.getButtonClickCallbackFunc,
      maxCharLength: updloadButtonDefInfo.maxCharLength,
      fileUploadCallbackFunc: updloadButtonDefInfo.fileUploadCallbackFunc,
      cancelUploadCallbackFunc: updloadButtonDefInfo.cancelUploadCallbackFunc,
      checkFileTypeCallbackFunc: updloadButtonDefInfo.checkFileTypeCallbackFunc,
      accept: updloadButtonDefInfo.accept
    },
    headerClass: getColumnHeaderClassNames(updloadButtonDefInfo, ['cck-table-upload-header']),
    cellClass: getCellClassNames(updloadButtonDefInfo, [
      'cck-table-button-column',
      'cck-table-upload-column'
    ]),
    ...getCommonColumnDefs(updloadButtonDefInfo, 'upload')
  }
}

export function createCustomColumnDef(customColumnDefInfo: CustomColumnDefProps): ColDef {
  return {
    ...customColumnDefInfo,
    cellRenderer: AgGridCellRenderer.Custom,
    cellRendererParams: {
      isSearchBlocked: customColumnDefInfo?.isSearchBlocked ?? true,
      autoHeight: customColumnDefInfo.autoHeight
    },
    headerClass: getColumnHeaderClassNames(customColumnDefInfo, []),
    cellClass: getCellClassNames(customColumnDefInfo, ['cck-table-custom-cell-frame']),
    ...getCommonColumnDefs(customColumnDefInfo, 'custom')
  }
}

export function createDateColumnDef(dateColumnDefInfo: DateAgGridCellRendererProps): ColDef {
  return {
    ...dateColumnDefInfo,
    cellRenderer: AgGridDateEditor.Date,
    cellRendererParams: {
      isSearchBlocked: true,
      showTime: dateColumnDefInfo.showTime,
      disabledDirection: dateColumnDefInfo.disabledDirection,
      disabledDate: dateColumnDefInfo.disabledDate
    },
    headerClass: getColumnHeaderClassNames(dateColumnDefInfo, []),
    cellClass: getCellClassNames(dateColumnDefInfo, ['cck-table-date-cell']),
    ...getCommonColumnDefs(dateColumnDefInfo, 'label')
  }
}

export function createDateRangeColumnDef(
  dateRangeColumnDefInfo: DateRangeAgGridCellRendererProps
): ColDef {
  return {
    ...dateRangeColumnDefInfo,
    cellRenderer: AgGridDateEditor.DateRange,
    cellRendererParams: {
      isSearchBlocked: true,
      showTime: dateRangeColumnDefInfo.showTime,
      minDate: dateRangeColumnDefInfo.minDate,
      maxDate: dateRangeColumnDefInfo.maxDate
    },
    headerClass: getColumnHeaderClassNames(dateRangeColumnDefInfo, []),
    cellClass: getCellClassNames(dateRangeColumnDefInfo, ['cck-table-date-cell']),
    ...getCommonColumnDefs(dateRangeColumnDefInfo, 'label')
  }
}

export function createAutoLabelColumnDef(autoCompleteDefInfo: AutoCompleteColumnDefProps): ColDef {
  return {
    ...autoCompleteDefInfo,
    cellRenderer: AgGridCellRenderer.Label,
    cellRendererParams: {
      color: autoCompleteDefInfo.color,
      typoStyle: autoCompleteDefInfo.typoStyle,
      getLabelClassNamesCallbackFunc: autoCompleteDefInfo.getLabelClassNamesCallbackFunc,
      placeholder: autoCompleteDefInfo.placeholder,
      postfixPlaceholder: autoCompleteDefInfo.postfixPlaceholder
    },
    cellEditor: AgGridCellEditor.AutoComplete,
    cellEditorParams: {
      checkUniqueValueInColumn: autoCompleteDefInfo.checkUniqueValueInColumn,
      isValidateCheckCallbackFunc: autoCompleteDefInfo.isValidateCheckCallbackFunc,
      refinedInputValueCallbackFunc: autoCompleteDefInfo.refinedInputValueCallbackFunc,
      changeInputValueFilterCallbackFunc: autoCompleteDefInfo.changeInputValueFilterCallbackFunc,
      autoCompleteFunc: autoCompleteDefInfo.autoCompleteFunc,
      maxLength: autoCompleteDefInfo.maxLength,
      isDropdownSelect: autoCompleteDefInfo.isDropdownSelect
    },
    headerClass: getColumnHeaderClassNames(autoCompleteDefInfo, []),
    cellClass: getCellClassNames(autoCompleteDefInfo),
    cellClassRules: {
      ...autoCompleteDefInfo.cellClassRules,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      'cck-table-bottom-border-highlight': (params) => {
        // cellClassRules가 변경될 때마다 cellRenderer를 다시 호출
        // cellClass에 넣으면 ag-grid 최적화 로직에 따라 다시 안그려질 수 있으므로,
        // 동적으로 변해야하는 스타일에 대해서는 cellClassRules에 넣는 것이 좋음
        return autoCompleteDefInfo.cellBottomBorderHighlight ?? false
      }
    },
    ...getCommonColumnDefs(autoCompleteDefInfo, 'label')
  }
}

export function createCheckboxSelectorColumnDef(props: CommonColumnDefProps): ColDef {
  const selectorProps = {
    width: 60,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRendererParams: {
      isSearchBlocked: true
    },
    ...props
  }

  return {
    ...selectorProps,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerClass: getColumnHeaderClassNames(selectorProps, ['cck-table-checkbox-selector-header']),
    cellClass: getCellClassNames(selectorProps, ['cck-table-checkbox-selector']),
    ...getCommonColumnDefs(selectorProps)
  }
}

export function createIconColumnDef(props: IconAgGridCellRendererProps): ColDef {
  return {
    ...props,
    cellRenderer: AgGridCellRenderer.Icon,
    cellRendererParams: {
      // value에 대해 검색이 불가능한 column으로 설정(custom prameter)
      isSearchBlocked: true,
      iconProp: props.iconProp,
      buttonClickCallbackFunc: props.buttonClickCallbackFunc,
      callbackIsContentVisible: props.callbackIsContentVisible,
      iconPropsByValueCallbackFunc: props.iconPropsByValueCallbackFunc
    },
    headerClass: getColumnHeaderClassNames(props, []),
    cellClass: getCellClassNames(props, ['cck-table-icon-column']),
    ...getCommonColumnDefs(props, 'icon')
  }
}

export function createAntdDropdownColumDef(props: AntdDropdownAgGridCellRendererProps): ColDef {
  return {
    ...props,
    cellRenderer: AgGridCellRenderer.AntdDropdown,
    cellRendererParams: {
      items: props.items,
      placeholder: props.placeholder,
      isDisableDropdownCallbackFunc: props.isDisableDropdownCallbackFunc,
      getDropdownItemsCallbackFunc: props.getDropdownItemsCallbackFunc
    },
    cellEditor: AgGridCellEditor.OnlyPaste,
    headerClass: getColumnHeaderClassNames(props, []),
    cellClass: getCellClassNames(props, [])
  }
}
