import React from 'react'
import { useRecoilValue } from 'recoil'

import { searchStandardCountMapAtom } from 'atoms/search/Search'
import NewSearchKeywordCountItemComponent, {
  SearchKeywordCountItemAreaType
} from 'components/new_search/NewSearchKeywordCountItemComponent'
import { findSearchCountItem } from 'components/util/SearchUtil'
import { useSearchResultExist } from 'hook/search/useSearchResultExist'

type NewSearchKeywordCountListComponentProps = {
  indexItemId: string
  itemArea: SearchKeywordCountItemAreaType
  childrenOverlap?: boolean
}

const NewSearchKeywordCountListComponent: React.FC<NewSearchKeywordCountListComponentProps> = ({
  indexItemId,
  itemArea,
  childrenOverlap = false
}) => {
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)
  const isSearchResultExist = useSearchResultExist(
    itemArea === SearchKeywordCountItemAreaType.DROPDOWN_STANDARD,
    indexItemId
  )

  const makeSearchCountItems = () => {
    const renderComponents: JSX.Element[] = []
    if (!isSearchResultExist) {
      return renderComponents
    }

    const isStandard = itemArea === SearchKeywordCountItemAreaType.DROPDOWN_STANDARD

    !isStandard && renderComponents.push(<span> </span>)

    let index = 0
    searchStandardCountMap.forEach((value, key) => {
      const found = findSearchCountItem(value, indexItemId, isStandard)

      if (found) {
        renderComponents.push(
          <NewSearchKeywordCountItemComponent
            indexItemId={indexItemId}
            itemArea={itemArea}
            itemIndex={index}
            keyword={key}
          />
        )

        if (childrenOverlap) {
          index += 1
        }
      }
    })

    if (childrenOverlap) {
      return <span className="search-count-list-overlap-frame">{renderComponents}</span>
    }

    return renderComponents
  }

  return <>{makeSearchCountItems()}</>
}

export default NewSearchKeywordCountListComponent
