import { isEmpty, notEmpty } from '@cck/common'
import _ from 'lodash'
import { atom, selector, selectorFamily } from 'recoil'

import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import { dummyStandardIndexSelector } from 'services/dummy/DummyStandardIndex'
import { Index, IndexReply, getIndex } from 'services/standard_client'

export const selectedOneDepthIndexItemAtom = atom<Index | undefined>({
  key: 'selectedOneDepthIndexItemAtom',
  default: undefined
})

export const selectedIndexItemAtom = atom<Index>({
  key: 'selectedIndexItemAtom',
  default: undefined
})

export const getIndexItemSelector = selectorFamily<IndexReply, string>({
  key: 'getIndexItemSelector',
  get(standardId: string) {
    return async function ({ get }) {
      const indexMap = await get(dummyStandardIndexSelector)
      const cached = indexMap.get(standardId)
      if (cached) {
        return cached
      }
      // return indexMap.get(standardId) ?? { indexList: [] }
      return await getIndex(standardId)
    }
  }
})

export const indexItemsSelector = selector<IndexReply>({
  key: 'indexItemsSelector',
  get: async ({ get }) => {
    const selectedStandardId = get(selectedStandardIdAtom)
    if (selectedStandardId === '') {
      return { indexList: [] }
    }
    return get(getIndexItemSelector(selectedStandardId))
  }
})

export function findIndexItemInner(indexItems: Index[], id: string[]): Index | undefined {
  if (isEmpty(indexItems)) {
    return
  }

  const numberOfDot = (indexItems[0].id.match(/\./g) || []).length + 1
  const targetId = id.slice(0, numberOfDot).join('.')
  const found = indexItems.find((item) => item.id === targetId)
  if (!found) {
    return
  }
  if (numberOfDot < id.length && notEmpty(found.children)) {
    return findIndexItemInner(found.children, id)
  }
  return found
}

export const findIndexItemSelector = selectorFamily<
  Index | undefined,
  { standardId: string; id: string }
>({
  key: 'findIndexItemSelector',
  get({ standardId, id }) {
    return async function ({ get }) {
      const indexItems = get(getIndexItemSelector(standardId))
      const ids = _.split(id, '.')

      return findIndexItemInner(indexItems.indexList, ids)
    }
  }
})
