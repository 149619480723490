import { IconComponent, IconType } from '@cck/common'
import React from 'react'

interface ChatMessageRequestQuestionComponentProps {
  requestQuestions: string[]
  submitRequestQuestion: (question: string) => void
}

const ChatMessageRequestQuestionComponent: React.FC<ChatMessageRequestQuestionComponentProps> = ({
  requestQuestions,
  submitRequestQuestion
}) => {
  const makeRequestQuestions = () => {
    return requestQuestions.map((question, idx) => {
      return (
        <div
          className="chat-message-request-question-content-item"
          key={idx}
          onClick={() => submitRequestQuestion(question)}
        >
          {question}
        </div>
      )
    })
  }

  return (
    <div className="chat-message-request-question-wrapper">
      <div className="chat-message-request-question-title-wrapper">
        <IconComponent isSvg iconType={IconType.ACCIO_CHAT} size={24} />
        이런 질문은 어떠세요?
      </div>
      <div className="chat-message-request-question-content-wrapper">{makeRequestQuestions()}</div>
    </div>
  )
}

export default ChatMessageRequestQuestionComponent
