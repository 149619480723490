import { IconComponent, IconType, isEmpty } from '@cck/common'
import type { MenuProps } from 'antd'
import { Badge, Dropdown } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import {
  indexItemsSelector,
  selectedIndexItemAtom,
  selectedOneDepthIndexItemAtom
} from 'atoms/index/Index'
import { searchStandardColorMapAtom, searchStandardCountMapAtom } from 'atoms/search/Search'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import ContentItemListComponent from 'components/content/ContentItemListComponent'
import NewSearchKeywordListComponent from 'components/new_search/NewSearchKeywordListComponent'
import NewSearchStandardComponent from 'components/new_search/NewSearchStandardComponent'
import { findFirstChildIndexItem } from 'components/util/IndexItemUtil'
import { getIndexItemIdString } from 'utils/standard_utils'

const NewLnbComponent: React.FC = () => {
  const navigate = useNavigate()
  const [showPopover, setShowPopover] = useState(false)
  const [selectedOneDepthIndexItem, setSelectedOneDepthIndexItem] = useRecoilState(
    selectedOneDepthIndexItemAtom
  )
  const indexItems = useRecoilValue(indexItemsSelector)
  const setSelectedIndexItem = useSetRecoilState(selectedIndexItemAtom)
  const selectedStandardId = useRecoilValue(selectedStandardIdAtom)
  const [searchStandardCountMap, setSearchStandardCountMap] = useRecoilState(
    searchStandardCountMapAtom
  )
  const setSearchStandardColorMap = useSetRecoilState(searchStandardColorMapAtom)

  useEffect(() => {
    const handleClickOutsidePopover = (event: MouseEvent) => {
      const target = event.target as Element
      if (!target.closest('.new-lnb-view-search-frame, .new-lnb-search-history-popover-frame')) {
        setShowPopover(false)
      }
    }

    document.addEventListener('click', handleClickOutsidePopover)

    return () => document.removeEventListener('click', handleClickOutsidePopover)
  }, [])

  const makeContentLists = () => {
    if (!selectedOneDepthIndexItem) {
      return null
    }

    return (
      <div className="new-lnb-two-depth-list-frame">
        <div className="new-lnb-two-depth-list">
          <ContentItemListComponent oneDepthIndexItem={selectedOneDepthIndexItem} />
        </div>
      </div>
    )
  }

  const makeOneDepthDropdown = () => {
    if (!selectedOneDepthIndexItem || isEmpty(indexItems)) {
      return (
        <Dropdown disabled trigger={['click']}>
          <div
            className={classNames({
              'new-lnb-view-one-depth-dropdown-frame': true,
              'new-lnb-view-one-depth-dropdown-frame-disabled': true
            })}
          >
            <div className="new-lnb-view-one-depth-dropdown-text-frame">
              <div className="new-lnb-view-one-depth-dropdown-text">기준서를 선택해주세요</div>
            </div>
            <IconComponent iconType={IconType.CHEVRON_DOWN} />
          </div>
        </Dropdown>
      )
    }

    const items: MenuProps['items'] = _.map(indexItems.indexList, (indexItem) => {
      return {
        label: getIndexItemIdString(indexItem) + ' ' + indexItem.name,
        key: indexItem.id,
        onClick: () => {
          const firstChildIndexItem = findFirstChildIndexItem(indexItem)
          setSelectedOneDepthIndexItem(indexItem)
          setSelectedIndexItem(firstChildIndexItem)
          navigate(
            ['', 'standard', selectedStandardId, indexItem.id, firstChildIndexItem.id].join('/')
          )
        }
      }
    })

    const itemId = getIndexItemIdString(selectedOneDepthIndexItem)
    return (
      <Dropdown
        menu={{ items }}
        overlayClassName="new-lnb-one-depth-dropdown-overlay-frame"
        trigger={['click']}
      >
        <div
          className={classNames({
            'new-lnb-view-one-depth-dropdown-frame': true,
            'new-lnb-view-one-depth-dropdown-frame-has-item': true
          })}
        >
          <div className="new-lnb-view-one-depth-dropdown-text-frame">
            <div className="new-lnb-view-one-depth-dropdown-text-icon">
              <IconComponent iconType={IconType.BOOKMARK_OUTLINE} />
            </div>
            <div className="new-lnb-view-one-depth-dropdown-text">
              {`${itemId} ${selectedOneDepthIndexItem.name}`}
            </div>
          </div>
          <IconComponent iconType={IconType.CHEVRON_DOWN} />
        </div>
      </Dropdown>
    )
  }

  const filterIcon = useMemo(() => {
    if (!selectedOneDepthIndexItem) {
      return <IconComponent isSvg enable="disable" iconType={IconType.FILTER} />
    }

    if (isEmpty(searchStandardCountMap)) {
      return <IconComponent isSvg iconType={IconType.FILTER} />
    }

    return (
      <Badge dot color="blue">
        <IconComponent
          isSvg
          clickCallbackFunc={() => {
            setShowPopover((prev) => !prev)
          }}
          enable="enable-blue"
          iconType={IconType.FILTER}
        />
      </Badge>
    )
  }, [selectedOneDepthIndexItem, searchStandardCountMap])

  const makePopover = () => {
    if (!showPopover) {
      return null
    }

    return (
      <div className="new-lnb-search-history-popover-frame" tabIndex={100}>
        <div className="new-lnb-search-history-popover-title-frame">
          <div className="new-lnb-search-history-popover-title-text">검색어</div>
          <div
            className="new-lnb-search-history-popover-title-delete"
            onClick={() => {
              setSearchStandardColorMap(new Map())
              setSearchStandardCountMap(new Map())
            }}
          >
            모두 삭제
          </div>
        </div>
        <div className="new-lnb-search-history-popover-keyword-frame">
          <NewSearchKeywordListComponent renderItemBySpan />
        </div>
      </div>
    )
  }

  const makeSearchStandardControl = () => {
    return (
      <div className="new-lnb-view-search-frame">
        <NewSearchStandardComponent
          handleInputFocusCallbackFunc={() => {
            setShowPopover(true)
          }}
        />
        {filterIcon}
        {makePopover()}
      </div>
    )
  }

  return (
    <div className="new-lnb-view-frame">
      {makeOneDepthDropdown()}
      {makeSearchStandardControl()}
      {makeContentLists()}
    </div>
  )
}

export default NewLnbComponent
