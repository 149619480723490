/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ko_KR'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'

const { RangePicker } = DatePicker

interface DateRangeAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  showTime?: boolean
  minDate?: string
  maxDate?: string
}

export interface DateRange {
  startDate: string
  endDate: string
}

const DateRangeAgGridCellRendererComponent: React.FC<DateRangeAgGridCellRendererComponentProps> = ({
  node,
  column,
  eGridCell,
  showTime,
  minDate,
  maxDate
}) => {
  const [key, setKey] = useState(0)
  useEffect(() => {
    setKey((prev) => prev + 1)
  }, [maxDate])

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    node.setDataValue(column.getColId(), { startDate: dateStrings[0], endDate: dateStrings[1] })
  }

  const checkDisableDate = (currentDate: dayjs.Dayjs): boolean => {
    if (minDate && currentDate.startOf('day').isBefore(dayjs(minDate).startOf('day'))) {
      return true
    }
    if (maxDate && currentDate.startOf('day').isAfter(dayjs(maxDate).startOf('day'))) {
      return true
    }
    return false
  }

  if (node.data.rangeDate) {
    const { startDate, endDate } = node.data.rangeDate
    return (
      <div style={{ width: eGridCell.clientWidth, height: eGridCell.clientHeight }}>
        <RangePicker
          className="cck-renderer-date-range"
          defaultValue={[dayjs(startDate), dayjs(endDate)]}
          disabledDate={(currentDate) => currentDate && checkDisableDate(currentDate)}
          format={['YYYY-MM-DD', 'YYYYMMDD']}
          key={key}
          locale={locale}
          placeholder={['시작일 (YYYY-MM-DD)', '종료일 (YYYY-MM-DD)']}
          showTime={showTime}
          onChange={handleDateChange}
        />
      </div>
    )
  }
  const maxDatejs = dayjs(maxDate)

  return (
    <div style={{ width: eGridCell.clientWidth, height: eGridCell.clientHeight }}>
      <RangePicker
        className="cck-renderer-date-range"
        defaultPickerValue={[maxDatejs.add(-1, 'month'), maxDatejs]}
        disabledDate={(currentDate) => currentDate && checkDisableDate(currentDate)}
        format={['YYYY-MM-DD', 'YYYYMMDD']}
        key={key}
        locale={locale}
        placeholder={['시작일 (YYYY-MM-DD)', '종료일 (YYYY-MM-DD)']}
        showTime={showTime}
        onChange={handleDateChange}
      />
    </div>
  )
}

export default DateRangeAgGridCellRendererComponent
