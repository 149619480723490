import classNames from 'classnames'
import React from 'react'

import { IconType } from 'components/icon/IconBaseType'
import IconComponent from 'components/icon/IconComponent'

import './checkbox.scss'

interface CheckboxComponentProps {
  checked?: boolean
  disabled?: boolean
  size?: number
  callbackCheckboxStateChange?: (checked: boolean) => void
}

// Common Checkbox Component
const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  checked = false,
  disabled = false,
  size,
  callbackCheckboxStateChange
}) => {
  const handleCheckboxClicked = () => {
    if (disabled && disabled === true) {
      return
    }

    callbackCheckboxStateChange && callbackCheckboxStateChange(!checked)
  }

  return (
    <div
      className={classNames({
        'cck-base-checkbox': true,
        ['checkbox-state-' + (disabled ? 'disable' : checked ? 'check' : 'enable')]: true
      })}
    >
      <IconComponent
        clickCallbackFunc={handleCheckboxClicked}
        enable={disabled ? 'disable' : 'enable-white'}
        iconType={IconType.CHECK}
        size={size}
      />
    </div>
  )
}

export default CheckboxComponent
