import { notEmpty, isEmpty } from '@cck/common'
import classNames from 'classnames'
import parse from 'html-react-parser'
import _, { cloneDeep } from 'lodash'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue, useRecoilCallback } from 'recoil'

import {
  bookmarkItemMapAtom,
  lastAddedBookmarkNameAtom,
  bookmarkDuplicateAddAlertAtom,
  bookmarkAreaOpenAtom,
  selectedBookmarkStandardIdAtom,
  removeBookmarkItemMapSelector,
  BookmarkContentItem,
  BookmarkItem
} from 'atoms/bookmark/Bookmark'
import { findIndexItemSelector, selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import { ReactComponent as BookmarkSelectedIcon } from 'resource/bookmark_selected.svg'
import { ReactComponent as BookmarkUnSelectedIcon } from 'resource/bookmark_unselected.svg'
import { Content, sortIds } from 'services/standard_client'
import { highlightKeywords, updateImageSrc } from 'utils/html_utils'

type DescriptionItemComponentProps = {
  contentItem: Content
  searchPatterns: { keyword: string; color: string }[]
}

const DescriptionItemComponent: React.FC<DescriptionItemComponentProps> = ({
  contentItem,
  searchPatterns
}) => {
  const location = useLocation()
  const selectedStandardId = useRecoilValue(selectedStandardIdAtom)
  const selectedOneDepthIndexItem = useRecoilValue(selectedOneDepthIndexItemAtom)
  const bookmarkItemMap = useRecoilValue(bookmarkItemMapAtom)
  const removeBookmarkItemMap = useSetRecoilState(removeBookmarkItemMapSelector)

  // 이미 추가된 북마크를 제외한 나머지 추가될 북마크 아이템 생성
  // bookmarkItem.bookmarkContentList : 이미 추가된 북마크
  // bookmarkContentItemList : 추가하려하는 북마크 리스트
  const remakeBookmarkContentItemList = (
    bookmarkItem: BookmarkItem,
    bookmarkContentItemList: BookmarkContentItem[]
  ) => {
    const newBookmarkContentItemList: BookmarkContentItem[] = []
    bookmarkContentItemList.forEach((bookmarkContentItem) => {
      const found = bookmarkItem.bookmarkContentList.find(
        (oldBookmarkContentItem) =>
          oldBookmarkContentItem.bookmarkName === bookmarkContentItem.bookmarkName
      )

      // 찾지 못한 경우 === 아직 추가되지 않은 북마크인 경우
      if (!found) {
        newBookmarkContentItemList.push(bookmarkContentItem)
      }
    })

    return newBookmarkContentItemList
  }

  const addBookmarkWithSortedIds = useRecoilCallback<[string[], string[], string, string], void>(
    ({ set }) =>
      async (bookmarkNames, ids, oneDepthIndexId, oneDepthIndexName) => {
        if (!selectedOneDepthIndexItem) {
          return
        }

        if (bookmarkNames.length !== ids.length) {
          return
        }

        const bookmarkContentItemList: BookmarkContentItem[] = []
        for (let i = 0; i < bookmarkNames.length; i += 1) {
          bookmarkContentItemList.push({ bookmarkName: bookmarkNames[i], id: ids[i] })
        }

        // set bookmark name atom to find auto scroll target html
        // (BookmarkContentComponent.tsx file)
        set(lastAddedBookmarkNameAtom, bookmarkNames[0])
        // set selected standardId
        set(selectedBookmarkStandardIdAtom, selectedStandardId)

        const newBookmarkItemMap = cloneDeep(bookmarkItemMap)
        const oneDepthBookmarkItemMap = newBookmarkItemMap.get(selectedStandardId)
        // new standardId bookmark item
        if (!oneDepthBookmarkItemMap) {
          const newOneDepthBookmarkItem: BookmarkItem = {
            oneDepthIndexName,
            bookmarkContentList: bookmarkContentItemList
          }

          const newOneDepthBookmarkItemMap = new Map<string, BookmarkItem>()
          newOneDepthBookmarkItemMap.set(oneDepthIndexId, newOneDepthBookmarkItem)
          newBookmarkItemMap.set(selectedStandardId, newOneDepthBookmarkItemMap)
          set(bookmarkItemMapAtom, newBookmarkItemMap)

          // bookmark area open
          set(bookmarkAreaOpenAtom, true)
          return
        }

        const bookmarkItem = oneDepthBookmarkItemMap.get(oneDepthIndexId)
        // new oneDepthId bookmark item
        if (!bookmarkItem) {
          const newOneDepthBookmarkItem: BookmarkItem = {
            oneDepthIndexName,
            bookmarkContentList: bookmarkContentItemList
          }
          oneDepthBookmarkItemMap.set(oneDepthIndexId, newOneDepthBookmarkItem)
          newBookmarkItemMap.set(selectedStandardId, oneDepthBookmarkItemMap)
          set(bookmarkItemMapAtom, newBookmarkItemMap)
          return
        }

        const newBookmarkContentItemList = remakeBookmarkContentItemList(
          bookmarkItem,
          bookmarkContentItemList
        )

        if (newBookmarkContentItemList.length === 0) {
          // be added bookmark item
          set(bookmarkDuplicateAddAlertAtom, true)
          return
        }

        // add oneDepthId bookmarkItem
        // sort ids
        const unsortedIds: string[] = _.map(newBookmarkContentItemList, 'id')
        bookmarkItem.bookmarkContentList.forEach((bookmarkContentItem) => {
          unsortedIds.push(bookmarkContentItem.id)
        })

        const sortedIdList = await sortIds(selectedStandardId, unsortedIds)
        bookmarkItem.bookmarkContentList =
          bookmarkItem.bookmarkContentList.concat(bookmarkContentItemList)

        // remake bookmarkItem.bookmarkContentList
        bookmarkItem.bookmarkContentList.sort(
          (a, b) => sortedIdList.ids.indexOf(a.id) - sortedIdList.ids.indexOf(b.id)
        )

        oneDepthBookmarkItemMap.set(oneDepthIndexId, bookmarkItem)
        newBookmarkItemMap.set(selectedStandardId, oneDepthBookmarkItemMap)
        set(bookmarkItemMapAtom, newBookmarkItemMap)
      },
    [bookmarkItemMap, selectedOneDepthIndexItem, selectedStandardId]
  )

  const addBookmarkInOtherStandard = useRecoilCallback<
    [string, { id: string; name: string }[], string],
    void
  >(
    ({ snapshot }) =>
      async (standardId, bookmarkIds) => {
        const foundItem = await snapshot.getPromise(
          findIndexItemSelector({ standardId, id: bookmarkIds[0].id.split('.')[0] })
        )
        if (!foundItem) {
          // eslint-disable-next-line no-console
          console.log('Invalid bookmark', bookmarkIds)
          return
        }

        addBookmarkWithSortedIds(
          _.map(bookmarkIds, 'name'),
          _.map(bookmarkIds, 'id'),
          foundItem.id,
          foundItem.name
        )
      },
    [addBookmarkWithSortedIds]
  )

  /*
   * NOTE 북마크 중복 alert 필요 시, 주석 로직 이용 필요
  const handleDuplicateBookmark = () => {
    setBookmarkDuplicateAddAlert(true)
  }
  */

  const handleAddBookmark = () => {
    if (!selectedOneDepthIndexItem) {
      return
    }

    addBookmarkWithSortedIds(
      [contentItem.name],
      [contentItem.id],
      selectedOneDepthIndexItem.id,
      selectedOneDepthIndexItem.name
    )
  }

  const handleRemoveBookmark = () => {
    if (!selectedOneDepthIndexItem) {
      return
    }
    removeBookmarkItemMap({
      standardId: selectedStandardId,
      oneDepthIndexId: selectedOneDepthIndexItem.id,
      bookmarkName: contentItem.name
    })
  }

  const isBookmarked = () => {
    if (!selectedOneDepthIndexItem) {
      return false
    }

    const oneDepthBookmarkItemMap = bookmarkItemMap.get(selectedStandardId)
    if (isEmpty(oneDepthBookmarkItemMap)) {
      return false
    }

    const bookmarkItem = oneDepthBookmarkItemMap.get(selectedOneDepthIndexItem.id)
    if (isEmpty(bookmarkItem)) {
      return false
    }

    const bookmarkContentList = bookmarkItem.bookmarkContentList
    let isAlreadyBookmarked = false
    bookmarkContentList.forEach((bookmarkContentItem) => {
      if (bookmarkContentItem.bookmarkName === contentItem.name) {
        isAlreadyBookmarked = true
        return true
      }
    })

    return isAlreadyBookmarked
  }

  const makeBookmarkIcon = (isAlreadyBookmarked: boolean) => {
    if (isAlreadyBookmarked) {
      return (
        <div className="Bookmark" onClick={handleRemoveBookmark}>
          <BookmarkSelectedIcon className="BookmarkIconSvg" />
        </div>
      )
    }

    return (
      <div className="Bookmark" onClick={handleAddBookmark}>
        <BookmarkUnSelectedIcon className="BookmarkIconSvg" />
      </div>
    )
  }

  const handleBookmarkInTextClick = (event: React.MouseEvent) => {
    const target = event.target
    if (
      target instanceof HTMLSpanElement &&
      target.className === 'bookmark' &&
      notEmpty(target.textContent) &&
      notEmpty(target.dataset?.bookmarkids) &&
      selectedOneDepthIndexItem
    ) {
      const index = JSON.parse(target.dataset.bookmarkids)
      addBookmarkWithSortedIds(
        _.map(index, 'name'),
        _.map(index, 'id'),
        selectedOneDepthIndexItem.id,
        selectedOneDepthIndexItem.name
      )
    } else if (
      target instanceof HTMLSpanElement &&
      target.className === 'bookmark_other_standard' &&
      notEmpty(target.textContent) &&
      notEmpty(target.dataset?.bookmarkids) &&
      notEmpty(target.dataset?.standard)
    ) {
      addBookmarkInOtherStandard(
        target.dataset.standard,
        JSON.parse(target.dataset.bookmarkids),
        target.textContent
      )
    }
  }

  const pathnames = location.pathname.split('/')
  const pathLastName = decodeURIComponent(pathnames[pathnames.length - 1])
  const splitIds = contentItem.id.split('.')
  const lastId = splitIds[splitIds.length - 1]

  const makeBookmarkIconArea = () => {
    const isAlreadyBookmarked = isBookmarked()

    return (
      <div
        className={classNames({
          DescriptionName: true,
          DescriptionNameWithBookmark: isAlreadyBookmarked
        })}
        id={lastId}
      >
        {makeBookmarkIcon(isAlreadyBookmarked)}
        <p>{contentItem.name}</p>
      </div>
    )
  }

  return (
    <div
      className={classNames({
        DescriptionItem: true,
        DescriptionItemLinkHighlight: lastId === pathLastName
      })}
    >
      {contentItem.bookmarkable && makeBookmarkIconArea()}
      <div className="DescriptionText" onClick={handleBookmarkInTextClick}>
        {parse(contentItem.content, {
          replace: (node) => {
            const image = updateImageSrc(node)
            if (image) {
              return <>{image}</>
            }
            if (notEmpty(searchPatterns)) {
              const keywordTexts = highlightKeywords(searchPatterns, node)
              if (keywordTexts) {
                return <>{keywordTexts}</>
              }
            }
          }
        })}
      </div>
    </div>
  )
}
export default DescriptionItemComponent
