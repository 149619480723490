import { LinearProgress } from '@mui/material'
import classNames from 'classnames'
import React, { Suspense } from 'react'
import PanelGroup from 'react-panelgroup'
import { useRecoilValue, useRecoilState } from 'recoil'

import {
  bookmarkAreaOpenAtom,
  isBookmarkAreaVerticalAtom,
  verticalBookmarkAreaWidthAtom,
  horizontalBookmarkAreaHeightAtom
} from 'atoms/bookmark/Bookmark'
import BookmarkDuplicateAddAlertComponent from 'components/bookmark/BookmarkDuplicateAddAlertComponent'
import HorizontalBookmarkAreaComponent from 'components/bookmark/HorizontalBookmarkAreaComponent'
import VerticalBookmarkAreaComponent from 'components/bookmark/VerticalBookmarkAreaComponent'
import ContentFrameComponent from 'components/content/ContentFrameComponent'
import PortalComponent from 'components/portal/PortalComponent'

// @types/react-panelgroup export 오류
// onResizeEnd func을 지원하지만 @types version에서는
// 해당 함수를 export 시키지 않고 있음
// 또한 onResizeEnd는 onUpdate와 동일한 input parameter로 동작하는 함수지만
// library의 index.d.ts 파일에 onUpdate 함수의 input parameter를 PanelWidth로 작성해놓음
// 실제로는 PanelWidth[]가 들어옴
// 또, PanelWidth에 대한 타입을 export하고 있지 않아 로컬에 선언하여 사용.
// Panel에서 size 값만 사용할 예정이므로 나머지 변수는 선언하지 않음
interface PanelWidth {
  size?: number
}

const NewBodyComponent: React.FC = () => {
  const bookmarkAreaOpen = useRecoilValue(bookmarkAreaOpenAtom)
  const isBookmarkAreaVertical = useRecoilValue(isBookmarkAreaVerticalAtom)

  const [verticalBookmarkAreaWidth, setVerticalBookmarkAreaWidth] = useRecoilState(
    verticalBookmarkAreaWidthAtom
  )
  const [horizontalBookmarkAreaHeight, setHorizontalBookmarkAreaHeight] = useRecoilState(
    horizontalBookmarkAreaHeightAtom
  )

  const makeBookmarkAreaVertical = () => {
    if (!isBookmarkAreaVertical) {
      return null
    }

    return (
      <div
        className={classNames({
          BookmarkVerticalFrame: true,
          BookmarkVerticalFrameWithBookmarkClose: !bookmarkAreaOpen
        })}
      >
        <div className="RightSideFrame">
          <Suspense fallback={<LinearProgress className="linear_progress" />}>
            <VerticalBookmarkAreaComponent />
          </Suspense>
        </div>
      </div>
    )
  }

  const makeBookmarkAreaHorizontal = () => {
    if (isBookmarkAreaVertical) {
      return null
    }

    const node = document.getElementsByClassName('ContentTextArea')

    return (
      <Suspense fallback={<LinearProgress className="linear_progress" />}>
        <HorizontalBookmarkAreaComponent />
        <PortalComponent parentElement={node[0] as HTMLElement}>
          <div style={{ height: '50%' }}></div>
        </PortalComponent>
      </Suspense>
    )
  }

  const calculateVerticalBookmarkAreaWidth = () => {
    if (bookmarkAreaOpen) {
      return verticalBookmarkAreaWidth
    } else {
      return 0
    }
  }

  const getContentWidth = () => {
    return window.innerWidth > 1920 ? 1920 - 240 : window.innerWidth - 240
  }

  const getContentHeight = () => {
    return window.innerHeight - 64
  }

  const calculateVerticalContentsAreaWidth = () => {
    const contentWidth = getContentWidth()
    return contentWidth - calculateVerticalBookmarkAreaWidth()
  }

  const calculateVerticalContentsAreaMinWidth = () => {
    const contentWidth = getContentWidth()

    // (static content width - leftFrame) / 2
    return (contentWidth - 300) / 2
  }

  const calculateHorizontalBookmarkAreaMaxHeight = () => {
    const contentHeight = getContentHeight()
    return contentHeight / 2
  }

  const calculateHorizontalBookmarkAreaHeight = () => {
    if (bookmarkAreaOpen) {
      return horizontalBookmarkAreaHeight
    } else {
      return 0
    }
  }

  const calculateHorizontalContentsAreaHeight = () => {
    const contentHeight = getContentHeight()
    return contentHeight - calculateHorizontalBookmarkAreaHeight()
  }

  const makeBookmarkAreaVerticalWithContentFrame = () => {
    if (bookmarkAreaOpen) {
      return (
        <Suspense fallback={<LinearProgress className="linear_progress" />}>
          <PanelGroup
            borderColor="#E9ECEF"
            panelWidths={[
              {
                size: calculateVerticalContentsAreaWidth(),
                minSize: calculateVerticalContentsAreaMinWidth(),
                resize: 'stretch'
              },
              { size: calculateVerticalBookmarkAreaWidth(), minSize: 300, resize: 'dynamic' }
            ]}
            onResizeEnd={(data: PanelWidth[]) => {
              if (data.length !== 2 || !data[1].size) {
                return
              }

              setVerticalBookmarkAreaWidth(data[1].size)
            }}
          >
            <ContentFrameComponent />
            {makeBookmarkAreaVertical()}
          </PanelGroup>
        </Suspense>
      )
    }

    return (
      <Suspense fallback={<LinearProgress className="linear_progress" />}>
        <ContentFrameComponent />
        {makeBookmarkAreaVertical()}
      </Suspense>
    )
  }

  const makeBookmarkAreaHorizontalWithContentFrame = () => {
    if (!bookmarkAreaOpen) {
      return (
        <Suspense fallback={<LinearProgress className="linear_progress" />}>
          <ContentFrameComponent />
        </Suspense>
      )
    }

    return (
      <Suspense fallback={<LinearProgress className="linear_progress" />}>
        <ContentFrameComponent>
          <div className="BookmarkHorizontalFrame">
            <PanelGroup
              borderColor="#E9ECEF"
              direction="column"
              panelWidths={[
                {
                  size: calculateHorizontalContentsAreaHeight(),
                  minSize: calculateHorizontalBookmarkAreaMaxHeight(),
                  resize: 'stretch'
                },
                { size: calculateHorizontalBookmarkAreaHeight(), minSize: 300, resize: 'dynamic' }
              ]}
              onResizeEnd={(data: PanelWidth[]) => {
                if (data.length !== 2 || !data[1].size) {
                  return
                }

                setHorizontalBookmarkAreaHeight(data[1].size)
              }}
            >
              <div className="BookmarkHorizontalTopAreaFrame"></div>
              <div className="BookmarkHorizontalBottomAreaFrame">
                {makeBookmarkAreaHorizontal()}
              </div>
            </PanelGroup>
          </div>
        </ContentFrameComponent>
      </Suspense>
    )
  }

  const makeContentsBookmarkArea = () => {
    if (isBookmarkAreaVertical) {
      return makeBookmarkAreaVerticalWithContentFrame()
    }

    return makeBookmarkAreaHorizontalWithContentFrame()
  }

  return (
    <div className="BodyContainer">
      {makeContentsBookmarkArea()}
      <BookmarkDuplicateAddAlertComponent />
    </div>
  )
}
export default NewBodyComponent
