import { IconType } from 'components/icon/IconBaseType'
import { NewInputControlTypeProps } from 'components/input/NewInputControlComponent'
import { convertToBusinessNumber, emailVaildCheckBeforeInput } from 'utils/format/format_utils'

export const NewInputControlType = {
  NONE: '',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  NEWPASSWORD: 'NEWPASSWORD',
  NEWPASSWORDCHECK: 'NEWPASSWORDCHECK',
  PROJECTNAME: 'PROJECTNAME',
  CONFIRMWORD: 'CONFIRMWORD',
  YEAR: 'YEAR',
  SEARCH: 'SEARCH',
  SEARCH_PROJECT_ROOM: 'SEARCH_PROJECT_ROOM',
  BUSINESS_INFO: 'BUSINESS_INFO',
  COMPANY_INFO: 'COMPANY_INFO',
  STOCK: 'STOCK',
  BUSINESS_NUMBER: 'BUSINESS_NUMBER',
  COMPANY_NAME: 'COMPANY_NAME',
  COMPANY_REPRESENTATIVE: 'COMPANY_REPRESENTATIVE',
  REFERENCE_DATE: 'REFERENCE_DATA',
  MANAGER: 'MANAGER',
  MANAGER_EMAIL: 'MANAGER_EMAIL',
  MANAGER_PHONE: 'MANAGER_PHONE',
  SEARCH_BUSINESS_INFO: 'SEARCH_BUSINESS_INFO',
  PHONE: 'PHONE'
} as const

export type NewInputControlType = typeof NewInputControlType[keyof typeof NewInputControlType]

type Hook = (inputControlType: NewInputControlType) => NewInputControlTypeProps

// useNewInputControl은 input component 생성 시 type에 따라 default로 생성되는 값들을 정의해주는 hook
export const useNewInputControl: Hook = (inputControlType: NewInputControlType) => {
  const getPlaceholderText = () => {
    switch (inputControlType) {
      case NewInputControlType.EMAIL:
        return '이메일 주소를 입력하세요'
      case NewInputControlType.PASSWORD:
        return '비밀번호를 입력하세요'
      case NewInputControlType.NEWPASSWORD:
        return '비밀번호를 입력하세요'
      case NewInputControlType.NEWPASSWORDCHECK:
        return '비밀번호를 한 번 더 입력하세요'
      case NewInputControlType.PROJECTNAME:
        return '프로젝트 이름을 입력하세요'
      case NewInputControlType.SEARCH_PROJECT_ROOM:
        return '프로젝트 룸, 팀원, 회사 프로젝트 코드'
      case NewInputControlType.CONFIRMWORD:
        return ''
      case NewInputControlType.YEAR:
        return 'YYYY'
      case NewInputControlType.SEARCH:
        return '홍길동, kdhong@per.gg, 서울, 1본부'
      case NewInputControlType.BUSINESS_INFO:
        return '업무내용'
      case NewInputControlType.COMPANY_INFO:
        return '회사명'
      case NewInputControlType.STOCK:
        return ''
      case NewInputControlType.BUSINESS_NUMBER:
        return '사업자등록번호를 입력해주세요'
      case NewInputControlType.COMPANY_NAME:
        return '회사명을 입력해주세요'
      case NewInputControlType.COMPANY_REPRESENTATIVE:
        return '대표자명을 입력해주세요'
      case NewInputControlType.REFERENCE_DATE:
        return '조회 기준일을 입력해주세요'
      case NewInputControlType.MANAGER:
        return '담당자명을 입력해주세요'
      case NewInputControlType.MANAGER_EMAIL:
        return '이메일 주소를 입력해주세요'
      case NewInputControlType.MANAGER_PHONE:
        return '연락처를 입력해주세요'
      case NewInputControlType.SEARCH_BUSINESS_INFO:
        return '검색어를 입력하세요'
      default:
        return ''
    }
  }

  const placeholderText = getPlaceholderText()

  const getPrefixIconProp = () => {
    switch (inputControlType) {
      case NewInputControlType.SEARCH:
      case NewInputControlType.SEARCH_PROJECT_ROOM:
        return {
          iconType: IconType.SEARCH,
          className: 'new-search-control-icon '
        }
      default:
        break
    }
    return undefined
  }
  const prefixIconProp = getPrefixIconProp()

  const getSuffixIconProp = () => {
    switch (inputControlType) {
      case NewInputControlType.CONFIRMWORD:
        return {
          iconType: IconType.ERROR
        }
      default:
        break
    }
    return undefined
  }
  const suffixIconProp = getSuffixIconProp()

  const getClassName = () => {
    switch (inputControlType) {
      case NewInputControlType.PASSWORD:
        return 'cck-password-input'
      case NewInputControlType.EMAIL:
        return 'cck-email-input'
      case NewInputControlType.SEARCH:
      case NewInputControlType.SEARCH_PROJECT_ROOM:
        return 'cck-search-input'
      default:
        return 'cck-basic-input'
    }
  }
  const className = getClassName()

  const inputTextValidateFunc = (text: string) => {
    switch (inputControlType) {
      case NewInputControlType.EMAIL:
      case NewInputControlType.MANAGER_EMAIL:
        return emailVaildCheckBeforeInput(text)
      default:
        return false
    }
  }

  const predefinedChangeInputTextFunc = (text: string) => {
    switch (inputControlType) {
      case NewInputControlType.BUSINESS_NUMBER:
        return convertToBusinessNumber(text.toString().replace(/[^0-9]/g, ''))
      // case NewInputControlType.MANAGER_PHONE:
      // case NewInputControlType.PHONE:
      //   return convertToPhoneNumber(text.toString().replace(/[^0-9]/g, ''))
      default:
        return text
    }
  }

  return {
    placeholderText,
    prefixIconProp,
    suffixIconProp,
    className,
    inputTextValidateFunc,
    predefinedChangeInputTextFunc
  }
}
