import { Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import classNames from 'classnames'
import React, { useState } from 'react'

import { IconBaseComponentProps } from 'components/icon/IconBaseType'
import IconComponent from 'components/icon/IconComponent'
import { NewInputControlComponentProps } from 'components/input/NewInputControlComponent'

// NOTE (hyeonseok 23.08.08):
// processCompositionEnter state 존재 이유
// 한글과 같이 키 조합으로 새로운 텍스트를 만들어내는 composition 텍스트 입력인 경우의
// 이벤트 처리 상 enter 키 입력 시 change event, enter event가 한 번씩 더 발생하는 이슈가 있음
// (known issue)
// 예시.
// 한글 '가나' 입력 과정

// keyboard event : 'ㄱ', 'ㅏ', 'ㄴ', 'ㅏ'
// change event   : 'ㄱ', '가', '간', '가', '가나'
// 네 번째의 '가'의 경우 '간' + 'ㅏ' 입력 시, 컴포지션이 끝난 결과물인 '가'는 change event가 별도로 발생

// '가나' 까지 입력이 완료된 이후, enter event가 발생 시
// 1. enter event occur (nativeEvent.isComposing = true)
// 2. change event occur ('나', 마지막 composition 타겟을 대상으로 change event 발생)
// 3. evnter event occur (nativeEvent.isComposiing = false)
// 위의 과정을 통해 이벤트가 처리됨 (마지막의 컴포지션 타겟 텍스트를 input element 에서 비우는 과정)
// 올바르지 않은 마지막 change event를 처리하지 않기 위해 processCompositionEnter state를 사용
// antd Input이외에도 html input element도 동일한 문제 존재 (크로미움 버그로 예상)

type Hook = (inputControlComponentProps: NewInputControlComponentProps) => React.ReactNode

export const useCreateInput: Hook = (inputControlComponentProps: NewInputControlComponentProps) => {
  const [processCompositionEnter, setProcessCompositionEnter] = useState(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (processCompositionEnter) {
      return
    }

    const predefinedChangeInput =
      inputControlComponentProps.inputControlTypeProps?.predefinedChangeInputTextFunc

    const text = predefinedChangeInput
      ? predefinedChangeInput(event.target.value)
      : event.target.value

    inputControlComponentProps.changeInputFunc && inputControlComponentProps.changeInputFunc(text)
  }
  const handleBlur = () => {
    inputControlComponentProps.blurInputFunc && inputControlComponentProps.blurInputFunc()
  }

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProcessCompositionEnter(event.nativeEvent.isComposing)

    if (processCompositionEnter) {
      return
    }

    inputControlComponentProps.completeInputFunc &&
      inputControlComponentProps.completeInputFunc(event)
  }

  const getMaxLength = () => {
    return (
      inputControlComponentProps.maxLength ?? (inputControlComponentProps.useTextArea ? 500 : 50)
    )
  }

  const getMaxLengthProps = () => {
    if (inputControlComponentProps.needMaxLength === false) {
      return undefined
    }
    return getMaxLength()
  }

  const getStatus = () => {
    if (
      inputControlComponentProps.initText &&
      inputControlComponentProps.initText?.length >= (getMaxLength() || 0)
    ) {
      return 'error'
    }
    switch (inputControlComponentProps.validateState) {
      case 'Invalid':
        return 'error'
      case 'Warning':
        return 'warning'
      default:
        return ''
    }
  }

  const getIconComponent = (iconProp?: IconBaseComponentProps) => {
    if (!iconProp) {
      return null
    }
    return <IconComponent {...iconProp} />
  }

  const prefixIcon = getIconComponent(
    inputControlComponentProps.inputControlTypeProps?.prefixIconProp
  )
  const suffix = getIconComponent(inputControlComponentProps.inputControlTypeProps?.suffixIconProp)
  const maxLengthProps = inputControlComponentProps.needMaxLength
    ? { maxLength: getMaxLengthProps() }
    : {}
  const renderInput = () => {
    return (
      <Input
        // allowClear
        autoFocus={inputControlComponentProps.initFocus}
        className={classNames({
          'cck-input': true,
          [`${inputControlComponentProps.inputControlTypeProps?.className}`]: true,
          [`${inputControlComponentProps.className}`]: true
        })}
        disabled={inputControlComponentProps.disabled}
        placeholder={inputControlComponentProps.inputControlTypeProps?.placeholderText}
        prefix={prefixIcon}
        size="large"
        status={getStatus()}
        suffix={suffix}
        type={inputControlComponentProps.inputType}
        value={inputControlComponentProps.initText}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={inputControlComponentProps.onKeyDownFunc}
        onPressEnter={handlePressEnter}
        {...maxLengthProps}
      />
    )
  }

  const renderTextArea = () => {
    return (
      <TextArea
        autoFocus={inputControlComponentProps.initFocus}
        className={classNames({
          'cck-input': true,
          'cck-input-textarea': true,
          [`${inputControlComponentProps.inputControlTypeProps?.className}`]: true,
          [`${inputControlComponentProps.className}`]: true
        })}
        disabled={inputControlComponentProps.disabled}
        placeholder={inputControlComponentProps.inputControlTypeProps?.placeholderText}
        size="large"
        status={getStatus()}
        style={{ resize: 'none' }}
        value={inputControlComponentProps.initText}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={inputControlComponentProps.onKeyDownFunc}
        onPressEnter={handlePressEnter}
        {...maxLengthProps}
      />
    )
  }

  return inputControlComponentProps.useTextArea ? renderTextArea() : renderInput()
}
