import { isEmpty } from '@cck/common'
import React from 'react'
import { useRecoilValue } from 'recoil'

import {
  searchStandardCountMapAtom,
  searchStandardColorMapAtom,
  SEARCH_CONTENT_KEYWORD_COLOR_LIST
} from 'atoms/search/Search'
import { findSearchCountItem } from 'components/util/SearchUtil'

export enum SearchKeywordCountItemAreaType {
  // eslint-disable-next-line no-unused-vars
  LNB = 'LNB',
  // eslint-disable-next-line no-unused-vars
  DROPDOWN_STANDARD = 'DROPDOWN_STANDARD',
  // eslint-disable-next-line no-unused-vars
  DROPDOWN_ONE_DEPTH = 'DROPDOWN_ONE_DEPTH'
}

type NewSearchKeywordCountItemComponentProps = {
  keyword: string
  indexItemId: string
  itemArea: SearchKeywordCountItemAreaType
  itemIndex?: number
}

const NewSearchKeywordCountItemComponent: React.FC<NewSearchKeywordCountItemComponentProps> = ({
  keyword,
  indexItemId,
  itemArea,
  itemIndex = 0
}) => {
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)
  const searchStandardColorMap = useRecoilValue(searchStandardColorMapAtom)
  const makeSearchCountItem = () => {
    if (isEmpty(searchStandardCountMap)) {
      return
    }

    const searchCounts = searchStandardCountMap.get(keyword)
    if (isEmpty(searchCounts)) {
      return <></>
    }

    const isStandard = itemArea === SearchKeywordCountItemAreaType.DROPDOWN_STANDARD

    const found = findSearchCountItem(searchCounts, indexItemId, isStandard)
    if (!found) {
      return <></>
    }

    const colorIndex = searchStandardColorMap.get(keyword)
    let color = '#FFFFFF'
    if (colorIndex !== undefined && typeof colorIndex === 'number') {
      color = SEARCH_CONTENT_KEYWORD_COLOR_LIST[colorIndex]
    }

    if (isStandard) {
      return <div className="search-count-item-standard" style={{ backgroundColor: color }}></div>
    }

    if (itemIndex > 0) {
      return (
        <span
          className="search-count-item"
          style={{ backgroundColor: color, left: `${itemIndex * 6}px`, position: 'absolute' }}
        >
          {found.count}
        </span>
      )
    }

    return (
      <span className="search-count-item" style={{ backgroundColor: color }}>
        {found.count}
      </span>
    )
  }

  return <>{makeSearchCountItem()}</>
}

export default NewSearchKeywordCountItemComponent
