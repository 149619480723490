import { IconComponent, IconType } from '@cck/common'
import { Input, message } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import {
  searchStandardKeywordAtom,
  searchStandardCountMapAtom,
  addSearchStandardColorMapSelector,
  selectedSearchedKeywordAtom
} from 'atoms/search/Search'
import { searchKeyword } from 'services/standard_client'

type NewSearchStandardComponentProps = {
  isHeaderView?: boolean
  handleInputFocusCallbackFunc?: () => void
}

const NewSearchStandardComponent: React.FC<NewSearchStandardComponentProps> = ({
  isHeaderView = false,
  handleInputFocusCallbackFunc
}) => {
  const [processCompositionEnter, setProcessCompositionEnter] = useState(false)
  const [searchStandardCountMap, setSearchStandardCountMap] = useRecoilState(
    searchStandardCountMapAtom
  )
  const [searchStandardKeyword, setSearchStandardKeyword] =
    useRecoilState(searchStandardKeywordAtom)
  const selectedOneDepthIndexItem = useRecoilValue(selectedOneDepthIndexItemAtom)

  const setAddSearchStandardColorMap = useSetRecoilState(addSearchStandardColorMapSelector)
  const setSelectedSearchedKeyword = useSetRecoilState(selectedSearchedKeywordAtom)

  const handleChangeKeyword = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement
    setSearchStandardKeyword(target.value)
  }

  const handleKeyPressed = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return
    }
    if (searchStandardCountMap.size >= 6) {
      !isHeaderView &&
        message.config({
          top: 200
        })

      message.warning('검색 키워드는 6개까지만 가능합니다. 기존 검색어를 삭제 후 진행해 주세요.')
      return
    }

    handleSearchKeyword()
  }

  const handleSearchKeyword = () => {
    if (searchStandardKeyword === '') {
      return
    }
    searchKeyword(searchStandardKeyword).then((response) => {
      const newCountMap = _.cloneDeep(searchStandardCountMap)
      newCountMap.set(searchStandardKeyword, response.counts)
      setAddSearchStandardColorMap(searchStandardKeyword)

      setSelectedSearchedKeyword(searchStandardKeyword)
      setSearchStandardCountMap(newCountMap)
    })
  }

  const searchIcon = useMemo(() => {
    if (isHeaderView) {
      return null
    }
    return (
      <IconComponent
        enable={selectedOneDepthIndexItem !== undefined ? 'enable' : 'disable'}
        iconType={IconType.SEARCH_THIN}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeaderView, selectedOneDepthIndexItem])
  return (
    <div
      className={classNames({
        'search-standard-control-frame': true,
        'search-standard-control-header-frame': isHeaderView
      })}
    >
      <Input
        disabled={selectedOneDepthIndexItem === undefined}
        placeholder={isHeaderView ? '문단번호, 키워드로 통합 검색' : '기준서 내 검색'}
        prefix={searchIcon}
        onChange={handleChangeKeyword}
        onFocus={() => {
          if (isHeaderView) {
            return
          }

          handleInputFocusCallbackFunc && handleInputFocusCallbackFunc()
        }}
        onPressEnter={(e) => {
          setProcessCompositionEnter(e.nativeEvent.isComposing)
          e.preventDefault()
          e.stopPropagation()

          if (processCompositionEnter) {
            return
          }

          handleKeyPressed(e)
        }}
      />
    </div>
  )
}
export default NewSearchStandardComponent
