import { isEmpty } from '@cck/common'

interface NavigateProps {
  linkStandardId: string
  linkOneDepthIndexItemId: string
  linkIndexItemId: string
  linkItemName: string
}

export function getMoveReferenceDocument(metadata: { [key: string]: string }): NavigateProps {
  const documentUrl = metadata.standard_id
  const lastDepth = metadata.id
  if (isEmpty(lastDepth)) {
    return {
      linkStandardId: documentUrl,
      linkOneDepthIndexItemId: '',
      linkIndexItemId: '',
      linkItemName: ''
    }
  }
  const replaceText = lastDepth.replaceAll('_', '-')
  const splits = replaceText.split('.')
  return {
    linkStandardId: documentUrl,
    linkOneDepthIndexItemId: splits[0],
    linkIndexItemId: splits.slice(0, -1).join('.'),
    linkItemName: splits[splits.length - 1]
  }
}
