import { Column, RowNode } from 'ag-grid-community'
import React, { useRef, useState } from 'react'

import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'
import DropdownMenuComponent from 'components/dropdown/DropdownMenuComponent'
import DropdownMenuItemComponent, {
  DropdownMenuItemComponentProps
} from 'components/dropdown/DropdownMenuItemComponent'
import { IconType } from 'components/icon/IconBaseType'
import IconComponent from 'components/icon/IconComponent'
import LabelComponent from 'components/label/LabelComponent'
import { isEmpty } from 'utils/common_utils'

interface DropdownAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  value: string
  getDropdownMenuItemPropCallbackFunc?: (
    node: RowNode,
    column: Column
  ) => DropdownMenuItemComponentProps[]
  convertValueCallbackFunc?: (value: string) => string
  convertNodeCallbackFunc?: (value: string) => React.ReactNode
  placeholder?: string
  menuWidth?: number
}

const DropdownAgGridCellRendererComponent: React.FC<DropdownAgGridCellRendererComponentProps> = ({
  node,
  column,
  value,
  convertNodeCallbackFunc,
  getDropdownMenuItemPropCallbackFunc,
  convertValueCallbackFunc,
  callbackIsContentVisible,
  placeholder,
  eGridCell,
  menuWidth
}) => {
  const dropdownButtonRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)

  const getDropdownMenuItems = () => {
    if (!getDropdownMenuItemPropCallbackFunc) {
      return undefined
    }

    const dropdownMenuItem = getDropdownMenuItemPropCallbackFunc(node, column).map((itemProps) => {
      return (
        <DropdownMenuItemComponent
          customNode={itemProps.customNode}
          handleItemClickCallbackFunc={() => {
            handleDropdownMenuItemClick(itemProps)
          }}
          key={itemProps.key}
          value={itemProps.value}
        />
      )
    })

    return isEmpty(dropdownMenuItem) ? undefined : dropdownMenuItem
  }

  const dropDownItems = getDropdownMenuItems()

  const handleClick = () => {
    if (visible) {
      setVisible(false)
      return
    }

    if (dropDownItems) {
      setVisible(true)
    }
  }

  const handleOutsideClick = () => {
    setVisible(false)
  }

  const handleDropdownMenuItemClick = (itemProps: DropdownMenuItemComponentProps) => {
    // Check 값 반영
    node.setDataValue(column.getColId(), itemProps.key)

    // 지정된 callback 수행
    itemProps.handleItemClickCallbackFunc && itemProps.handleItemClickCallbackFunc()
  }

  const isPlaceholder = isEmpty(value) && placeholder

  const getValue = () => {
    return convertValueCallbackFunc ? convertValueCallbackFunc(value) : value
  }

  const contentVisible = callbackIsContentVisible ? callbackIsContentVisible(node, column) : true

  if (!contentVisible) {
    return <div className="cck-table-dropdown-button-wrapper" />
  }

  const makeValueNode = () => {
    let resultNode: React.ReactNode

    if (convertNodeCallbackFunc) {
      resultNode = convertNodeCallbackFunc(value)
    }

    const isDropdownItemExist = dropDownItems !== undefined && dropDownItems.length !== 0

    return (
      resultNode || (
        <>
          <LabelComponent
            classNames={isPlaceholder ? 'cck-table-label-placeholder' : ''}
            value={isPlaceholder ? placeholder : getValue()}
          />
          {isDropdownItemExist && (
            <div className="cck-table-dropdown-icon">
              <IconComponent enable="disable" iconType={IconType.DROPDOWN_BUTTON} size={20} />
            </div>
          )}
        </>
      )
    )
  }

  return (
    <div
      className="cck-renderer-common cck-table-dropdown-button-wrapper"
      ref={dropdownButtonRef}
      style={{ width: eGridCell.clientWidth }}
      onClick={handleClick}
    >
      {makeValueNode()}
      {visible && (
        <DropdownMenuComponent
          handleOutsideClickCallbackFunc={handleOutsideClick}
          menuWidth={menuWidth}
          parentElement={dropdownButtonRef && (dropdownButtonRef.current as HTMLElement)}
          wrapperClassName="cck-table-dropdown-button-wrapper"
        >
          {dropDownItems}
        </DropdownMenuComponent>
      )}
    </div>
  )
}

export default DropdownAgGridCellRendererComponent
