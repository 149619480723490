import React from 'react'

import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'
import DatePickerComponent from 'components/date/DatePickerComponent'

interface DateAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  showTime?: boolean
  disabledDirection?: boolean
  disabledDate?: string
}

const DateAgGridCellRendererComponent: React.FC<DateAgGridCellRendererComponentProps> = ({
  node,
  column,
  eGridCell,
  showTime,
  disabledDirection,
  disabledDate
}) => {
  const handleChange = (cellText: string) => {
    node?.setDataValue(column.getColId(), {
      date: new Date(cellText)
    })
  }

  const value = node?.data[column.getColId()]
  return (
    <div style={{ width: eGridCell.clientWidth, height: eGridCell.clientHeight }}>
      <DatePickerComponent
        date={value?.date}
        disabledDate={disabledDate ? new Date(disabledDate) : undefined}
        disabledDirection={disabledDirection}
        showTime={showTime}
        onChange={handleChange}
      />
    </div>
  )
}

export default DateAgGridCellRendererComponent
