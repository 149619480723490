import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

export type ScrollBarRefType = Scrollbars

type ScrollBarComponentProps = {
  children: React.ReactNode
  autoHideEnable?: boolean
  theme?: 'invisible' | 'light' | 'deep-dark' | 'dark' // default dark
  scrollBarRef?: React.RefObject<ScrollBarRefType>
}

const ScrollBarComponent: React.FC<ScrollBarComponentProps> = ({
  children,
  autoHideEnable = false,
  theme = 'dark',
  scrollBarRef
}) => {
  // NOTE (hyeonseok 23.07.30):
  // design system에 따라 색상 변경해야 함
  const lightColor = 'white'
  const darkColor = '#989fa6' // gray-500
  const deepDarkColor = '#252a23' // gray-800
  let backgroundColor = darkColor
  switch (theme) {
    case 'light':
      backgroundColor = lightColor
      break
    case 'deep-dark':
      backgroundColor = deepDarkColor
      break
    case 'dark':
    default:
      break
  }

  const renderTrackVertical = ({ style, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
    const trackStyle = {
      position: 'absolute' as const,
      top: '2px',
      right: '8px',
      bottom: '2px',
      width: '5px',
      borderRadius: '10px'
    }
    return <div style={{ ...style, ...trackStyle }} {...props} />
  }

  const renderTrackHorizontal = ({ style, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
    const trackStyle = {
      position: 'absolute' as const,
      left: '2px',
      bottom: '8px',
      right: '2px',
      height: '5px',
      borderRadius: '10px'
    }
    return <div style={{ ...style, ...trackStyle }} {...props} />
  }

  const renderThumbStyle = () => {
    if (theme === 'invisible') {
      return <div></div>
    }

    const commonStyle = {
      borderRadius: '10px',
      opacity: 0.3,
      width: '5px',
      height: '5px',
      cursor: 'pointer'
    }

    // theme === 'light' | 'dark'
    return <div style={{ ...commonStyle, backgroundColor }} />
  }

  return (
    <Scrollbars
      autoHide={autoHideEnable}
      ref={scrollBarRef}
      renderThumbHorizontal={renderThumbStyle}
      renderThumbVertical={renderThumbStyle}
      renderTrackHorizontal={renderTrackHorizontal}
      renderTrackVertical={renderTrackVertical}
    >
      {children}
    </Scrollbars>
  )
}

export default ScrollBarComponent
