import React, { useEffect, useState } from 'react'

import { CommonAgGridHeaderProps } from 'components/ccktable/control/AgGridTypes'
import CheckboxComponent from 'components/checkbox/CheckboxComponent'

interface CheckboxAgGridHeaderComponentProps extends CommonAgGridHeaderProps {
  isChecked?: boolean
}

const CheckboxAgGridHeaderComponent: React.FC<CheckboxAgGridHeaderComponentProps> = ({
  isChecked,
  api,
  column,
  columnApi
}) => {
  const [checkState, setCheckState] = useState<boolean>(isChecked || false)

  useEffect(() => {
    const dataArray: string[] = []
    api.forEachNode((node) => {
      dataArray.push(node.data[column.getColId()])
    })

    setCheckState(dataArray.every((item) => Boolean(item)))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.getModel().getRowCount()])

  const handleCheckboxStateChange = (checked: boolean) => {
    setCheckState(checked)
    if (api && columnApi) {
      api.forEachNode((node) => {
        node.setDataValue(column.getColId(), checked)
      })
    }
  }

  return (
    <div className="cck-table-custom-header-frame">
      <CheckboxComponent
        callbackCheckboxStateChange={handleCheckboxStateChange}
        checked={checkState}
        size={18}
      />
    </div>
  )
}

export default CheckboxAgGridHeaderComponent
