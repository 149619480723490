/* eslint-disable prettier/prettier */
import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import MainChatViewComponent from 'components/chat/MainChatViewComponent'
import NewMainViewFrameComponent from 'components/new_main/NewMainViewFrameComponent'

import 'App.scss'

const NewApp: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Navigate replace to="/standard" />} path="/" />
        <Route element={<NewMainViewFrameComponent />} path="/standard/*" />
        <Route element={<MainChatViewComponent />} path="/chat-kicpa-info/*" />
      </Routes>
    </BrowserRouter>
  )
}

export default NewApp
