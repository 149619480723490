import { Column, RowNode } from 'ag-grid-community'
import React from 'react'

import ButtonComponent, { ButtonComponentProps } from 'components/button/ButtonComponent'
import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'

interface SingleButtonAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  buttonComponentProps: ButtonComponentProps
  buttonClickCallbackFunc?: (node: RowNode, column: Column) => void
  buttonPropsByValueCallbackFunc?: (
    node: RowNode,
    column: Column
  ) => ButtonComponentProps | undefined
  buttonWidth?: string
}

const SingleButtonAgGridCellRendererComponent: React.FC<
  SingleButtonAgGridCellRendererComponentProps
> = ({
  buttonComponentProps,
  buttonClickCallbackFunc,
  buttonPropsByValueCallbackFunc,
  callbackIsContentVisible,
  node,
  column,
  buttonWidth
}) => {
  const singleButtonClickCallbackFunc = () => {
    buttonClickCallbackFunc && buttonClickCallbackFunc(node, column)
  }

  const getIsVisible = () => {
    if (callbackIsContentVisible) {
      return callbackIsContentVisible(node, column)
    }
    return true
  }

  const getRefinedButtonProps = (): ButtonComponentProps => {
    const newProp = buttonPropsByValueCallbackFunc && buttonPropsByValueCallbackFunc(node, column)
    buttonComponentProps.clickCallbackFunc = singleButtonClickCallbackFunc
    if (!newProp) {
      // 기존 prop 반환
      return buttonComponentProps
    }
    // 덮어쓴 prop 반환
    return {
      ...buttonComponentProps,
      ...newProp,
      clickCallbackFunc: singleButtonClickCallbackFunc
    }
  }

  return (
    <>
      {getIsVisible() && (
        <div className="cck-table-button-cell-frame">
          <div className="cck-table-button-cell-single" style={{ width: buttonWidth }}>
            <ButtonComponent {...getRefinedButtonProps()} />
          </div>
        </div>
      )}
    </>
  )
}

export default SingleButtonAgGridCellRendererComponent
