import { nanoid } from 'nanoid'
import React, { createContext, useContext, ReactNode, useState } from 'react'
import toast from 'react-hot-toast'

import { notEmpty } from 'common/utils'
import BackdropComponent from 'components/backdrop/BackdropComponent'
import BasicNotificationComponent from 'components/notification/BasicNotificationComponent'
import NotificationsHolderComponent from 'components/notification/NotificationsHolderComponent'

import './notification.scss'

export const NotificationProvideType = {
  USE_ALL: 'USE_ALL',
  USE_NOTIFICATION: 'USE_NOTIFICATION',
  USE_BACKDROP: 'USE_BACKDROP'
} as const

export type NotificationProvideType =
  typeof NotificationProvideType[keyof typeof NotificationProvideType]

// 기본 제공 옵션
export const NotificationType = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
  // add options
} as const

export type NotificationType = typeof NotificationType[keyof typeof NotificationType]

export interface NotificationContent {
  title?: string
  description?: string | ReactNode
  // add options
}

interface NotificationContextProps {
  expandNotification: boolean
  setExpandNotification: (expandNotification: boolean) => void
  showNotification: (type: NotificationType, content: NotificationContent) => void
  showBackdrop: (backdropText: string) => void
  closeBackdrop: () => void
  setIndiviualBackdrop: (backdropText: string) => string
  removeIndividualBackdrop: (key: string) => void
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined)

export const useMainNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error('useMainNotification must be used within a MainNotificationProvider')
  }
  return context
}

interface MainNotificationProviderProps {
  notificationProvideType?: NotificationProvideType
  children: ReactNode
}

const MainNotificationProvider: React.FC<MainNotificationProviderProps> = ({
  notificationProvideType = NotificationProvideType.USE_ALL,
  children
}) => {
  const [backdropText, setBackdropText] = useState<string | undefined>(undefined)
  const [individualBackdropMap, setIndividualBackdropMap] = useState<Map<string, string>>(new Map())

  const [expandNotification, setExpandNotification] = useState(true)

  const enableNotification =
    notificationProvideType === NotificationProvideType.USE_ALL ||
    notificationProvideType === NotificationProvideType.USE_NOTIFICATION

  const enableBackdrop =
    notificationProvideType === NotificationProvideType.USE_ALL ||
    notificationProvideType === NotificationProvideType.USE_BACKDROP

  // notification을 보여주는 함수
  const showNotification = (type: NotificationType, content: NotificationContent) => {
    if (!enableNotification) {
      return
    }

    const isBasicNotification =
      type === NotificationType.SUCCESS ||
      type === NotificationType.ERROR ||
      type === NotificationType.INFO ||
      type === NotificationType.WARNING

    if (isBasicNotification || typeof content.description !== 'string') {
      toast.custom(
        (t) => {
          return <BasicNotificationComponent content={content} toastId={t.id} type={type} />
        },
        {
          duration: Infinity
        }
      )

      return
    }

    // 추후 다른 형태 고려 필요
    toast(content.description || '')
  }

  const showBackdrop = (text: string) => {
    setBackdropText(text)
  }

  const closeBackdrop = () => {
    setBackdropText(undefined)
  }

  const setIndiviualBackdrop = (inputBackdropText: string): string => {
    const key = nanoid()

    setIndividualBackdropMap((prevMap) => {
      const newBackdropMap = new Map(prevMap)
      newBackdropMap.set(key, inputBackdropText)
      return newBackdropMap
    })

    return key
  }

  const removeIndividualBackdrop = (key: string) => {
    setIndividualBackdropMap((prevMap) => {
      const newBackdropMap = new Map(prevMap)
      newBackdropMap.delete(key)

      return newBackdropMap
    })
  }

  return (
    <NotificationContext.Provider
      value={{
        expandNotification,
        setExpandNotification,
        showNotification,
        showBackdrop,
        closeBackdrop,
        setIndiviualBackdrop,
        removeIndividualBackdrop
      }}
    >
      <NotificationsHolderComponent />
      {enableBackdrop && notEmpty(backdropText) && (
        <BackdropComponent open customGuideText={backdropText} />
      )}
      {individualBackdropMap.size > 0 && (
        <BackdropComponent
          open
          customGuideText={Array.from(individualBackdropMap.values()).at(
            individualBackdropMap.size - 1
          )}
        />
      )}
      {children}
    </NotificationContext.Provider>
  )
}

export default MainNotificationProvider
