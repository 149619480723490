/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Column,
  ColumnApi,
  GridApi,
  ProcessDataFromClipboardParams,
  RowNode
} from 'ag-grid-community'
import _ from 'lodash'

import { newNumberWithCommas } from 'common/utils/number/number_utils'
import { LabelTextAlign } from 'components/ccktable/control/LabelAgGridCellRendererComponent'
import { CCKTableHeightLayoutType } from 'components/ccktable/table/CCKTableComponent'
import { isEmpty } from 'utils/common_utils'
import { convertToBusinessNumber, removeEmptyStringArrays } from 'utils/format/format_utils'

export function checkValueIsUnique<T extends object>(
  targetObjectArray: T[],
  targetKey: string,
  targetValue: string
): boolean {
  return !targetObjectArray.some(
    (targetObject) => targetObject[targetKey as keyof T] === targetValue
  )
}

// json value들만 모아서 lower case로 변환
function getObjectValuesAsLowerCase<T extends object>(obj: T): (string | number | boolean)[] {
  const result: (string | number | boolean)[] = []
  for (const value of Object.values(obj)) {
    if (typeof value === 'string') {
      result.push(value.toLowerCase())
    } else if (typeof value === 'object' && value !== null) {
      result.push(...getObjectValuesAsLowerCase(value))
    } else {
      result.push(value)
    }
  }
  return result
}

export function searchJsonObjectIndexArrayByKeywords<T extends object>(
  inputJson: T[],
  searchKeywords: string[],
  isAndCondition = true
): number[] {
  // keyword list to lower case
  const searchKeys = searchKeywords.map((key) => key.toLowerCase())

  const result: number[] = []
  inputJson.forEach((json, index) => {
    // value 내에서만 검색해야하므로 value들만 따로 모아놓고, lower case로 변환
    const jsonString = JSON.stringify(getObjectValuesAsLowerCase(json))

    if (
      (isAndCondition ? Array.prototype.every : Array.prototype.some).call(
        searchKeys,
        (key: string) => jsonString.includes(key)
      )
    ) {
      result.push(index)
    }
  })

  return result
}

// grid 최대 높이를 넘어가는지 여부
function isGridMaxHeightOverflow(
  rowCount: number,
  maxHeight: number,
  gridApi?: GridApi | null
): boolean {
  // grid size 설정 정보
  const sizeInfo = gridApi?.getSizesForCurrentTheme()
  // row height 설정 값
  const rowHeight = sizeInfo?.rowHeight ?? 0
  // header height 설정 값
  const headerHeight = sizeInfo?.headerHeight ?? 0

  return headerHeight + rowCount * rowHeight > maxHeight
}

export function calculateTableMaxHeight(tableWrapper: HTMLDivElement): number {
  const childCount = tableWrapper.childElementCount
  if (childCount < 1) {
    // eslint-disable-next-line no-console
    console.error('CCKTable Child Count Zero Error:', tableWrapper)
    return 0
  }

  const currentHeight = tableWrapper.clientHeight
  if (childCount === 1) {
    // table만 있는 경우
    return currentHeight
  }

  // table 외에 다른 element가 있는 경우(ex. 검색창 등)
  let otherChildHeight = 0

  for (let i = 0; i < childCount - 1; i++) {
    otherChildHeight += tableWrapper.children[i].clientHeight
  }
  return currentHeight - otherChildHeight
}

// 보여지는 column에 대한 row data만 추출
function filterRowDataByVisibleColumns(rowData: any[], columnApi: ColumnApi): any[] {
  // 보여지는 column 추출
  const visibleColumns = columnApi.getAllDisplayedColumns()

  // 보여지는 Column을 키 값으로하는 row data 반환
  return rowData.map((row) =>
    // visibleColumns 배열의 각 요소에 대해 reduce 함수를 실행
    visibleColumns.reduce((filteredRow, column) => {
      if (column.getColDef().cellRendererParams.isSearchBlocked) {
        // 검색이 막혀진 column은 row data 추출에서 제외
        // ColumnDefGenerator.ts에서 cellRendererParams.isSearchBlocked를 true로 설정한 경우
        return filteredRow
      }

      if (column.getColDef().cellRenderer === 'custom') {
        // search가 가능한 custom column
        const childs = row[column.getColId()].props.children.filter(
          (child: any) => typeof child === 'string'
        ) as string[]

        return Object.assign(filteredRow, { [column.getColId()]: childs.join(' ') })
      }

      const convertValueCallbackFunc =
        column.getColDef().cellRendererParams?.convertValueCallbackFunc

      const rowValue = convertValueCallbackFunc
        ? convertValueCallbackFunc(row[column.getColId()])
        : row[column.getColId()]

      return Object.assign(filteredRow, { [column.getColId()]: rowValue })
    }, {})
  )
}

// keyword에 해당하는 row data만 추출
export function getFilteredRowDataByKeywords<T>(
  tableRowData: T[],
  columnApi: ColumnApi,
  searchKeywords: string[]
): T[] {
  // 검색어가 없는 경우 전체 row data 반환
  if (isEmpty(searchKeywords)) {
    return tableRowData
  }

  // keyword로 검색해서 해당하는 index array 반환
  const indexArrays = searchJsonObjectIndexArrayByKeywords(
    // 보여지는 column에 대한 row data만 추출
    filterRowDataByVisibleColumns(tableRowData, columnApi),
    searchKeywords
  )

  // index array에 해당하는 row data 반환
  return indexArrays.map((index) => tableRowData[index])
}

export function applyGridDomLayout(
  gridApi: GridApi,
  tableMaxHeight: number,
  setAutoHeight: (autoHeight: boolean) => void,
  heightLayout: CCKTableHeightLayoutType
): void {
  if (heightLayout === 'FIX') {
    gridApi.setDomLayout('normal')
    return
  }

  if (heightLayout === 'AUTO') {
    gridApi.setDomLayout('autoHeight')
    return
  }

  // grid max height overflow 여부에 따라 domLayout을 변경
  if (isGridMaxHeightOverflow(gridApi.getDisplayedRowCount(), tableMaxHeight, gridApi)) {
    // 지정된 height를 유지
    gridApi.setDomLayout('normal')
    // wrapper의 height를 100%로 변경
    setAutoHeight(false)
  } else {
    // 자동으로 height를 조절
    gridApi.setDomLayout('autoHeight')
  }
}

export function getAllRowDatas(gridApi: GridApi): { rowIndex: number; data: any }[] {
  const rowDatas: { rowIndex: number; data: any }[] = []
  gridApi.forEachNode((node, index) =>
    rowDatas.push({
      rowIndex: index,
      data: node.data
    })
  )

  return rowDatas
}

type CellFlashType = 'success' | 'error' | 'warning' | 'info'

type CellFlashParams = {
  api: GridApi
  rowNodes: RowNode[]
  columns?: Column[]
  flashType?: CellFlashType
  flashDelay?: number
  fadeDelay?: number
  targetElement?: HTMLElement
}

export function showFlashCellsSimple(flashParams: CellFlashParams): void {
  flashParams.api.flashCells({
    rowNodes: flashParams.rowNodes,
    columns: flashParams.columns,
    flashDelay: flashParams.flashDelay || 1000,
    fadeDelay: flashParams.fadeDelay || 500
  })
}

export function showFlashCells(flashParams: CellFlashParams): void {
  const targetElements =
    [flashParams.targetElement?.closest('.cck-table-frame')] ||
    document.querySelectorAll('.cck-table-frame')

  const cellFlashClassName = `cck-cell-flash-${flashParams.flashType ?? 'success'}`

  targetElements.forEach((targetElement) => {
    targetElement?.classList.add(cellFlashClassName)
  })

  showFlashCellsSimple(flashParams)

  // flash가 끝난 후에 후처리를 수행
  setTimeout(() => {
    targetElements.forEach((targetElement) => {
      targetElement?.classList.remove(cellFlashClassName)
    })
  }, flashParams.flashDelay || 1000)
}

export const pasteDataWithNewRow = (params: ProcessDataFromClipboardParams, gridApi?: GridApi) => {
  const adjustedData = removeEmptyStringArrays(params.data)
  if (!gridApi || gridApi === null) {
    return adjustedData
  }

  const pasteRows = adjustedData.length
  const currentRowIndex = gridApi.getFocusedCell()?.rowIndex || 0
  const rowCount = gridApi.getDisplayedRowCount() || 0
  const missingRowCount = currentRowIndex + pasteRows - rowCount
  if (missingRowCount) {
    for (let i = 0; i < missingRowCount; i += 1) {
      const row = _.cloneDeep({ Index: 0 })
      row.Index = gridApi.getDisplayedRowCount() + 1
      gridApi.applyTransaction({ add: [row] })
    }
  }

  return adjustedData
}

export type RefinedLabelType = 'NONE' | 'PRICE' | 'NUMBER' | 'BUSINESS_NUMBER' | 'PHONE_NUMBER'

export const convertToPhoneNumber = (input: string): string => {
  if (input.length < 4) {
    return input
  }
  if (input.length < 8) {
    return input.slice(0, 3) + '-' + input.slice(3)
  }
  return input.slice(0, 3) + '-' + input.slice(3, 7) + '-' + input.slice(7, 11)
}

export const refinedLabelValue = (input: string, type: RefinedLabelType): string => {
  if (input === undefined || input === null) {
    return ''
  }

  switch (type) {
    case 'PRICE': {
      // 숫자, .만 입력 가능하도록 보정
      const numberValue = newNumberWithCommas(input.toString().replace(/[^0-9.]/g, ''))
      return numberValue !== 'NaN' ? numberValue : ''
    }
    case 'NUMBER':
      return input.replace(/[^0-9]/g, '')
    case 'BUSINESS_NUMBER': {
      if (input === '해당 없음') {
        return input
      }
      const numberValue = convertToBusinessNumber(input.toString().replace(/[^0-9]/g, ''))
      return numberValue !== 'NaN' ? numberValue : ''
    }
    case 'PHONE_NUMBER': {
      const numberValue = convertToPhoneNumber(input.toString().replace(/[^0-9]/g, ''))
      return numberValue !== 'NaN' ? numberValue : ''
    }
    default:
      return input
  }
}

export const getDefaultLabelAlign = (type: RefinedLabelType): LabelTextAlign => {
  if (type === 'PRICE') {
    return LabelTextAlign.RIGHT
  }

  return LabelTextAlign.LEFT
}

export const manualRowNodeSort = (
  inputDatas: any[],
  currentSortModel: {
    colId: string | undefined
    sort: string | null | undefined
  }[]
): any[] => {
  const result = [...inputDatas]

  // 정렬 로직 (예시: 단일 열 정렬)
  if (currentSortModel.length && currentSortModel[0].colId && currentSortModel[0].sort) {
    const sortColId = currentSortModel[0].colId
    const sortDirection = currentSortModel[0].sort

    result.sort((a, b) => {
      const valA = a[sortColId]
      const valB = b[sortColId]

      if (valA < valB) {
        return sortDirection === 'asc' ? -1 : 1
      }
      if (valA > valB) {
        return sortDirection === 'asc' ? 1 : -1
      }
      return 0
    })
  }

  return result
}
