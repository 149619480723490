import React from 'react'
import './dropdown.scss'

export interface DropdownMenuItemComponentProps {
  key: string
  value?: string
  customNode?: React.ReactNode
  handleItemClickCallbackFunc?: () => void
}

const DropdownMenuItemComponent: React.FC<DropdownMenuItemComponentProps> = ({
  key,
  value,
  customNode,
  handleItemClickCallbackFunc
}) => {
  const handleItemClick = () => {
    handleItemClickCallbackFunc && handleItemClickCallbackFunc()
  }

  return (
    <div className={`drop-down-menu-item${customNode ? '-custom' : ''}`} onClick={handleItemClick}>
      {customNode || value || ''}
    </div>
  )
}

export default DropdownMenuItemComponent
