import {
  ButtonColor,
  ButtonComponent,
  ButtonSize,
  ButtonState,
  IconComponent,
  IconType
} from '@cck/common'
import Markdown from 'markdown-to-jsx'
import React from 'react'

import ChatMessageAnswerRefPointComponent from 'components/chat/message/ChatMessageAnswerRefPointComponent'
import { ChatReference } from 'components/chat/message/ChatMessageReferenceComponent'

export interface ChatAnswer {
  answer: string
}

interface ChatMessageAnswerComponentProps {
  answer: ChatAnswer
  isDone: boolean
  references: ChatReference[]
  setSelectReferences: (references: ChatReference[]) => void
}

const ChatMessageAnswerComponent: React.FC<ChatMessageAnswerComponentProps> = ({
  answer,
  isDone,
  references,
  setSelectReferences
}) => {
  return (
    <div className="chat-message-answer-wrapper">
      <div className="chat-message-answer-title-wrapper">
        <IconComponent iconType={IconType.ANSWER} size={24} />
        답변
      </div>
      <div className="chat-message-answer-content">
        <Markdown
          options={{
            overrides: {
              ChatMessageAnswerRefPointComponent: {
                component: ChatMessageAnswerRefPointComponent,
                props: {
                  references,
                  setSelectReferences
                }
              }
            }
          }}
        >
          {answer.answer}
        </Markdown>
      </div>
      {isDone && (
        <div className="chat-message-answer-option-wrapper">
          <div>
            <ButtonComponent
              color={ButtonColor.NO_BORDER}
              leftIcon={<IconComponent iconType={IconType.RELOAD} size={14} />}
              size={ButtonSize.SMALL}
              states={ButtonState.NORMAL}
              text="답변 재생성"
            />
          </div>
          <div>
            <ButtonComponent
              color={ButtonColor.NO_BORDER}
              leftIcon={<IconComponent iconType={IconType.FILE_COPY} size={14} />}
              size={ButtonSize.SMALL}
              states={ButtonState.NORMAL}
              text="복사"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatMessageAnswerComponent
