import { Column, RowNode } from 'ag-grid-community'
import { isEmpty } from 'lodash'
import React, { useRef } from 'react'

import ButtonComponent, { ButtonComponentProps } from 'components/button/ButtonComponent'
import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'
import { IconType } from 'components/icon/IconBaseType'
import IconComponent from 'components/icon/IconComponent'

interface UploadButtonAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  buttonComponentProps: ButtonComponentProps
  value: string
  buttonClickCallbackFunc?: (node: RowNode, column: Column) => void
  fileUploadCallbackFunc?: (node: RowNode, file: File) => void
  cancelUploadCallbackFunc?: (node: RowNode) => void
  maxCharLength?: number
  checkFileTypeCallbackFunc?: (file: File) => Promise<boolean>
  accept?: string
}

const UploadButtonAgGridCellRendererComponent: React.FC<
  UploadButtonAgGridCellRendererComponentProps
> = ({
  buttonComponentProps,
  buttonClickCallbackFunc,
  value,
  node,
  column,
  fileUploadCallbackFunc,
  cancelUploadCallbackFunc,
  maxCharLength,
  checkFileTypeCallbackFunc,
  accept
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }
    let check = true
    if (checkFileTypeCallbackFunc) {
      check = await checkFileTypeCallbackFunc(file)
    }
    if (check === false) {
      return
    }
    fileUploadCallbackFunc && fileUploadCallbackFunc(node, file)
  }

  const handleClick = () => {
    inputRef.current?.click()
  }

  const handleMouseDown = () => {
    handleClick()
  }

  const handleCancelIcon = () => {
    if (cancelUploadCallbackFunc) {
      cancelUploadCallbackFunc(node)
      handleClick()
    }
  }

  const renderInner = () => {
    if (!isEmpty(value)) {
      const shortenFileName = () => {
        if (maxCharLength === undefined || value.length <= maxCharLength) {
          return value
        }
        const lastDotIndex = value.lastIndexOf('.')
        const fileNameWithoutExtension = value.substring(0, lastDotIndex)
        const shortenedFileName = fileNameWithoutExtension.substring(0, maxCharLength - 3)
        return `${shortenedFileName}..${value.substring(lastDotIndex)}`
      }
      const getIcon = () => {
        if (cancelUploadCallbackFunc) {
          return (
            <IconComponent
              clickCallbackFunc={handleCancelIcon}
              enable="disable"
              iconType={IconType.CANCEL}
            />
          )
        }
        return (
          <IconComponent
            isSvg
            clickCallbackFunc={handleClick}
            enable="disable"
            iconType={IconType.REPLACE}
          />
        )
      }
      return (
        <div className="cck-renderer-common cck-table-upload-label">
          {shortenFileName()}
          {getIcon()}
        </div>
      )
    }
    return (
      <div className="cck-table-button-cell-frame">
        <div className="cck-table-button-cell-single">
          <ButtonComponent {...buttonComponentProps} clickCallbackFunc={handleMouseDown} />
        </div>
      </div>
    )
  }
  return (
    <div className="cck-table-upload">
      {renderInner()}
      <input
        accept={accept}
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={handleFileUpload}
      />
    </div>
  )
}

export default UploadButtonAgGridCellRendererComponent
