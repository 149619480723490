import { IconComponent, IconType } from '@cck/common'
import _ from 'lodash'
import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  searchStandardCountMapAtom,
  searchStandardColorMapAtom,
  SEARCH_CONTENT_KEYWORD_COLOR_LIST,
  maxSearchCountAlertAtom
} from 'atoms/search/Search'

type NewSearchKeywordItemComponentProps = {
  keyword: string
  renderItemBySpan?: boolean
}

const NewSearchKeywordItemComponent: React.FC<NewSearchKeywordItemComponentProps> = ({
  keyword,
  renderItemBySpan = false
}) => {
  const [searchStandardCountMap, setSearchStandardCountMap] = useRecoilState(
    searchStandardCountMapAtom
  )
  const [searchStandardColorMap, setSearchStandardColorMap] = useRecoilState(
    searchStandardColorMapAtom
  )
  const setMaxSearchCountAlert = useSetRecoilState(maxSearchCountAlertAtom)

  const handleDeleteItem = () => {
    const newCountMap = _.cloneDeep(searchStandardCountMap)
    const newColorMap = _.cloneDeep(searchStandardColorMap)
    newCountMap.delete(keyword)
    newColorMap.delete(keyword)
    setSearchStandardCountMap(newCountMap)
    setSearchStandardColorMap(newColorMap)

    setMaxSearchCountAlert(false)
  }

  const colorIndex = searchStandardColorMap.get(keyword)
  let backgroundColor = '#FFFFFF'
  if (colorIndex !== undefined && typeof colorIndex === 'number') {
    backgroundColor = SEARCH_CONTENT_KEYWORD_COLOR_LIST[colorIndex]
  }

  if (renderItemBySpan) {
    return (
      <span
        className="main-view-dropdown-header-search-history-item-span"
        style={{ backgroundColor }}
      >
        {keyword}
        <IconComponent clickCallbackFunc={handleDeleteItem} iconType={IconType.DELETE} size={10} />
      </span>
    )
  }

  return (
    <div className="main-view-dropdown-header-search-history-item" style={{ backgroundColor }}>
      {keyword}
      <IconComponent clickCallbackFunc={handleDeleteItem} iconType={IconType.DELETE} size={10} />
    </div>
  )
}

export default NewSearchKeywordItemComponent
