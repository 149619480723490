import { isEmpty, notEmpty } from '@cck/common'
import { Layout } from 'antd'
import React, { useEffect, useState } from 'react'

import ChatContentViewComponent from 'components/chat/ChatContentViewComponent'
import useChat from 'components/chat/hook/useChat'
import { ChatReference } from 'components/chat/message/ChatMessageReferenceComponent'
import { ChatMessage } from 'components/chat/message/ChatMessageWrapperComponent'
import ChatContentRightSiderViewComponent from 'components/chat/sider/ChatContentRightSiderViewComponent'

const ChatContentWrapperViewComponent: React.FC = () => {
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([])
  const [recentChat, setRecentChat] = useState<ChatMessage | undefined>(undefined)
  const [selectedReferences, setSelectedReferences] = useState<ChatReference[]>([])

  const { answer, isAnswerDone, sourceNodes, relatedQuestions, askQuestion } = useChat()

  useEffect(() => {
    if (!recentChat) {
      return
    }

    setRecentChat((prev) => {
      if (prev) {
        if (isEmpty(prev.references) && notEmpty(sourceNodes)) {
          // source가 update되는 시점

          const refs: ChatReference[] = []
          sourceNodes.forEach((source, idx) => {
            refs.push({
              id: source.node_id,
              index: idx + 1,
              score: source.score.toString(),
              text: source.text,
              metadata: source.metadata
            })
          })

          return {
            ...prev,
            references: refs
          }
        }

        if (isAnswerDone) {
          const extractIdPattern = /\[참고 id: ([^\]]+)]/g

          let lastAnswer = answer
          Array.from(answer.matchAll(extractIdPattern)).forEach((match) => {
            if (
              prev.references.some((ref) => {
                if (
                  Object.values(ref.metadata).some((metaDataValue) => metaDataValue === match[1])
                ) {
                  lastAnswer = lastAnswer.replaceAll(
                    match[0],
                    // eslint-disable-next-line max-len
                    `<ChatMessageAnswerRefPointComponent index={${ref.index}}/>`
                  )

                  return true
                }
                return false
              })
            ) {
              return
            }
            lastAnswer = lastAnswer.replaceAll(match[0], '')
          })

          return {
            ...prev,
            isDone: isAnswerDone,
            answer: {
              answer: lastAnswer
            },
            relatedQuestions
          }
        }

        return {
          ...prev,
          isDone: isAnswerDone,
          answer: {
            answer
          }
        }
      }

      return prev
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, isAnswerDone, sourceNodes, relatedQuestions])

  const handleSubmitQuestion = (question: string) => {
    if (recentChat) {
      setChatHistory((prev) => [...prev, recentChat])
    }

    setRecentChat({
      question: {
        question
      },
      references: [],
      answer: {
        answer: ''
      },
      isDone: false,
      relatedQuestions: []
    })

    askQuestion(question)
  }

  return (
    <Layout>
      <ChatContentViewComponent
        chatHistory={chatHistory}
        recentChat={recentChat}
        setSelectReferences={setSelectedReferences}
        submitQuestion={handleSubmitQuestion}
      />
      {notEmpty(selectedReferences) && (
        <ChatContentRightSiderViewComponent
          clearReferences={() => {
            setSelectedReferences([])
          }}
          references={selectedReferences}
        />
      )}
    </Layout>
  )
}

export default ChatContentWrapperViewComponent
