/* eslint-disable no-unused-vars */
import { Tooltip, type TooltipProps } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'

import './button.scss'

export enum ButtonState {
  DISABLED = 'disabled',
  PRESSED = 'pressed',
  NORMAL = 'normal'
}

export enum ButtonSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  XSMALL = 'xsmall'
}

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINE = 'outline',
  WARNING = 'warning',
  GRAY = 'gray',
  BLACK = 'black',
  GREEN = 'green',
  SKYBLUE = 'skyblue',
  MARINE = 'marine',
  NO_BORDER = 'no-border'
}

export interface ButtonComponentProps {
  text: string
  states: ButtonState
  size: ButtonSize
  color: ButtonColor
  shadow?: boolean // default false
  clickCallbackFunc?: () => void
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  tooltip?: string | React.ReactNode
  tooltipProps?: TooltipProps
  timer?: boolean
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  text,
  states,
  size,
  color,
  clickCallbackFunc,
  leftIcon,
  rightIcon,
  shadow = false,
  tooltip,
  tooltipProps = {},
  timer = true
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const defaultTooltipProps: TooltipProps = {
    placement: 'bottom',
    trigger: 'hover'
  }
  const handleClick = () => {
    !isDisabled && clickCallbackFunc && clickCallbackFunc()
    if (timer && isDisabled === false) {
      setIsDisabled(true)

      setTimeout(() => {
        setIsDisabled(false)
      }, 500)
      // 0.5초 후에 버튼을 다시 활성화
    }
  }
  return (
    <Tooltip
      overlayInnerStyle={{ width: 'max-content' }}
      title={tooltip}
      {...defaultTooltipProps}
      {...tooltipProps}
    >
      <div
        className={classNames(`button-frame-${size}`, `button-color-${color}-${states}`, {
          'button-shadow-frame': shadow,
          'button-click-animation': isDisabled && states !== ButtonState.DISABLED && timer
        })}
        onClick={handleClick}
      >
        {leftIcon}
        <div>{text}</div>
        {rightIcon}
      </div>
    </Tooltip>
  )
}

export default ButtonComponent
