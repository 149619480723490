import { isEmpty } from '@cck/common'
import _ from 'lodash'

import { Index } from 'services/standard_client'

function isSubsetId(a: string, b: string): boolean {
  const aIds = a.split('.')
  const bIds = b.split('.')
  for (let i = 0; i < aIds.length && i < bIds.length; ++i) {
    if (!_.isEqual(aIds[i], bIds[i])) {
      return false
    }
  }
  return true
}

export function findIndexItemByIds(indexItemList: Index[], id: string): Index | undefined {
  if (isEmpty(indexItemList)) {
    return undefined
  }

  const found = indexItemList.find((item) => isSubsetId(id, item.id))
  if (!found) {
    return undefined
  }

  if (found.id === id) {
    return found
  }

  return findIndexItemByIds(found.children, id)
}

export const findFirstChildIndexItem = (indexItem: Index) => {
  if (isEmpty(indexItem.children)) {
    return indexItem
  }

  return indexItem.children[0]
}
