import { Column, RowNode } from 'ag-grid-community'
import { Dropdown } from 'antd'
import { isEmpty } from 'lodash'
import React from 'react'

import { AntdDropdownItemType } from 'components/ccktable/column/ColumnDefTypes'
import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'
import { IconType } from 'components/icon/IconBaseType'
import IconComponent from 'components/icon/IconComponent'
import LabelComponent from 'components/label/LabelComponent'

interface AntdDropdownAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  value: string
  items?: AntdDropdownItemType[]
  placeholder?: string
  // convertKeyValueToNodeCallbackFunc?: (value: string) => React.ReactNode
  isDisableDropdownCallbackFunc?: (node: RowNode, column: Column) => boolean
  getDropdownItemsCallbackFunc?: (node: RowNode, column: Column) => AntdDropdownItemType[]
}

const AntdDropdownAgGridCellRendererComponent: React.FC<
  AntdDropdownAgGridCellRendererComponentProps
> = ({
  node,
  column,
  value,
  placeholder,
  items,
  isDisableDropdownCallbackFunc,
  // convertKeyValueToNodeCallbackFunc,
  getDropdownItemsCallbackFunc,
  eGridCell
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(value)

  const isDisableDropdown = () => {
    if (isDisableDropdownCallbackFunc) {
      return isDisableDropdownCallbackFunc(node, column)
    }
    return false
  }

  const dropdownItemProps = getDropdownItemsCallbackFunc
    ? getDropdownItemsCallbackFunc(node, column)
    : items ?? []

  const getDropdownMenuItems = () => {
    return dropdownItemProps.map((item) => {
      return {
        ...item,
        onClick: () => {
          setSelectedKey(item.key)
          // value 변경
          node.setDataValue(column.getColId(), item.key)
          // callback func 호출(외부 동작이 필요한 경우)
          item.onClickCallbackFunc && item.onClickCallbackFunc(node, column, item.key)
        }
      }
    })
  }

  const makeValueNode = () => {
    const showPlaceHolder = isEmpty(value) && placeholder
    return (
      <>
        {dropdownItemProps.find((item) => item.key === selectedKey)?.label ?? (
          <LabelComponent
            classNames={showPlaceHolder ? 'cck-table-label-placeholder' : ''}
            value={showPlaceHolder ? placeholder : value}
          />
        )}
        <div className="cck-table-dropdown-icon">
          <IconComponent enable="disable" iconType={IconType.DROPDOWN_BUTTON} size={20} />
        </div>
      </>
    )
  }

  return (
    <Dropdown
      disabled={isDisableDropdown() || isEmpty(dropdownItemProps)}
      key={selectedKey}
      menu={{ items: getDropdownMenuItems(), style: { maxHeight: '400px', overflowY: 'auto' } }}
      placement="bottom"
      trigger={['click']}
    >
      <div
        className="cck-renderer-common"
        style={{ width: eGridCell.clientWidth, minWidth: '110px' }}
      >
        {makeValueNode()}
      </div>
    </Dropdown>
  )
}

export default AntdDropdownAgGridCellRendererComponent
