import { IconComponent, IconType } from '@cck/common'
import React from 'react'

export interface ChatQuestion {
  question: string
}

interface ChatMessageQuestionComponentProps {
  question: ChatQuestion
}

const ChatMessageQuestionComponent: React.FC<ChatMessageQuestionComponentProps> = ({
  question
}) => {
  return (
    <div className="chat-message-question-wrapper">
      {question.question}
      <IconComponent iconType={IconType.FOLDER_FILE_ALT} size={24} />
    </div>
  )
}

export default ChatMessageQuestionComponent
