import { selector } from 'recoil'

import { IndexReply } from 'services/standard_client'

async function loadJsonFile(name: string): Promise<IndexReply> {
  const url = `${process.env.PUBLIC_URL}/standard_index/${name}.json`
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  const data = await response.json()
  return data as IndexReply
}

async function getDummyStandardIndex(): Promise<Map<string, IndexReply>> {
  return new Map<string, IndexReply>([
    ['ASNPO', await loadJsonFile('asnpo')],
    ['AUDSTD', await loadJsonFile('audstd')],
    ['CEWSTD', await loadJsonFile('cewstd')],
    ['KIFRS', await loadJsonFile('kifrs')]
  ])
}

export const dummyStandardIndexSelector = selector<Map<string, IndexReply>>({
  key: 'dummyStandardIndexSelector',
  get: async () => {
    return await getDummyStandardIndex()
  }
})
