import React, { ReactNode, useEffect } from 'react'

import PortalComponent from 'components/portal/PortalComponent'

import './dropdown.scss'

type DropDownMenuComponentProps = {
  children?: ReactNode
  wrapperClassName: string
  parentElement: HTMLElement
  handleOutsideClickCallbackFunc?: () => void
  menuWidth?: number
}

const DropdownMenuComponent: React.FC<DropDownMenuComponentProps> = ({
  children,
  parentElement,
  handleOutsideClickCallbackFunc,
  wrapperClassName,
  menuWidth
}) => {
  const rect = parentElement?.getBoundingClientRect()
  const top = rect.y + rect.height
  const maxHeight = window.innerHeight - top - 10

  const handleOutsideClick = (e: MouseEvent) => {
    const targetElement = e.target as HTMLElement

    const wrapperElement =
      wrapperClassName.length > 0 ? targetElement.closest(`.${wrapperClassName}`) : undefined
    if (wrapperElement === parentElement) {
      return
    }
    handleOutsideClickCallbackFunc && handleOutsideClickCallbackFunc()
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    if (handleOutsideClickCallbackFunc) {
      window.addEventListener('resize', handleOutsideClickCallbackFunc)
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      if (handleOutsideClickCallbackFunc) {
        window.removeEventListener('resize', handleOutsideClickCallbackFunc)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PortalComponent>
      <div
        className="drop-down-menu-frame"
        style={{
          position: 'absolute',
          left: rect.x,
          top,
          width: menuWidth !== undefined ? menuWidth : rect.width,
          maxHeight
        }}
      >
        {children}
      </div>
    </PortalComponent>
  )
}

export default DropdownMenuComponent
