import { IconComponent, IconType, isEmpty } from '@cck/common'
import { Col, Row } from 'antd'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import {
  getIndexItemSelector,
  selectedIndexItemAtom,
  selectedOneDepthIndexItemAtom
} from 'atoms/index/Index'
import { searchStandardCountMapAtom } from 'atoms/search/Search'
import { selectedStandardIdAtom, standardsSelector } from 'atoms/standard/Standard'
import { SearchKeywordCountItemAreaType } from 'components/new_search/NewSearchKeywordCountItemComponent'
import NewSearchKeywordCountListComponent from 'components/new_search/NewSearchKeywordCountListComponent'
import { findFirstChildIndexItem } from 'components/util/IndexItemUtil'
import { Index } from 'services/standard_client'
import { getIndexItemIdString } from 'utils/standard_utils'

type NewStandardMenuViewComponentProps = {
  selectItemCallbackFunc: () => void
  isMainView?: boolean
}

const NewStandardMenuViewComponent: React.FC<NewStandardMenuViewComponentProps> = ({
  selectItemCallbackFunc,
  isMainView = false
}) => {
  const navigate = useNavigate()

  const standards = useRecoilValue(standardsSelector)
  const setSelectedStandardId = useSetRecoilState(selectedStandardIdAtom)
  const setSelectedOneDepthIndexItem = useSetRecoilState(selectedOneDepthIndexItemAtom)
  const setSelectedIndexItem = useSetRecoilState(selectedIndexItemAtom)
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)

  const [menuSelectedStandardName, setMenuSelectedStandardName] = useState<string | undefined>(
    undefined
  )
  const [menuSelectedStandardId, setMenuSelectedStandardId] = useState<string | undefined>(
    undefined
  )

  const indexItems = useRecoilValue(getIndexItemSelector(menuSelectedStandardId || ''))

  const sortItemsVerticallyToHorizontally = (items: Index[], numColumns: number): Index[] => {
    const numRows = Math.ceil(items.length / numColumns)
    const sortedItems: Index[] = new Array(items.length)

    items.forEach((item, index) => {
      const targetRow = index % numRows
      const targetColumn = Math.floor(index / numRows)
      const newIndex = targetRow * numColumns + targetColumn
      sortedItems[newIndex] = item
    })

    return sortedItems.filter((item) => item !== undefined)
  }

  const menuContents = useMemo(() => {
    if (!menuSelectedStandardName || !menuSelectedStandardId) {
      return _.map(standards, (standard) => {
        return (
          <div
            className={isMainView ? 'new-standard-menu-item-main-view' : 'new-standard-menu-item'}
            onClick={() => {
              setMenuSelectedStandardId(standard.id)
              setMenuSelectedStandardName(standard.name)
              // navigate(`/standard/${standard.id}`)
            }}
          >
            {standard.name}
            <div className="new-standard-menu-item-search-count-frame">
              <NewSearchKeywordCountListComponent
                indexItemId={standard.id}
                itemArea={SearchKeywordCountItemAreaType.DROPDOWN_STANDARD}
              />
            </div>
          </div>
        )
      })
    }

    if (isEmpty(indexItems)) {
      return <div>Index is empty</div>
    }

    const sortedIndexList = sortItemsVerticallyToHorizontally(indexItems.indexList, 4)

    return (
      <Row gutter={[16, 4]}>
        {_.map(sortedIndexList, (indexItem) => {
          return (
            <Col span={6}>
              <div
                className="new-standard-submenu-item"
                onClick={() => {
                  setSelectedOneDepthIndexItem(indexItem)
                  setSelectedStandardId(menuSelectedStandardId)
                  const firstChildIndexItem = findFirstChildIndexItem(indexItem)
                  setSelectedIndexItem(firstChildIndexItem)
                  navigate(
                    [
                      '',
                      'standard',
                      menuSelectedStandardId,
                      indexItem.id,
                      firstChildIndexItem.id
                    ].join('/')
                  )

                  selectItemCallbackFunc()
                }}
              >
                <div className="new-standard-submenu-item-id">
                  {getIndexItemIdString(indexItem)}
                </div>
                <div className="new-standard-submenu-item-name">{indexItem.name}</div>
                <div className="new-standard-submenu-item-search-count-frame">
                  <NewSearchKeywordCountListComponent
                    childrenOverlap
                    indexItemId={indexItem.id}
                    itemArea={SearchKeywordCountItemAreaType.DROPDOWN_ONE_DEPTH}
                  />
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexItems, standards, menuSelectedStandardName, searchStandardCountMap])

  const menuHeader = useMemo(() => {
    if (!menuSelectedStandardName) {
      return (
        <>
          <div className="new-standard-menu-view-header-title">기준서</div>
          <div className="new-standard-menu-view-header-guide-text">
            아래 목록에서 열람할 기준서를 선택해주세요.
          </div>
        </>
      )
    }

    return (
      <>
        <IconComponent
          clickCallbackFunc={() => {
            setMenuSelectedStandardName(undefined)
            setMenuSelectedStandardId(undefined)
          }}
          iconType={IconType.CHEVRON_LEFT}
        />
        <div className="new-standard-menu-view-header-title">{menuSelectedStandardName}</div>
        {/* <div>기준서</div> */}
      </>
    )
  }, [menuSelectedStandardName])

  return (
    <div className="new-standard-menu-view-frame">
      <div className="new-standard-menu-view-header-frame">{menuHeader}</div>
      <div className="new-standard-menu-view-body-frame">{menuContents}</div>
    </div>
  )
}

export default NewStandardMenuViewComponent
