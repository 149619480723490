import {
  ButtonColor,
  ButtonComponent,
  ButtonSize,
  ButtonState,
  IconComponent,
  IconType,
  ScrollBarComponent
} from '@cck/common'
import Sider from 'antd/es/layout/Sider'
import React from 'react'

import { ChatReference } from 'components/chat/message/ChatMessageReferenceComponent'

import ChatContentReferenceItemComponent from './ChatContentReferenceItemComponent'

interface ChatContentRightSiderViewComponent {
  references: ChatReference[]
  clearReferences: () => void
}

const ChatContentRightSiderViewComponent: React.FC<ChatContentRightSiderViewComponent> = ({
  references,
  clearReferences
}) => {
  const makeReference = () => {
    return (
      <div className="chat-content-sider-reference-list">
        <div className="chat-content-sider-reference-option-wrapper">
          <div>
            <ButtonComponent
              clickCallbackFunc={clearReferences}
              color={ButtonColor.SECONDARY}
              rightIcon={<IconComponent iconType={IconType.CLOSE} size={18} />}
              size={ButtonSize.SMALL}
              states={ButtonState.NORMAL}
              text="닫기"
            />
          </div>
        </div>
        {references.map((refItem) => {
          return <ChatContentReferenceItemComponent key={refItem.id} reference={refItem} />
        })}
      </div>
    )
  }

  return (
    <Sider
      collapsedWidth={120}
      // collapsed={isCollapsed}
      theme="light"
      trigger={null}
      width={480}
    >
      <div className="chat-content-wrapper-view-sider">
        <ScrollBarComponent autoHideEnable>{makeReference()}</ScrollBarComponent>
      </div>
    </Sider>
  )
}

export default ChatContentRightSiderViewComponent
