import { useEffect } from 'react'

type Hook = (ref: React.RefObject<HTMLElement>, trapFocus: boolean) => void

const useTrapTabFocus: Hook = (ref: React.RefObject<HTMLElement>, trapFocus: boolean) => {
  useEffect(() => {
    const handleFocusIn = (e: Event) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        e.preventDefault()

        // Find the first focusable child element
        const focusableElements = ref.current.querySelectorAll(
          'a[href], area[href], input:not([disabled]),' +
            'select:not([disabled]), textarea:not([disabled]),' +
            'button:not([disabled]), iframe, object, embed,' +
            '[tabindex]:not([tabindex="-1"]), [contenteditable]'
        )
        const firstFocusableElement = focusableElements[0] as HTMLElement

        if (firstFocusableElement) {
          firstFocusableElement.focus()
        } else {
          ref.current.tabIndex = 0
          ref.current.focus()
        }
      }
    }

    trapFocus && document.addEventListener('focusin', handleFocusIn)

    return () => {
      trapFocus && document.removeEventListener('focusin', handleFocusIn)
    }
  }, [ref, trapFocus])
}

export default useTrapTabFocus
