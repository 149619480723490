import React from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import 'antd/dist/reset.css'
import 'index.css'

import NewApp from 'NewApp'

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <RecoilRoot>
    <NewApp />
  </RecoilRoot>
)
