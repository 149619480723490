import { ScrollBarComponent, isEmpty, type ScrollBarRefType } from '@cck/common'
import { Content } from 'antd/es/layout/layout'
import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'

import ChatInputComponent from 'components/chat/ChatInputComponent'
import { ChatReference } from 'components/chat/message/ChatMessageReferenceComponent'
import ChatMessageWrapperComponent, {
  ChatMessage
} from 'components/chat/message/ChatMessageWrapperComponent'

interface ChatContentViewComponentProps {
  chatHistory: ChatMessage[]
  recentChat?: ChatMessage
  submitQuestion: (question: string) => void
  setSelectReferences: (references: ChatReference[]) => void
}

const ChatContentViewComponent: React.FC<ChatContentViewComponentProps> = ({
  chatHistory,
  recentChat,
  submitQuestion,
  setSelectReferences
}) => {
  const scrollRef = React.useRef<ScrollBarRefType>(null)
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom()
    }
  }, [recentChat])

  const makeTempKey = (question: string, index?: number) => {
    return `${question}_${index !== undefined ? index : 'recentChat'}`
  }

  const recentChatMessageWrapper = useMemo(() => {
    if (!recentChat) {
      return null
    }
    return (
      <ChatMessageWrapperComponent
        isLastMessage
        chatMessage={recentChat}
        key={makeTempKey(recentChat.question.question)}
        setSelectReferences={setSelectReferences}
        submitRequestQuestion={submitQuestion}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentChat])

  const content = () => {
    if (isEmpty(chatHistory) && !recentChat) {
      return (
        <div className="chat-main-view-content-empty-header">
          <span className="chat-main-view-content-empty-header-1">ACCIO</span>{' '}
          <span className="chat-main-view-content-empty-header-2">Standard</span>{' '}
          <span className="chat-main-view-content-empty-header-3">for</span>{' '}
          <span className="chat-main-view-content-empty-header-4">CPA</span>
        </div>
      )
    }

    return (
      <div className="chat-main-view-content-scroll-frame">
        <ScrollBarComponent autoHideEnable scrollBarRef={scrollRef}>
          {chatHistory.map((message, index) => {
            return (
              <ChatMessageWrapperComponent
                chatMessage={message}
                key={makeTempKey(message.question.question, index)}
                setSelectReferences={setSelectReferences}
                submitRequestQuestion={submitQuestion}
              />
            )
          })}
          {recentChatMessageWrapper}
        </ScrollBarComponent>
      </div>
    )
  }

  return (
    <Content
      className={classNames([
        { 'chat-main-view-content': true },
        { 'chat-main-view-content-empty': isEmpty(chatHistory) && !recentChat },
        { 'chat-main-view-content-notEmpty': !isEmpty(chatHistory) || recentChat }
      ])}
      style={{ backgroundColor: 'white' }}
    >
      {content()}
      <ChatInputComponent
        chatHistory={chatHistory}
        recentChat={recentChat}
        submitQuestion={submitQuestion}
      />
    </Content>
  )
}

export default ChatContentViewComponent
