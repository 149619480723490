import { Column, RowNode } from 'ag-grid-community'
import React from 'react'

import { IconBaseComponentProps } from 'common/components/icon/IconBaseType'
import IconComponent from 'common/components/icon/IconComponent'
import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'

interface IconAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  buttonClickCallbackFunc?: (node: RowNode, column: Column) => VideoFacingModeEnum
  iconProp: IconBaseComponentProps
  iconPropsByValueCallbackFunc?: (
    node: RowNode,
    column: Column
  ) => IconBaseComponentProps | undefined
}

const IconAgGridCellRendererComponent: React.FC<IconAgGridCellRendererComponentProps> = ({
  buttonClickCallbackFunc,
  callbackIsContentVisible,
  iconPropsByValueCallbackFunc,
  node,
  column,
  iconProp,
  eGridCell
}) => {
  const getIsVisible = () => {
    if (callbackIsContentVisible) {
      return callbackIsContentVisible(node, column)
    }
    return true
  }
  const iconClickCallbackFunc = () => {
    buttonClickCallbackFunc && buttonClickCallbackFunc(node, column)
  }
  const getRefinedButtonProps = (): IconBaseComponentProps => {
    const newProp = iconPropsByValueCallbackFunc && iconPropsByValueCallbackFunc(node, column)
    iconProp.clickCallbackFunc = iconClickCallbackFunc
    if (!newProp) {
      // 기존 prop 반환
      return iconProp
    }
    return {
      ...iconProp,
      ...newProp,
      clickCallbackFunc: iconClickCallbackFunc
    }
  }

  return (
    <div className="cck-renderer-common cck-table-icon" style={{ width: eGridCell.clientWidth }}>
      {getIsVisible() && <IconComponent {...getRefinedButtonProps()} />}
    </div>
  )
}

export default IconAgGridCellRendererComponent
