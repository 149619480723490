import { selector, selectorFamily } from 'recoil'

import { selectedIndexItemAtom } from 'atoms/index/Index'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import { Content, getContent } from 'services/standard_client'

const getOneDepthItemsSelector = selectorFamily<
  Content[] | undefined,
  { standardId: string; id: string }
>({
  key: 'getOneDepthItemsSelector',
  get({ standardId, id }) {
    return async () => (await getContent(standardId, id)).contents
  }
})

function isContainingId(targetId: string, currentId: string): boolean {
  const targetIdArray = targetId.split('.')
  const currentIdArray = currentId.split('.')

  for (let i = 0; i < targetIdArray.length; i++) {
    if (targetIdArray[i] !== currentIdArray[i]) {
      return false
    }
  }

  return true
}

const getContentItemsSelector = selectorFamily<
  Content[] | undefined,
  { standardId: string; id: string }
>({
  key: 'getContentItemsSelector',
  get({ standardId, id }) {
    return async ({ get }) => {
      const oneDepthId = id.split('.')[0]
      const oneDepthItems = get(getOneDepthItemsSelector({ standardId, id: oneDepthId }))
      return oneDepthItems?.filter((item) => isContainingId(id, item.id))
    }
  }
})

export const contentItemsSelector = selector<Content[] | undefined>({
  key: 'contentItemsSelector',
  get: async ({ get }) => {
    const selectedStandardId = get(selectedStandardIdAtom)
    const selectedIndexItem = get(selectedIndexItemAtom)
    if (selectedIndexItem) {
      return get(
        getContentItemsSelector({ standardId: selectedStandardId, id: selectedIndexItem.id })
      )
    }
  }
})
