import { Column, GridApi, ICellEditorParams, RowNode } from 'ag-grid-community'
import React, { useState, useImperativeHandle, forwardRef } from 'react'

import {
  showFlashCellsSimple,
  getAllRowDatas,
  showFlashCells,
  checkValueIsUnique,
  RefinedLabelType,
  refinedLabelValue
} from 'components/ccktable/util/CCKTableUtil'
import NewInputControlComponent, { NewInputState } from 'components/input/NewInputControlComponent'

export interface CCKBasicAgGridCellEditorComponentProps extends ICellEditorParams {
  refinedValueType?: RefinedLabelType
  // unique value check를 수행할지 여부
  checkUniqueValueInColumn?: boolean
  // input 값 validation check를 위한 callback
  isValidateCheckCallbackFunc?: (
    input: string,
    node: RowNode,
    column: Column,
    api: GridApi
  ) => boolean
  // input 값이 최종적으로 Cell에 입력되기 전애 보정하기 위한 callback
  refinedInputValueCallbackFunc?: (input: string, node: RowNode, column: Column) => string
  // input 값이 변경될 때마다 호출되는 callback
  changeInputValueFilterCallbackFunc?: (input: string, node: RowNode, column: Column) => string
  useTextAreaInputEditor: boolean
  maxLength?: number
  moveNextAfterEdit?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CCKBasicAgGridCellEditorComponent = forwardRef<any, CCKBasicAgGridCellEditorComponentProps>(
  (props, ref) => {
    const {
      refinedValueType = 'NONE',
      value,
      node,
      column,
      api,
      eGridCell,
      checkUniqueValueInColumn,
      useTextAreaInputEditor = false,
      isValidateCheckCallbackFunc,
      refinedInputValueCallbackFunc,
      changeInputValueFilterCallbackFunc,
      maxLength,
      moveNextAfterEdit = true
    } = props

    // text input value
    const [inputValue, setInputValue] = useState(value)

    // text input valid state
    const [inputValidState, setInputValidState] = useState<NewInputState>('Default')

    const [inputSize, setInputSize] = useState({
      width: eGridCell.clientWidth,
      height: eGridCell.parentElement?.clientHeight || eGridCell.clientHeight
    })

    // ag-grid cell editor를 사용하기 위해선 useImperativeHandle을 사용해야 함 + forwardRef
    // 호출 순서
    // Edit 창이 열리면
    // (1) isCancelBeforeStart(true 반환 시 편집 취소) -> (2) afterGuiAttached
    // Enter 입력 시
    // (3) handleInputComplete callbackFunc 실행. 내부에서 api.stopEditing() 호출로 편집 종료 call
    // Edit 창이 닫히면
    // (4) isCancelAfterEnd(true 반환 시 편집 결과 무시) -> (5) getValue(반환된 수정 값을 Cell에 반영)
    useImperativeHandle(ref, () => {
      return {
        // 편집이 시작되기 전에 한번 호출됨. 편집을 취소할 수 있는 기회를 주기 위함
        // true 반환 시 편집 취소
        isCancelBeforeStart() {
          // eslint-disable-next-line no-console
          console.log('isCancelBeforeStart')
          return false
        },
        // 이 컴포넌트가 만들어져 Dom에 붙고 난 후 호출됨
        afterGuiAttached() {
          // eslint-disable-next-line no-console
          console.log('afterGuiAttached')
          // cell edit이 시작되면 실제 dom input에 focus를 줘 캐럿이 생기도록 함
          eGridCell.querySelector<HTMLInputElement>('.cck-input')?.focus()
          setInputSize({
            width: eGridCell.clientWidth,
            height: eGridCell.parentElement?.clientHeight || eGridCell.clientHeight
          })
        },

        // 편집이 완료되면 한번 호출됨(ex. Enter 입력 등). 편집 결과를 무시하고 싶을 때 사용
        // true 반환 시 편집 결과 무시
        isCancelAfterEnd() {
          // eslint-disable-next-line no-console
          console.log('isCancelAfterEnd')

          // 입력 값이 이전과 동일한 경우, 편집 취소
          if (inputValue === value) {
            return true
          }

          return !isValidateInput()
        },

        // Edit 최종 결과값을 반환
        getValue() {
          // eslint-disable-next-line no-console
          console.log('getValue')
          // 최종 입력값 보정

          let result = refinedInputValueCallbackFunc
            ? refinedInputValueCallbackFunc(inputValue, node, column)
            : inputValue

          result = refinedLabelValue(result, refinedValueType)

          setInputValue(result)

          showFlashCellsSimple({ api, rowNodes: [node] })
          return result
        }
      }
    })

    const handleInputChange = (input: string) => {
      // Valid State 초기화
      inputValidState !== 'Default' && setInputValidState('Default')

      let result = changeInputValueFilterCallbackFunc
        ? changeInputValueFilterCallbackFunc(input, node, column)
        : input

      result = refinedLabelValue(result, refinedValueType)

      // 입력값 변경 필터링
      setInputValue(result)
    }

    // Tab, Enter 입력 시 자동으로 입력이 종료되지 않도록 key down을 받고, unique 체크를 수행
    const handleOnKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (event.key !== 'Enter') {
        // 종료 관련된 key가 아니면 무시
        return
      }

      if (event.key === 'Enter' && event.shiftKey) {
        setInputValue((prev: string) => prev + '\n')
        event.preventDefault()
        return
      }

      // enter 입력 시 , 편집 종료 및 아래 셀로 이동
      // 한글의 경우 Composition 확인 필요
      if (event.nativeEvent.isComposing) {
        return
      }

      const currentCell = api.getFocusedCell()
      if (currentCell === null) {
        return
      }
      const rowIndex = currentCell.rowIndex + (event.shiftKey ? -1 : 1)
      const colKey = currentCell.column.getColId()

      // selection range를 제거
      api.clearRangeSelection()
      // 현재 cell의 editing 종료
      api.stopEditing()

      // 다음 cell의 editing 시작
      moveNextAfterEdit &&
        api.startEditingCell({
          rowIndex,
          colKey,
          charPress: 'Enter'
        })
    }

    const isValidateInput = () => {
      // uniuqe check를 수행할지 여부

      if (
        checkUniqueValueInColumn &&
        !checkValueIsUnique(
          // 현재 자기 자신의 row data를 제외하고 전부 검사
          getAllRowDatas(api)
            .filter((rowData) => rowData.rowIndex !== node.rowIndex)
            .map((rowData) => rowData.data),
          column.getColId(),
          inputValue
        )
      ) {
        // unique check를 통과하지 못한 경우
        // NOTE (hyeonseok 23.09.05): callback function 필요할 수 있음
        showFlashCells({ api, rowNodes: [node], targetElement: eGridCell, flashType: 'error' })

        return false
      }

      // validation check를 수행할지 여부
      if (
        isValidateCheckCallbackFunc &&
        !isValidateCheckCallbackFunc(inputValue, node, column, api)
      ) {
        // validationi check를 통과하지 못한 경우
        showFlashCells({ api, rowNodes: [node], targetElement: eGridCell, flashType: 'error' })
        return false
      }

      return true
    }

    return (
      <div
        style={{
          width: inputSize.width,
          height: inputSize.height
        }}
      >
        <NewInputControlComponent
          blurInputFunc={() => api.stopEditing()}
          changeInputFunc={handleInputChange}
          className={useTextAreaInputEditor ? 'cck-aggrid-cell-editor-input-textarea' : ''}
          initText={inputValue}
          inputControlTypeProps={{
            placeholderText: '',
            className: 'cck-full-size-input'
          }}
          maxLength={maxLength}
          useTextArea={useTextAreaInputEditor}
          validateState={inputValidState}
          onKeyDownFunc={handleOnKeyDown}
        />
      </div>
    )
  }
)

CCKBasicAgGridCellEditorComponent.displayName = 'CCKBasicAgGridCellEditorComponent'

export default CCKBasicAgGridCellEditorComponent
