import { Modal } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'

import { useTrapTabFocus } from 'common/hooks'
import ModalHeaderComponent, { ModalHeaderProps } from 'components/modal/ModalHeaderComponent'
import PortalComponent from 'components/portal/PortalComponent'

import './new_modal.scss'

export interface ModalComponentProps {
  children?: React.ReactNode
  headerProps?: ModalHeaderProps
  hasBottomPadding?: boolean
  closeModal?: () => void
  outsideClickClose?: boolean
  targetElementName?: string
  trapFocus?: boolean
  isDraggable?: boolean
  dragStyles?: React.CSSProperties
}

const NewModalComponent: React.FC<ModalComponentProps> = ({
  children,
  headerProps,
  hasBottomPadding = true,
  closeModal,
  outsideClickClose = true,
  targetElementName,
  trapFocus = true,
  isDraggable,
  dragStyles
}) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const draggleRef = useRef<HTMLDivElement>(null)

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    })
  }

  useTrapTabFocus(divRef, trapFocus)

  useEffect(() => {
    const adjustJustifyContent = () => {
      if (!divRef.current) {
        return
      }
      const divElement = divRef.current as HTMLElement
      const xOver = divElement.getBoundingClientRect().width > window.innerWidth
      const yOver = divElement.getBoundingClientRect().height > window.innerHeight
      divElement.style.transform = 'translate(-50%, -50%)'
      divElement.style.left = '50%'
      divElement.style.top = '50%'
      if (xOver && yOver) {
        divElement.style.transform = ''
        divElement.style.left = '0'
        divElement.style.top = '0'
      } else if (xOver) {
        divElement.style.transform = 'translate(0, -50%)'
        divElement.style.left = '0'
      } else if (yOver) {
        divElement.style.transform = 'translate(-50%, 0)'
        divElement.style.top = '0'
      }
    }

    adjustJustifyContent()
    window.addEventListener('resize', adjustJustifyContent)

    return () => {
      window.removeEventListener('resize', adjustJustifyContent)
    }
  }, [])

  const uiwrapper = document.querySelector(targetElementName || '.cck-ui-wrapper') as HTMLElement
  const handleDimmClick = outsideClickClose ? closeModal : undefined
  if (isDraggable) {
    return (
      <Modal
        open
        className="new-modal-draggable"
        closable={false}
        footer={null}
        modalRender={(modal) => (
          <Draggable
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        style={dragStyles}
        width={dragStyles?.width || '448px'}
      >
        {children}
      </Modal>
    )
  }
  return (
    <PortalComponent parentElement={uiwrapper}>
      <div className="new-modal-root">
        <div className="new-modal-dimm" onClick={handleDimmClick} />
        <div
          className={classNames('new-modal', {
            'new-modal-bottom-padding': hasBottomPadding
          })}
          ref={divRef}
        >
          <ModalHeaderComponent {...headerProps} />
          <div className="modal-body-frame">{children}</div>
        </div>
      </div>
    </PortalComponent>
  )
}

export default NewModalComponent
