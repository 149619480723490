import { IconComponent, IconType, isEmpty } from '@cck/common'
import { Spin, Layout } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import React, { Suspense, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  findIndexItemInner,
  selectedIndexItemAtom,
  selectedOneDepthIndexItemAtom
} from 'atoms/index/Index'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import NewBodyComponent from 'components/new_body/NewBodyComponent'
import NewLnbComponent from 'components/new_lnb/NewLnbComponent'
import NewStandardMenuViewComponent from 'components/new_menu/NewStandardMenuViewComponent'
import NewSearchKeywordListComponent from 'components/new_search/NewSearchKeywordListComponent'
import NewSearchStandardComponent from 'components/new_search/NewSearchStandardComponent'
import { getIndex } from 'services/standard_client'

const { Sider, Content } = Layout

const MainViewFrameComponent: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [leftSiderCollapsed, setLeftSiderCollapsed] = useState<boolean>(false)
  const [isChangeModeHover, setIsChangeModeHover] = useState<boolean>(false)
  const [selectedStandardId, setSelectedStandardId] = useRecoilState(selectedStandardIdAtom)

  const setSelectedOneDepthIndexItem = useSetRecoilState(selectedOneDepthIndexItemAtom)
  const setSelectedIndexItem = useSetRecoilState(selectedIndexItemAtom)

  const suspenseFallback = <Spin />

  const setLinkSelectedIndexItem = async (
    standardId: string,
    oneDepthIndexItemId: string,
    indexItemId: string
  ) => {
    // const indexItems = await getIndexItemSelector(standardId)
    const indexItems = (await getIndex(standardId)).indexList
    if (isEmpty(indexItems)) {
      return
    }

    const oneDepthIndexItem = await findIndexItemInner(indexItems, [oneDepthIndexItemId])
    if (!oneDepthIndexItem) {
      return false
    }

    const indexItem = await findIndexItemInner(
      oneDepthIndexItem.children,
      _.split(indexItemId, '.')
    )

    if (!indexItem) {
      return false
    }

    setSelectedStandardId(standardId)
    setSelectedOneDepthIndexItem(oneDepthIndexItem)
    setSelectedIndexItem(indexItem)

    return true
  }

  useEffect(() => {
    if (location.pathname !== '/standard/link') {
      return
    }

    const linkStandardId = location.state?.linkStandardId
    const linkOneDepthIndexItemId = location.state?.linkOneDepthIndexItemId
    const linkIndexItemId = location.state?.linkIndexItemId
    const linkItemName = location.state?.linkItemName
    if (linkStandardId && linkOneDepthIndexItemId && linkIndexItemId) {
      setLinkSelectedIndexItem(linkStandardId, linkOneDepthIndexItemId, linkIndexItemId).then(
        (isSuccess) => {
          if (!isSuccess) {
            return
          }

          navigate(
            [
              '',
              'standard',
              linkStandardId,
              linkOneDepthIndexItemId,
              linkIndexItemId,
              linkItemName
            ].join('/')
          )
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === '/standard/link') {
      return
    }

    if (selectedStandardId === '') {
      navigate('/standard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const makeDimArea = () => {
    if (!showDropdown) {
      return null
    }

    return (
      <div
        className="main-view-dim-frame"
        onClick={() => {
          setShowDropdown(false)
        }}
      />
    )
  }

  const makeDropdownMenu = () => {
    if (!showDropdown) {
      return null
    }

    return (
      <div className="main-view-dropdown-menu-frame">
        <Suspense fallback={suspenseFallback}>
          <NewStandardMenuViewComponent
            selectItemCallbackFunc={() => {
              setShowDropdown(false)
            }}
          />
        </Suspense>
      </div>
    )
  }

  const makeHeaderArea = () => {
    if (!showDropdown) {
      return (
        <div className="main-view-right-header-frame">
          <div
            className="main-view-right-header-item"
            onClick={() => {
              setShowDropdown((prev) => !prev)
            }}
          >
            <IconComponent iconType={IconType.OUTLINE_MENU} />
            전체 기준서
          </div>
          <div
            className="main-view-right-header-search-frame"
            onClick={() => {
              setShowDropdown((prev) => !prev)
            }}
          >
            <IconComponent enable="disable" iconType={IconType.SEARCH} />
            통합 검색
          </div>
        </div>
      )
    }

    return (
      <div className="main-view-dropdown-header-frame">
        <IconComponent
          clickCallbackFunc={() => {
            setShowDropdown(false)
          }}
          iconType={IconType.DELETE}
        />
        <IconComponent enable="disable" iconType={IconType.SEARCH} />
        <NewSearchKeywordListComponent />
        <div className="main-view-dropdown-header-search-frame">
          <NewSearchStandardComponent isHeaderView />
        </div>
      </div>
    )
  }

  const makeMainRightArea = () => {
    if (selectedStandardId === '') {
      return (
        <div className="main-view-right-content-empty-frame">
          <div className="main-view-right-content-text-title">
            <div className="chat-main-view-content-empty-header">
              <span className="chat-main-view-content-empty-header-1">ACCIO</span>{' '}
              <span className="chat-main-view-content-empty-header-2">Standard</span>{' '}
              <span className="chat-main-view-content-empty-header-3">for</span>{' '}
              <span className="chat-main-view-content-empty-header-4">CPA</span>
            </div>
          </div>
          <div className="main-view-right-content-item-frame">
            <Suspense fallback={suspenseFallback}>
              <NewStandardMenuViewComponent
                isMainView
                selectItemCallbackFunc={() => {
                  setShowDropdown(false)
                }}
              />
            </Suspense>
          </div>
        </div>
      )
    }

    return (
      <>
        {makeDropdownMenu()}
        {makeHeaderArea()}
        <div className="main-view-right-body-frame">
          <NewBodyComponent />
        </div>
      </>
    )
  }

  const leftSiderCollapseButton = (
    <IconComponent
      iconType={leftSiderCollapsed ? IconType.CHEVRON_COMPACT_RIGHT : IconType.CHEVRON_COMPACT_LEFT}
      size={22}
    />
  )

  const makeLeftSider = () => {
    if (leftSiderCollapsed) {
      return (
        <div className="main-view-left-sider-collapse-inner-frame">
          <div className="main-view-left-header-frame">
            <IconComponent isSvg iconType={IconType.ACCIO_CHAT} size={35} />
          </div>
          <div className="main-view-left-header-icon-frame">
            <div
              className={classNames({
                'main-view-left-header-change-mode-icon': true,
                'main-view-left-header-change-mode-icon-hover': isChangeModeHover,
                'main-view-left-header-change-mode-icon-collapsed': true
              })}
              onClick={() => {
                navigate('/chat-kicpa-info')
              }}
              onMouseEnter={() => {
                setIsChangeModeHover(true)
              }}
              onMouseLeave={() => {
                setIsChangeModeHover(false)
              }}
            >
              <IconComponent iconType={IconType.SWITCH} />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="main-view-left-sider-inner-frame">
        <div className="main-view-left-header-frame">
          <IconComponent isSvg iconType={IconType.ACCIO_CHAT} size={35} />
          <div>
            ACCIO <span className="main-view-left-header-title">Standard</span>
          </div>
        </div>
        <div
          className="main-view-left-header-change-mode-frame"
          onClick={() => {
            navigate('/chat-kicpa-info')
          }}
          onMouseEnter={() => {
            setIsChangeModeHover(true)
          }}
          onMouseLeave={() => {
            setIsChangeModeHover(false)
          }}
        >
          <div
            className={classNames({
              'main-view-left-header-change-mode-text': true,
              'main-view-left-header-change-mode-text-hover': isChangeModeHover
            })}
          >
            대화로 전환
          </div>
          <div
            className={classNames({
              'main-view-left-header-change-mode-icon': true,
              'main-view-left-header-change-mode-icon-hover': isChangeModeHover
            })}
          >
            <IconComponent iconType={IconType.SWITCH} />
          </div>
        </div>
        <div className="main-view-left-body-frame">
          <Suspense fallback={suspenseFallback}>
            <NewLnbComponent />
          </Suspense>
        </div>
        <div className="main-view-left-footer-frame">
          Copyright © 2024 CCK Solution. All rights reserved.
        </div>
      </div>
    )
  }

  return (
    <div className="main-view-wrapper-frame">
      {makeDimArea()}
      <div className="main-view-frame">
        <Layout>
          <Sider
            collapsible
            className="main-view-left-frame"
            collapsed={leftSiderCollapsed}
            collapsedWidth={70}
            trigger={null}
            width={278}
          >
            {makeLeftSider()}
          </Sider>
          <Content className="main-view-right-frame">
            <div className="main-view-right-content-inner-frame">
              <Suspense fallback={suspenseFallback}>
                <div className="main-view-right-scroll-frame">
                  <div
                    className="left-sider-collapse-button"
                    onClick={() => {
                      setLeftSiderCollapsed((prev) => !prev)
                    }}
                  >
                    {leftSiderCollapseButton}
                  </div>
                  {makeMainRightArea()}
                </div>
              </Suspense>
            </div>
          </Content>
        </Layout>
      </div>
    </div>
  )
}

export default MainViewFrameComponent
