import { isEmpty, notEmpty } from '@cck/common'
import { Divider, Spin } from 'antd'
import React, { useMemo } from 'react'

import ChatMessageRequestQuestionComponent from 'components/chat/message//ChatMessageRequestQuestionComponent'
import ChatMessageAnswerComponent, {
  ChatAnswer
} from 'components/chat/message/ChatMessageAnswerComponent'
import ChatMessageQuestionComponent, {
  ChatQuestion
} from 'components/chat/message/ChatMessageQuestionComponent'
import ChatMessageReferenceComponent, {
  ChatReference
} from 'components/chat/message/ChatMessageReferenceComponent'

export interface ChatMessage {
  question: ChatQuestion
  references: ChatReference[]
  answer: ChatAnswer
  relatedQuestions: string[]
  isDone: boolean
}

interface ChatMessageWrapperComponentProps {
  chatMessage: ChatMessage
  submitRequestQuestion: (question: string) => void
  isLastMessage?: boolean
  setSelectReferences: (references: ChatReference[]) => void
}

const ChatMessageWrapperComponent: React.FC<ChatMessageWrapperComponentProps> = ({
  chatMessage,
  submitRequestQuestion,
  setSelectReferences,
  isLastMessage = false
}) => {
  const makeBottomDivider = () => {
    if (isLastMessage) {
      return <div style={{ height: '30px' }} />
    }

    return <Divider style={{ borderBlockStartWidth: 2, borderColor: 'lightgray' }} />
  }

  const recentChatMessageWrapper = useMemo(() => {
    return (
      <ChatMessageReferenceComponent
        references={chatMessage.references}
        setSelectReferences={setSelectReferences}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessage.references])

  const makeMessageContents = () => {
    if (!chatMessage.isDone && isEmpty(chatMessage.answer.answer)) {
      return <Spin />
    }

    return (
      <>
        <Divider style={{ margin: 0 }} />
        {recentChatMessageWrapper}
        <ChatMessageAnswerComponent
          answer={chatMessage.answer}
          isDone={chatMessage.isDone}
          references={chatMessage.references}
          setSelectReferences={setSelectReferences}
        />
        {notEmpty(chatMessage.relatedQuestions) && chatMessage.isDone && (
          <>
            <Divider style={{ margin: 0 }} />
            <ChatMessageRequestQuestionComponent
              requestQuestions={chatMessage.relatedQuestions}
              submitRequestQuestion={submitRequestQuestion}
            />
          </>
        )}
        {makeBottomDivider()}
      </>
    )
  }

  return (
    <div className="chat-message-wrapper">
      <ChatMessageQuestionComponent question={chatMessage.question} />
      {makeMessageContents()}
    </div>
  )
}

export default ChatMessageWrapperComponent
