import 'dotenv/config'

const BASE_URL = window.location.origin

async function sendRequest(url: string, body: unknown) {
  try {
    const result = await fetch(`${BASE_URL}/v1/blahblah/${url}`, {
      method: 'POST',
      body: JSON.stringify(body),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    return await result.json()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Failed to post request', url, JSON.stringify(body), error)
  }
}

export interface Standard {
  id: string
  name: string
}

export interface StandardsReply {
  standards: Standard[]
}

export async function getStandards(): Promise<StandardsReply> {
  return (await sendRequest('standard/get-standards', {})) as StandardsReply
}

export interface Index {
  id: string
  name: string
  subname: string
  children: Index[]
}

export interface IndexReply {
  indexList: Index[]
}

export async function getIndex(standardId: string): Promise<IndexReply> {
  return (await sendRequest('standard/get-index', { standardId })) as IndexReply
}

export interface Content {
  id: string
  name: string
  content: string
  bookmarkable: boolean
}

export interface ContentReply {
  contents: Content[]
}

export async function getContent(standardId: string, id: string): Promise<ContentReply> {
  return (await sendRequest('standard/get-content', { standardId, id })) as ContentReply
}

export interface SearchCount {
  standardId: string
  id: string
  count: number
}

export interface SearchReply {
  counts: SearchCount[]
}

export async function searchKeyword(keyword: string): Promise<SearchReply> {
  return (await sendRequest('standard/search-keyword', { keyword })) as SearchReply
}

export interface BookmarkIds {
  standardId: string
  ids: string[]
}

export async function sortIds(standardId: string, ids: string[]): Promise<BookmarkIds> {
  return (await sendRequest('standard/sort-ids', { standardId, ids })) as BookmarkIds
}

export interface AutocompleteReply {
  keywords: string[]
}

export async function loadAutoCompleteKeywords(): Promise<AutocompleteReply> {
  return (await sendRequest('standard/load-auto-complete-keywords', {})) as AutocompleteReply
}
