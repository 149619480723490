import { IconComponent, IconType, ScrollBarComponent } from '@cck/common'
import { Divider, Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const MainChatLeftSiderViewComponent: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const navigate = useNavigate()

  const handleChatSwitch = () => {
    navigate('/standard')
  }

  const sampleItems = [
    {
      label: <div className="chat-view-scrap-label">스크랩 1</div>,
      key: '1',
      icon: (
        <IconComponent
          isSvg
          className="chat-view-scrap"
          enable="enable"
          iconType={IconType.FOLDER_FILL2}
          size={18}
        />
      ),
      children: [
        {
          label: (
            <div className="chat-view-scrap-sub-label">회계단위를 구분한 재무제표를 하나로</div>
          ),
          key: '3'
        },
        {
          label: <div className="chat-view-scrap-sub-label">스크랩 그룹명 2</div>,
          key: '3'
        }
      ]
    }
  ]
  const leftSiderCollapseButton = (
    <IconComponent
      iconType={isCollapsed ? IconType.CHEVRON_COMPACT_RIGHT : IconType.CHEVRON_COMPACT_LEFT}
      size={22}
    />
  )
  const renderHeader = () => {
    const renderTitle = () => {
      if (isCollapsed) {
        return null
      }
      return (
        <div className="chat-main-view-sider-header-title">
          ACCIO <span className="chat-main-view-sider-title-chat">Chat</span>
        </div>
      )
    }
    return (
      <div className="chat-main-view-sider-header">
        <div className="chat-main-view-sider-title">
          <IconComponent isSvg iconType={IconType.ACCIO_CHAT} size={35} />
          {renderTitle()}
        </div>
      </div>
    )
  }
  const renderCollapsedContent = () => {
    return (
      <div className="chat-main-view-sider-collapsed-frame">
        <div className="chat-main-view-sider-main-wrapper">
          {renderHeader()}
          <div className="chat-main-view-editor-list">
            <div className="chat-main-view-button chat-main-view-switch-button">
              <IconComponent iconType={IconType.SWITCH} />
            </div>
            <div className="chat-main-view-button chat-main-view-add-button">
              <IconComponent isSvg iconType={IconType.CHAT_ADD} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderExtendedContent = () => {
    return (
      <div className="chat-main-view-sider-frame">
        <div className="chat-main-view-sider-main-wrapper">
          {renderHeader()}
          <div
            className="chat-main-view-sider-switch-btn"
            onClick={() => {
              handleChatSwitch()
            }}
          >
            <div className="chat-main-view-sider-switch-btn-text">기준서로 전환</div>
            <div className="chat-main-view-sider-switch-btn-icon">
              <IconComponent iconType={IconType.SWITCH} />
            </div>
          </div>
          <div className="chat-main-view-sider-content-wrapper">
            <div className="chat-main-view-sider-new-chat-btn">
              <div className="chat-main-view-sider-new-chat-btn-text">새 대화 시작</div>
              <div className="chat-main-view-sider-new-chat-btn-icon">
                <IconComponent isSvg iconType={IconType.CHAT_ADD} />
              </div>
            </div>
            <ScrollBarComponent autoHideEnable>
              <div className="chat-main-view-sider-subtitle">
                스크랩
                <IconComponent iconType={IconType.ADD} size={18} />
              </div>
              <Menu
                className="chat-main-view-scrap-menu"
                defaultOpenKeys={['sub1']}
                defaultSelectedKeys={['1']}
                inlineCollapsed={isCollapsed}
                items={sampleItems}
                mode="inline"
                style={{ borderInlineEndWidth: 0 }}
                theme="light"
              />
              <Divider style={{ margin: '15 0px' }} />
              <div className="chat-main-view-sider-conversation">
                <div className="chat-main-view-sider-subtitle">대화기록</div>
                <div className="chat-main-view-sider-conversation-list">
                  <div className="chat-main-view-sider-conversation-sub-list">
                    <div className="chat-main-view-sider-conversation-sub-list-title">즐겨찾기</div>
                    <div className="chat-main-view-sider-conversation-sub-list-item-list">
                      <div className="chat-main-view-sider-conversation-item">
                        주석 해설 실무사계
                      </div>
                      <div className="chat-main-view-sider-conversation-item">최근 대화 3</div>
                    </div>
                  </div>
                  <div className="chat-main-view-sider-conversation-sub-list">
                    <div className="chat-main-view-sider-conversation-sub-list-title">최근</div>
                    <div className="chat-main-view-sider-conversation-sub-list-item-list">
                      <div className="chat-main-view-sider-conversation-item">
                        주석 해설 실무사계
                      </div>
                      <div className="chat-main-view-sider-conversation-item">최근 대화 3</div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollBarComponent>
          </div>
        </div>
        <div className="chat-main-view-sider-user-info-wrapper">로그인 한 사용자</div>
      </div>
    )
  }
  const renderContent = () => {
    if (isCollapsed) {
      return renderCollapsedContent()
    }
    return renderExtendedContent()
  }
  return (
    <Sider
      collapsible
      className="chat-main-view-sider"
      collapsed={isCollapsed}
      collapsedWidth={70}
      theme="light"
      trigger={null}
      width={278}
    >
      <div
        className="left-sider-collapse-button"
        onClick={() => {
          setIsCollapsed((prev) => !prev)
        }}
      >
        {leftSiderCollapseButton}
      </div>
      {renderContent()}
    </Sider>
  )
}

export default MainChatLeftSiderViewComponent
