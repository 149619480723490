import { useRecoilValue } from 'recoil'

import { searchStandardCountMapAtom } from 'atoms/search/Search'
import { findSearchCountItem } from 'components/util/SearchUtil'

type Hook = (isStandardId: boolean, indexItemId?: string) => boolean

export const useSearchResultExist: Hook = (isStandardId, indexItemId) => {
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)
  if (!indexItemId || indexItemId === '') {
    return false
  }

  let isExist = false
  searchStandardCountMap.forEach((value) => {
    const found = findSearchCountItem(value, indexItemId, isStandardId)

    if (found) {
      isExist = true
      return false // break forEach loop
    }
  })

  return isExist
}
