import React from 'react'

import { CommonAgGridHeaderProps } from 'components/ccktable/control/AgGridTypes'

interface CustomAgGridHeaderComponentProps extends CommonAgGridHeaderProps {
  value: React.ReactNode
}

const CustomAgGridHeaderComponent: React.FC<CustomAgGridHeaderComponentProps> = ({ value }) => {
  return <div className="cck-table-custom-header-frame">{value}</div>
}

export default CustomAgGridHeaderComponent
