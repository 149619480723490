// ex)
// numberString : '1.1234567890123'
// digits: 6
// return value : '1.123456'
// TODO (hyeonseok 23.03.29):
// numberString이 e-n 으로 끝나는 아주 작은 값의 변환 로직 필요
export function toFixedString(numberString: string, digits: number): string {
  return parseFloat(numberString).toFixed(digits)
}

// 스트링을 input으로 받아
// point에 해당하는 위치까지 반올림한 % 스트링 반환
export function formatPercentage(input: string, decimalPlaces: number): string {
  const number = parseFloat(input)
  const multiplier = Math.pow(10, decimalPlaces + 2) // 소수점 2자리를 추가로 고려하기 위해 2를 더함
  const rounded = Math.round(number * multiplier) / (multiplier / 100)
  return rounded.toFixed(decimalPlaces) + '%'
}

export function roundNumber(input: string, decimalPlaces: number): string {
  const number = parseFloat(input)
  return number.toFixed(decimalPlaces)
}

// min, max 값 사이의 난수 생성
export function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function numberWithCommas(input: string): string {
  const [integerPart, decimalPart] = input.split('.')
  const integerFormatted = parseInt(integerPart.replace(',', ''), 10).toLocaleString('en-US')
  return decimalPart ? `${integerFormatted}.${decimalPart}` : integerFormatted
}

export function newNumberWithCommas(input: string): string {
  if (input.trim() === '') {
    return ''
  }

  // 소수점으로 분리
  const [integerPart, decimalPart] = input
    .trim()
    .replace(/\u200B/g, '')
    .split('.')

  // 정수 부분에 천 단위 구분자 추가
  const integerFormatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // 소수점 이하 부분이 있거나, 입력이 소수점으로 끝나는 경우 처리
  if (decimalPart !== undefined || input.endsWith('.')) {
    return `${integerFormatted}.${decimalPart ?? ''}`
  }

  return integerFormatted
}
