import { IconComponent, IconType } from '@cck/common'
import { Header } from 'antd/es/layout/layout'
import React, { useState } from 'react'

const MainChatViewHeaderComponent: React.FC = () => {
  const [bookmark, setBookmark] = useState(false)
  return (
    <Header className="chat-main-view-header">
      <div className="chat-main-view-header-frame">
        <div className="chat-main-view-header-frame-left">
          <IconComponent
            isSvg
            className={`chat-main-view-header-${bookmark ? 'bookmark' : 'non-bookmark'}`}
            clickCallbackFunc={() => setBookmark((prev) => !prev)}
            iconType={IconType.STAR_REWARD}
            size={18}
          />
          무제
          <div className="chat-main-view-tag"># NnMmNnMm</div>
        </div>
        <div>/</div>
        <div className="chat-main-view-header-frame-right">오늘</div>
      </div>
    </Header>
  )
}

export default MainChatViewHeaderComponent
