export function isEmpty<T>(value: T | undefined): value is undefined {
  if (typeof value === 'number' || typeof value === 'boolean') {
    return false
  }
  if (typeof value === 'undefined' || value === null) {
    return true
  }
  if (value instanceof Map) {
    return value.size === 0
  }
  if (value instanceof Date) {
    return false
  }
  if (value instanceof Object && !Object.keys(value).length) {
    return true
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return true
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (<any>value === '') {
    return true
  }

  return false
}

export function notEmpty<T>(value: T): value is Required<Exclude<T, undefined | null>> {
  return !isEmpty(value) // add "as any" if still got error here
}
