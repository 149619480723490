import { SearchCount } from 'services/standard_client'

export function findSearchCountItem(
  target: SearchCount[],
  id: string,
  isStandard: boolean
): SearchCount | undefined {
  if (isStandard) {
    return target.find((item) => item.standardId === id)
  }

  return target.find((item) => item.id === id)
}
