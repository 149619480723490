import { Column, GridApi, RowNode } from 'ag-grid-community'
import React from 'react'

import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'
import CheckboxComponent from 'components/checkbox/CheckboxComponent'

interface CheckboxAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  callbackCheckboxChange?: (
    checked: boolean,
    gridApi: GridApi,
    targetColumnNode: RowNode,
    column: Column
  ) => void
  checkboxClickDisabled?: boolean
}

const CheckboxAgGridCellRendererComponent: React.FC<CheckboxAgGridCellRendererComponentProps> = ({
  node,
  api,
  column,
  checkboxClickDisabled,
  callbackCheckboxChange,
  callbackIsContentDisable,
  callbackIsContentVisible,
  eGridCell
}) => {
  const handleCheckboxStateChange = (checked: boolean) => {
    // Check 값 반영
    node.setDataValue(column.getColId(), checked)
    // Checkbox Component에서 발생한 event를 ag-grid prop들과 조합하여 callback으로 전달
    callbackCheckboxChange && callbackCheckboxChange(checked, api, node, column)
  }

  const visible = callbackIsContentVisible ? callbackIsContentVisible(node, column) : true
  const disable = callbackIsContentDisable ? callbackIsContentDisable(node, column) : false
  const checked = Boolean(node?.data[column.getColId()])

  return (
    <div
      className={`cck-renderer-common cck-table-checkbox-${
        checkboxClickDisabled ? 'click-disable' : 'click-enable'
      }`}
    >
      {visible && (
        <CheckboxComponent
          callbackCheckboxStateChange={handleCheckboxStateChange}
          checked={checked}
          disabled={disable}
          size={18}
        />
      )}
    </div>
  )
}

CheckboxAgGridCellRendererComponent.displayName = 'CheckboxAgGridCellRendererComponent'

export default CheckboxAgGridCellRendererComponent
