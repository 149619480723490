import React from 'react'

import ButtonListComponent, { ButtonWrapperProp } from 'components/button/ButtonListComponent'
import { isEmpty } from 'utils/common_utils'

import './new_modal.scss'

export interface ModalHeaderProps {
  title?: string
  subTitle?: string
  buttons?: ButtonWrapperProp[]
}

const ModalHeaderComponent: React.FC<ModalHeaderProps> = ({ title, subTitle, buttons }) => {
  if (!title) {
    return null
  }
  const makeTitle = () => {
    return (
      <div className="modal-header-title">
        <span className="modal-header-main-title">{title}</span>
        {subTitle && <span className="modal-header-sub-title">{subTitle}</span>}
      </div>
    )
  }

  const makeButtonList = () => {
    if (isEmpty(buttons)) {
      return null
    }
    return <ButtonListComponent align="right" buttons={buttons} />
  }

  const makeHeader = () => {
    return (
      <div className="modal-header-wrapper">
        {makeTitle()}
        {makeButtonList()}
      </div>
    )
  }

  const renderModal = () => {
    return <div className="modal-header-frame">{makeHeader()}</div>
  }

  return renderModal()
}

export default ModalHeaderComponent
