import { useState } from 'react'

const BASE_URL = window.location.origin

interface SourceDataInfo {
  node_id: string
  score: number
  text: string
  metadata: { [key: string]: string }
}

function useChat() {
  const [answer, setAnswer] = useState<string>('')
  const [isAnswerDone, setIsAnswerDone] = useState<boolean>(true)
  const [sourceNodes, setSourceNodes] = useState<SourceDataInfo[]>([])
  const [relatedQuestions, setRelatedQuestions] = useState<string[]>([])

  const initChatContext = () => {
    setAnswer('')
    setIsAnswerDone(false)
    setSourceNodes([])
    setRelatedQuestions([])
  }

  const askQuestion = (question: string) => {
    initChatContext()

    const encodedQuestion = encodeURIComponent(question)
    const chatUrl = BASE_URL.endsWith('8080') ? BASE_URL.replace('8080', '8081') : BASE_URL
    const eventSource = new EventSource(
      `${chatUrl}/v1/blahblah-chat/chat?question=${encodedQuestion}`
    )
    // const eventSource = new EventSource(
    //   `http://192.168.11.204:8081/v2/blahblah-chat/chat?question=${encodedQuestion}`
    // )
    eventSource.onmessage = function (event) {
      const data = JSON.parse(event.data)

      // 스트림의 끝을 감지하고 연결을 종료합니다.
      if (data.end) {
        // eslint-disable-next-line no-console
        console.log('Stream ended')
        setIsAnswerDone(true)
        eventSource.close()
      } else {
        // 메시지 데이터를 받아 상태를 업데이트합니다.
        setAnswer(data.message) // 메시지 내용을 상태에 저장합니다.
      }

      // source node
      if (data.source_nodes) {
        setSourceNodes(JSON.parse(data.source_nodes) as SourceDataInfo[])
      }

      if (data.related_questions) {
        setRelatedQuestions(data.related_questions)
      }
    }

    eventSource.onerror = function (err) {
      // eslint-disable-next-line no-console
      console.error('EventSource failed:', err)
      eventSource.close()
    }
  }

  return { answer, isAnswerDone, sourceNodes, relatedQuestions, askQuestion }
}

export default useChat
