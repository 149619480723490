import type { TooltipProps } from 'antd'

import {
  SVG_ACCIO_CHARAC,
  SVG_ACCIO_CRY,
  SVG_ACCIO_SMILE,
  SVG_ASSIGNED_CURSOR,
  SVG_ASSIGNED_CURSOR_WHITE,
  SVG_AUTO_ADD,
  SVG_CCK_BANK_OUTLINE,
  SVG_DASHBOARD,
  SVG_EXCEL_FILE,
  SVG_EXCLAMATION,
  SVG_PDF_FILE,
  SVG_QESTION,
  SVG_STAR_YELLOW,
  SVG_TICKMARK_PDF,
  SVG_PROJECT_ROOM,
  SVG_SHARE,
  SVG_FOLDER_OUTLINE1,
  SVG_DSD_FILE,
  SVG_IMAGE_FILE,
  SVG_KEY_SECURITY,
  SVG_CERTIFICATE_MONITER,
  SVG_CLOCK,
  SVG_TICKMARK_EDIT,
  SVG_ZIP_FILE,
  SVG_HWP_FILE,
  SVG_ETC_FILE,
  SVG_VERSION_FILL_1,
  SVG_MS_WORD_FILE,
  SVG_TRASH_FILL1,
  SVG_PRINTER,
  SVG_HTML_FILE,
  SVG_TXT_FILE,
  SVG_XLSX_WHITE_FILE,
  SVG_XLSX_GREEN_FILE,
  SVG_DOCX_WHITE_FILE,
  SVG_DOCX_BLUE_FILE,
  SVG_PPTX_FILE,
  SVG_PPTX_GREY_FILE,
  SVG_DOCX_ORIGIN_GREY_FILE,
  SVG_XLSX_ORIGIN_GREY_FILE,
  SVG_XLSX_ORIGIN_FILE,
  SVG_FOLDER_FILL2,
  SVG_EMPTY_DATA,
  SVG_EMPTY_DATA_BLUE,
  SVG_INSTALL,
  SVG_REPLACE,
  SVG_DOCUMENT,
  SVG_YOUTUBE,
  SVG_EDIT,
  SVG_GROUP_PEOPLE,
  SVG_PERSON,
  SVG_VERSIONS,
  SVG_NO_ROOM,
  SVG_SUGGESTED_SYMBOL_X,
  SVG_COPY_TWOTONE,
  SVG_EDIT_PIN,
  SVG_SUGGESTED_CIRCLE_PLUS,
  SVG_UNLINKED,
  SVG_DOCUMENT_REFERENCE,
  SVG_DSD_FOLDER_ITEM,
  SVG_MOVE_NEXT,
  SVG_EDIT_WRITE,
  SVG_EMPTY_FOLDER,
  SVG_TRASH_OUTLINE,
  SVG_PASTE,
  SVG_BUBBLE_COMMENT,
  SVG_DOLLAR_OUTLINED,
  SVG_KEYBOARD_CLOSE_OUTLINE,
  SVG_BASELINE_CARD_MEMBERSHIP,
  SVG_MONEY,
  SVG_DOOR_CLOSED_LOCK,
  SVG_GLOBAL_OUTLINE,
  SVG_BOX_LINE,
  SVG_GRAPH_LINE,
  SVG_BETA_LINE,
  SVG_MANAGE_ACCOUNTS_OUTLINE,
  SVG_ID_CARD,
  SVG_DICTIONARY_ROUNDED,
  SVG_TEXT_MANUAL,
  SVG_VIDEO_MANUAL,
  SVG_DSD_DIFF,
  SVG_VIDEO_YOUTUBE,
  SVG_SCOURT_BALANCE,
  SVG_CUT,
  SVG_FILE_ADD,
  SVG_ROTATE_LEFT,
  SVG_ROTATE_RIGHT,
  SVG_DOCUMENT_WHITE,
  SVG_ADD_BLACK_CIRCLE,
  SVG_SCISSORS,
  SVG_PDF_EDITOR_EMPTY,
  SVG_SESSION_CHECK,
  SVG_WETAX_LOGO,
  SVG_STOCK_VOLATILITY,
  SVG_HORIZONTAL_SCROLL,
  SVG_NOTIFICATION,
  SVG_MAIL_SEND_FILL_1,
  SVG_ROUND_PEOPLE_1,
  SVG_DART,
  SVG_MANUAL_TIP,
  SVG_MANUAL_ERROR,
  SVG_ALERT_FILL_1,
  SVG_BCL_PREVIEW,
  SVG_VIDEO_MANUAL_WHITE,
  SVG_BCL_UPLOAD_ICON,
  SVG_POST_PDF,
  SVG_SQUARE_ARROW_DOWN,
  SVG_SQUARE_ARROW_UP,
  SVG_SQUARE_SPACE,
  SVG_SQUARE_ENTER,
  SVG_SQUARE_BACKSPACE,
  SVG_CURSOR_ARROW,
  SVG_BOOK_CONTENT,
  SVG_SUBMIT,
  SVG_ACCIO_CHAT,
  SVG_ACCIO_CHAT_EXAMPLE_1,
  SVG_ACCIO_CHAT_EXAMPLE_2,
  SVG_ACCIO_CHAT_EXAMPLE_3,
  SVG_ACCIO_CHAT_EXAMPLE_4,
  SVG_STAR_REWARD,
  SVG_CHAT_ADD,
  SVG_FILTER,
  SVG_FAM_NEW_CHAT
} from 'components/icon/SVGString'

type IconStateType =
  | 'enable'
  | 'disable'
  | 'error'
  | 'check'
  | 'enable-white'
  | 'enable-red'
  | 'enable-amber'
  | 'enable-blue'

export const IconType = {
  ADD: 'material-symbols:add',
  ARROW_DOWN: 'material-symbols:arrow-drop-down',
  ARROW_RIGHT: 'material-symbols:arrow-right',
  ARROW_LEFT: 'material-symbols:arrow-left',
  ARROW_RIGHT_LINE: 'formkit:arrowright',
  ARROW_LEFT_LINE: 'formkit:arrowleft',
  CANCEL: 'material-symbols:cancel',
  CHECK_BOX_BLANK_LINE: 'material-symbols:check-box-outline-blank',
  CHECK_BOX_FILL: 'material-symbols:check-box',
  CHECK_CIRCLE: 'material-symbols:check-circle',
  CHECK: 'material-symbols:check',
  CHEVRON_LEFT: 'material-symbols:chevron-left',
  COLOR_PICKER: 'fluent:color-20-regular',
  DATE_RANGE: 'material-symbols:date-range-outline-sharp',
  DOCUMENT_LIST: 'lucide:list',
  DOT: 'mdi:dot',
  DOWNLOAD: 'material-symbols:download',
  DOWNLOAD_LOADING: 'line-md:downloading-loop',
  EMPTY_FILE: 'teenyicons:file-no-access-solid',
  ERROR: 'material-symbols:error',
  EXPAND_MORE: 'material-symbols:expand-more',
  FILE_COPY: 'ri:file-copy-2-line',
  IMAGE_OUTLINE_SHARP: 'material-symbols:image-outline-sharp',
  IMAGE_SEARCH: 'material-symbols:image-search',
  MEETING_ROOM: 'material-symbols:meeting-room-outline',
  MINUS: 'ic:baseline-minus',
  MORE_HORIZ: 'material-symbols:more-horiz',
  MOUSE_GRAP: 'pepicons-pencil:hand-grab',
  MOUSE_POINTER: 'bx:pointer',
  PAGE_NUMBER: 'fluent:document-page-number-24-regular',
  PERSON_OUTLINE: 'material-symbols:person-outline',
  POINT_FILE: 'ant-design:file-ppt-filled',
  POWER_RESET: 'grommet-icons:power-reset',
  SAVE_OUTLINE: 'ant-design:save-outlined',
  SEARCH: 'material-symbols:search',
  SEARCH_THIN: 'iconamoon:search-thin',
  SLASH: 'la:slash',
  SPEECH_BUBBLE: 'simple-line-icons:speech',
  STAMP: 'ph:stamp',
  THUMBNAIL: 'carbon:thumbnail-2',
  TXT_FILE: 'bxs:file-txt',
  UPLOAD: 'material-symbols:upload',
  WORD_FILE: 'ant-design:file-word-filled',
  ZOOM_IN: 'ri:zoom-in-line',
  ZOOM_OUT: 'ri:zoom-out-line',
  CLOSE: 'material-symbols:close',
  USER_SETTING: 'mingcute:user-setting-line',
  HISTORY: 'ic:round-history',
  DROPDOWN_BUTTON: 'icon-park-outline:down',
  GEAR: 'ant-design:setting-filled',
  EDIT_SHARP: 'material-symbols:edit-sharp',
  LOGIN: 'material-symbols:login',
  LOGOUT_BASIC: 'material-symbols:logout',
  OUTLINE_MENU: 'ic:outline-menu',
  USER_ADD_LINE: 'mingcute:user-add-2-line',
  LEFT_CLICK: 'material-symbols:left-click',
  HAND_DRAG: 'icon-park:hand-drag',
  MARK_PEN: 'ri:mark-pen-line',
  ARROW_RIGHT_BOLD: 'solar:arrow-right-bold',
  COMMENT: 'gg:comment',
  BASELINE_LAST_PAGE: 'ic:baseline-last-page',
  SEARCH_MINUS: 'fe:search-minus',
  SEARCH_PLUS: 'fe:search-plus',
  BANK_OUTLINE: 'mdi:bank-outline',
  PAPER_FOLD: 'majesticons:paper-fold',
  USER_ADD_2_LINE: 'mingcute:user-add-2-line',
  BUILDING_OFFICE: 'heroicons:building-office-2',
  EMAIL_OUTLINE: 'mdi:email-outline',
  MACKBOOK_LINE: 'ri:macbook-line',
  STAR_FILL: 'solar:star-bold',
  STAR_OUTLINE: 'solar:star-linear',
  NEXT: 'tdesign:next',
  PREV: 'tdesign:previous',
  CALL_SHARP: 'material-symbols:call-sharp',
  BASELINE_PRINT: 'ic:baseline-print',
  BELL: 'mdi:bell',
  BOX_LIGHT: 'iconamoon:box-light',
  COMPANY: 'mdi:company',
  BASELINE_LIST_1: 'ic:baseline-list',
  CHEVRON_DOWN: 'tabler:chevron-down',
  CHEVRON_RIGHT: 'tabler:chevron-right',
  SETTING: 'uil:setting',
  SPEAKER: 'gridicons:speaker',
  CONTRAST: 'material-symbols:contrast',
  SAVE_DISK: 'streamline:interface-content-save-disk-floppy-electronics-device-disc-computer',
  SIDEBAR: 'lucide:sidebar',
  SIDEBAR_OPEN: 'lucide:sidebar-open',
  SIDEBAR_CLOSE: 'lucide:sidebar-close',
  ARROWS_IN_CIRCLE_UP: 'fluent:arrow-circle-up-48-filled',
  PLUS_CIRCLE: 'gala:add',
  FULL_SCREEN: 'subway:fot-screen',
  WARNING_TRIANGLE: 'material-symbols:warning',
  HOUSE_OUTLINE: 'mdi:house-outline',
  LOCATION_FILL: 'carbon:location-filled',
  REAL_ESTATE_FILL: 'fluent:real-estate-20-filled',
  ROAD_SPIKES: 'fa6-solid:road-spikes',
  BUILDING: 'bxs:building',
  BUILDING_SOLID: 'clarity:building-solid',
  MANUAL_BOOK: 'streamline:manual-book',
  ROTATE_LEFT_DOCUMENT: 'mdi:file-rotate-left-outline',
  DOCUMENT_STACK: 'system-uicons:document-stack',
  TRASH_BASIC: 'ph:trash',
  ADD_DOCUMENT_BASIC: 'carbon:document-add',
  CUT_BASIC: 'streamline:cut',
  EXCHANGE: 'uil:exchange',
  REFRESH_DOCUMENT: 'mdi:file-refresh-outline',
  LOGOUT: 'material-symbols-light:logout',
  BOOK_OUTLINE: 'material-symbols-light:book-2-outline',
  VIDEO_YOUTUBE_OUTLINE: 'uit:youtube',
  COMBINE: 'lucide:combine',
  EXPAND: 'iconoir:expand',
  PREV_ARROW: 'ooui:arrow-previous-ltr',
  NEXT_ARROW: 'ooui:arrow-previous-rtl',
  HIDE: 'el:eye-close',
  CHANGE: 'ic:outline-change-circle',
  MAIL_SEND_FILL: 'ri:mail-send-fill',
  TEXT_AD: 'material-symbols:text-ad',
  LIGHT_BULB: 'line-md:lightbulb',
  QUESTION: 'mingcute:question-fill',
  ALARM: 'ion:alarm-outline',
  MDI_PRINTER: 'mdi:printer',
  SWITCH: 'ep:switch',
  BOOKMARK_OUTLINE: 'material-symbols:bookmark-outline',
  COLLAPSE_LEFT: 'mdi:arrow-collapse-left',
  COLLAPSE_RIGHT: 'mdi:arrow-collapse-right',
  CHEVRON_COMPACT_LEFT: 'bi:chevron-compact-left',
  CHEVRON_COMPACT_RIGHT: 'bi:chevron-compact-right',
  FOLDER_FILE_ALT: 'lets-icons:folder-file-alt',
  FILE: 'pepicons-pencil:file',
  ANSWER: 'uis:align-left',
  RELOAD: 'streamline:arrow-reload-horizontal-1',
  ARROW_UP_RIGHT: 'ph:arrow-up-right-light',
  SOLID_EYE: 'iconoir:eye-solid',
  FOLDED_BOOK: 'solar:book-outline',
  OPENED_BOOK: 'system-uicons:book-text',
  DELETE: 'iwwa:delete',
  TABLE: 'material-symbols:table',
  TABLE_COLUMN: 'fluent:text-column-two-20-regular',
  TEXT_SUMMARY: 'ooui:text-summary-rtl',
  FAM_SETTINGS: 'arcticons:ai-chat',
  FAM_CHAT: 'vaadin:chat',
  FAM_CHAT_ME: 'fluent:emoji-meme-24-filled',
  FAM_CHAT_BOT: 'game-icons:slime',
  FAM_THEME: 'mdi:theme-light-dark',
  FAM_SUN: 'ph:sun-bold',
  FAM_MOON: 'radix-icons:moon',
  FAM_PLAYGROUND: 'svg-spinners:blocks-shuffle-3',
  FAM_PROMPT: 'carbon:prompt-template',
  FAM_CHAT_INFO: 'lucide:badge-info',
  FAM_CHAT_DELETE: 'uiw:delete',
  // 여기부터 svg icon component 사용
  MS_WORD_FILE: SVG_MS_WORD_FILE,
  EXCEL_FILE: SVG_EXCEL_FILE,
  PDF_FILE: SVG_PDF_FILE,
  ACCIO_CHARAC: SVG_ACCIO_CHARAC,
  DASHBOARD: SVG_DASHBOARD,
  EXCLAMATION: SVG_EXCLAMATION,
  STAR_YELLOW: SVG_STAR_YELLOW,
  ASSIGN_CURSOR: SVG_ASSIGNED_CURSOR,
  ASSIGN_CURSOR_WHITE: SVG_ASSIGNED_CURSOR_WHITE,
  AUTO_ADD: SVG_AUTO_ADD,
  QESTION: SVG_QESTION,
  ACCIO_CRY: SVG_ACCIO_CRY,
  TICKMARK_PDF: SVG_TICKMARK_PDF,
  CCK_BANK_OUTLINE: SVG_CCK_BANK_OUTLINE,
  ACCIO_SMILE: SVG_ACCIO_SMILE,
  PROJECT_RROM: SVG_PROJECT_ROOM,
  SHARE: SVG_SHARE,
  FOLDER_OUTLINSE1: SVG_FOLDER_OUTLINE1,
  DSD_FILE: SVG_DSD_FILE,
  IMAGE_FILE: SVG_IMAGE_FILE,
  KEY_SECURITY: SVG_KEY_SECURITY,
  CERTIFICATE_MONITER: SVG_CERTIFICATE_MONITER,
  CLOCK: SVG_CLOCK,
  TICKMARK_EDIT: SVG_TICKMARK_EDIT,
  ZIP_FILE: SVG_ZIP_FILE,
  HWP_FILE: SVG_HWP_FILE,
  ETC_FILE: SVG_ETC_FILE,
  VERSION_FILL_1: SVG_VERSION_FILL_1,
  TRASH_FILL1: SVG_TRASH_FILL1,
  PRINTER: SVG_PRINTER,
  HTML_FILE: SVG_HTML_FILE,
  SVG_TXT_FILE,
  XLSX_WHITE_FILE: SVG_XLSX_WHITE_FILE,
  XLSX_GREEN_FILE: SVG_XLSX_GREEN_FILE,
  DOCX_WHITE_FILE: SVG_DOCX_WHITE_FILE,
  DOCX_BLUE_FILE: SVG_DOCX_BLUE_FILE,
  PPTX_FILE: SVG_PPTX_FILE,
  PPTX_GREY_FILE: SVG_PPTX_GREY_FILE,
  DOCX_ORIGIN_GREY_FILE: SVG_DOCX_ORIGIN_GREY_FILE,
  XLSX_ORIGIN_GREY_FILE: SVG_XLSX_ORIGIN_GREY_FILE,
  XLSX_ORIGIN_FILE: SVG_XLSX_ORIGIN_FILE,
  FOLDER_FILL2: SVG_FOLDER_FILL2,
  EMPTY_DATA: SVG_EMPTY_DATA,
  EMPTY_DATA_BLUE: SVG_EMPTY_DATA_BLUE,
  INSTALL: SVG_INSTALL,
  REPLACE: SVG_REPLACE,
  DOCUMENT: SVG_DOCUMENT,
  YOUTUBE: SVG_YOUTUBE,
  EDIT: SVG_EDIT,
  GROUP_PEOPLE: SVG_GROUP_PEOPLE,
  PERSON: SVG_PERSON,
  VERSIONS: SVG_VERSIONS,
  NO_ROOM: SVG_NO_ROOM,
  SUGGESTED_SYMBOL_X: SVG_SUGGESTED_SYMBOL_X,
  COPY_TWOTONE: SVG_COPY_TWOTONE,
  EDIT_PIN: SVG_EDIT_PIN,
  SUGGESTED_CIRCLE_PLUS: SVG_SUGGESTED_CIRCLE_PLUS,
  UNLINKED: SVG_UNLINKED,
  DOCUMENT_REFERENCE: SVG_DOCUMENT_REFERENCE,
  DSD_FOLDER_ITEM: SVG_DSD_FOLDER_ITEM,
  MOVE_NEXT: SVG_MOVE_NEXT,
  EDIT_WRITE: SVG_EDIT_WRITE,
  EMPTY_FOLDER: SVG_EMPTY_FOLDER,
  TRASH_OUTLINE: SVG_TRASH_OUTLINE,
  PASTE: SVG_PASTE,
  BUBBLE_COMMENT: SVG_BUBBLE_COMMENT,
  DOLLAR_OUTLINED: SVG_DOLLAR_OUTLINED,
  KEYBOARD_CLOSE_OUTLINE: SVG_KEYBOARD_CLOSE_OUTLINE,
  BASELINE_CARD_MEMBERSHIP: SVG_BASELINE_CARD_MEMBERSHIP,
  MONEY: SVG_MONEY,
  DOOR_CLOSED_LOCK: SVG_DOOR_CLOSED_LOCK,
  GLOBAL_OUTLINE: SVG_GLOBAL_OUTLINE,
  BOX_LINE: SVG_BOX_LINE,
  GRAPH_LINE: SVG_GRAPH_LINE,
  BETA_LINE: SVG_BETA_LINE,
  MANAGE_ACCOUNTS_OUTLINE: SVG_MANAGE_ACCOUNTS_OUTLINE,
  ID_CARD: SVG_ID_CARD,
  DICTIONARY_ROUNDED: SVG_DICTIONARY_ROUNDED,
  TEXT_MANUAL: SVG_TEXT_MANUAL,
  VIDEO_MANUAL: SVG_VIDEO_MANUAL,
  DSD_DIFF: SVG_DSD_DIFF,
  VIDEO_YOUTUBE: SVG_VIDEO_YOUTUBE,
  SCOURT_BALANCE: SVG_SCOURT_BALANCE,
  CUT: SVG_CUT,
  FILE_ADD: SVG_FILE_ADD,
  ROTATE_LEFT: SVG_ROTATE_LEFT,
  ROTATE_RIGHT: SVG_ROTATE_RIGHT,
  PDF_EDITOR_EMPTY: SVG_PDF_EDITOR_EMPTY,
  DOCUMENT_WHITE: SVG_DOCUMENT_WHITE,
  ADD_BLACK_CIRCLE: SVG_ADD_BLACK_CIRCLE,
  SCISSORS: SVG_SCISSORS,
  SESSION_CHECK: SVG_SESSION_CHECK,
  WETAX_LOGO: SVG_WETAX_LOGO,
  STOCK_VOLATILITY: SVG_STOCK_VOLATILITY,
  HORIZONTAL_SCROLL: SVG_HORIZONTAL_SCROLL,
  NOTIFICATION: SVG_NOTIFICATION,
  MAIL_SEND_FILL_1: SVG_MAIL_SEND_FILL_1,
  ROUND_PEOPLE_1: SVG_ROUND_PEOPLE_1,
  DART: SVG_DART,
  MANUAL_TIP: SVG_MANUAL_TIP,
  MANUAL_ERROR: SVG_MANUAL_ERROR,
  ALERT_FILL_1: SVG_ALERT_FILL_1,
  BCL_PREVIEW: SVG_BCL_PREVIEW,
  VIDEO_MANUAL_WHITE: SVG_VIDEO_MANUAL_WHITE,
  BCL_UPLOAD_ICON: SVG_BCL_UPLOAD_ICON,
  POST_PDF: SVG_POST_PDF,
  SQUARE_ARROW_DOWN: SVG_SQUARE_ARROW_DOWN,
  SQUARE_ARROW_UP: SVG_SQUARE_ARROW_UP,
  SQUARE_SPACE: SVG_SQUARE_SPACE,
  SQUARE_ENTER: SVG_SQUARE_ENTER,
  SQUARE_BACKSPACE: SVG_SQUARE_BACKSPACE,
  CURSOR_ARROW: SVG_CURSOR_ARROW,
  BOOK_CONTENT: SVG_BOOK_CONTENT,
  SUBMIT: SVG_SUBMIT,
  ACCIO_CHAT: SVG_ACCIO_CHAT,
  ACCIO_CHAT_EXAMPLE_1: SVG_ACCIO_CHAT_EXAMPLE_1,
  ACCIO_CHAT_EXAMPLE_2: SVG_ACCIO_CHAT_EXAMPLE_2,
  ACCIO_CHAT_EXAMPLE_3: SVG_ACCIO_CHAT_EXAMPLE_3,
  ACCIO_CHAT_EXAMPLE_4: SVG_ACCIO_CHAT_EXAMPLE_4,
  STAR_REWARD: SVG_STAR_REWARD,
  CHAT_ADD: SVG_CHAT_ADD,
  FILTER: SVG_FILTER,
  FAM_NEW_CHAT: SVG_FAM_NEW_CHAT
  // svg 끝
} as const

export type IconType = typeof IconType[keyof typeof IconType]

export type IconBaseComponentProps = {
  iconType: IconType
  size?: number | [number, number]
  // TODO (hyeonseok 23.03.30):
  // rename 'enable' to 'state'
  enable?: IconStateType
  rotate?: number
  className?: string
  clickCallbackFunc?: () => void
  tooltip?: string
  tooltipProps?: TooltipProps
  isSvg?: boolean
  timer?: boolean
  flip?: 'horizontal' | 'vertial'
}

export type IconComponentProps = Omit<IconBaseComponentProps, 'iconName'>
