import React, { useImperativeHandle, forwardRef } from 'react'

import { CCKBasicAgGridCellEditorComponentProps } from 'components/ccktable/control/CCKBasicAgGridCellEditorComponent'

const OnlyPasteAgGridCellEditorComponent = forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  CCKBasicAgGridCellEditorComponentProps
>((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      // 편집이 시작되기 전에 한번 호출됨. 편집을 취소할 수 있는 기회를 주기 위함
      // true 반환 시 편집 취소
      isCancelBeforeStart() {
        // eslint-disable-next-line no-console
        console.log('isCancelBeforeStart')
        // return false
        return true
      }
    }
  })

  return <></>
})

OnlyPasteAgGridCellEditorComponent.displayName = 'OnlyPasteAgGridCellEditorComponent'

export default OnlyPasteAgGridCellEditorComponent
