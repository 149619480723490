import React from 'react'

import { ChatReference } from 'components/chat/message/ChatMessageReferenceComponent'

interface ChatMessageAnswerRefPointComponentProps {
  index: number
  references: ChatReference[]
  setSelectReferences: (references: ChatReference[]) => void
}

const ChatMessageAnswerRefPointComponent: React.FC<ChatMessageAnswerRefPointComponentProps> = ({
  index,
  references,
  setSelectReferences
}) => {
  return (
    <span
      onClick={() => {
        setSelectReferences([references[index - 1]])
      }}
    >
      {index}
    </span>
  )
}

export default ChatMessageAnswerRefPointComponent
