import {
  ButtonColor,
  ButtonComponent,
  ButtonSize,
  ButtonState,
  IconComponent,
  IconType
} from '@cck/common'
import { Card } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { getMoveReferenceDocument } from 'components/chat/util/ChatUtil'

export interface ChatReference {
  id: string
  index: number
  text: string
  score: string
  metadata: { [key: string]: string }
}

interface ChatMessageReferenceComponentProps {
  references: ChatReference[]
  setSelectReferences: (references: ChatReference[]) => void
}

const ChatMessageReferenceComponent: React.FC<ChatMessageReferenceComponentProps> = ({
  references,
  setSelectReferences
}) => {
  const navigate = useNavigate()

  const makeReference = () => {
    return references.map((ref) => {
      return (
        <Card key={ref.id} size="small">
          <div className="chat-message-reference-content-wrapper">
            <div className="chat-message-reference-content-title-wrapper">
              <div className="chat-message-reference-content-index-wrapper">{`${ref.index}`}</div>
              <div className="chat-message-reference-content-description-wrapper">
                {ref.metadata.title}
              </div>
            </div>
            <div className="chat-message-reference-content-option-wrapper">
              <div className="chat-message-reference-content-option-arrow">
                <IconComponent
                  clickCallbackFunc={() => {
                    const moveProps = getMoveReferenceDocument(ref.metadata)
                    navigate('/standard/link', {
                      state: moveProps
                    })
                  }}
                  iconType={IconType.ARROW_UP_RIGHT}
                  size={20}
                  tooltip="참고 원문 기준서로 보기"
                />
              </div>
              <div
                className="chat-message-reference-content-option-eye"
                onClick={() => {
                  setSelectReferences([ref])
                }}
              >
                <IconComponent iconType={IconType.SOLID_EYE} size={20} tooltip="참고 원문 보기" />
              </div>
            </div>
          </div>
        </Card>
      )
    })
  }

  return (
    <div className="chat-message-reference-wrapper">
      <div className="chat-message-reference-title-wrapper">
        <div className="chat-message-reference-title-left-wrapper">
          <IconComponent iconType={IconType.FILE} size={24} />
          참고원문
        </div>
        <div className="chat-message-reference-title-right-wrapper">
          <div>
            <ButtonComponent
              clickCallbackFunc={() => {
                setSelectReferences(references)
              }}
              color={ButtonColor.SECONDARY}
              rightIcon={<IconComponent iconType={IconType.SOLID_EYE} size={14} />}
              size={ButtonSize.SMALL}
              states={ButtonState.NORMAL}
              text="참고 원문 전체 보기"
            />
          </div>
        </div>
      </div>
      {makeReference()}
    </div>
  )
}

export default ChatMessageReferenceComponent
