import { notEmpty } from '@cck/common'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import { selectedIndexItemAtom, selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import { SearchKeywordCountItemAreaType } from 'components/new_search/NewSearchKeywordCountItemComponent'
import NewSearchKeywordCountListComponent from 'components/new_search/NewSearchKeywordCountListComponent'
import { useSearchResultExist } from 'hook/search/useSearchResultExist'
import { Index } from 'services/standard_client'

type ContentItemComponentProps = {
  currentIndexItem?: Index
  listViewDepth?: number
}

const ContentItemComponent: React.FC<ContentItemComponentProps> = ({
  currentIndexItem,
  listViewDepth
}) => {
  const [selectedIndexItem, setSelectedIndexItem] = useRecoilState(selectedIndexItemAtom)
  const isSearchResultExist = useSearchResultExist(false, currentIndexItem?.id)
  const selectedStandardId = useRecoilValue(selectedStandardIdAtom)
  const selectedOneDepthIndexItem = useRecoilValue(selectedOneDepthIndexItemAtom)

  const navigate = useNavigate()

  const handleItemClick = () => {
    if (!currentIndexItem) {
      return
    }

    setSelectedIndexItem(currentIndexItem)

    if (!selectedOneDepthIndexItem) {
      return
    }

    navigate(
      ['', 'standard', selectedStandardId, selectedOneDepthIndexItem.id, currentIndexItem.id].join(
        '/'
      )
    )
  }

  const makeSearchCountItems = () => {
    if (!isSearchResultExist) {
      return null
    }

    if (!currentIndexItem || notEmpty(currentIndexItem.children)) {
      return null
    }

    return (
      <NewSearchKeywordCountListComponent
        childrenOverlap
        indexItemId={currentIndexItem.id}
        itemArea={SearchKeywordCountItemAreaType.LNB}
      />
    )
  }

  const renderContentItem = () => {
    if (!currentIndexItem) {
      return null
    }

    const contentClassName = 'ContentItemLevel-' + listViewDepth
    return (
      <div className={`box-${listViewDepth}`}>
        <div
          className={classNames({
            [`${contentClassName}`]: true,
            SelectedContentItem: selectedIndexItem?.id === currentIndexItem.id,
            SearchResultExist: isSearchResultExist
          })}
          key={currentIndexItem.id}
          onClick={handleItemClick}
        >
          <span
            className={classNames({
              ContentName: listViewDepth && listViewDepth >= 3
            })}
          >
            {currentIndexItem.name}
          </span>
          <span className="ContentSubname"> {currentIndexItem.subname}</span>
          {makeSearchCountItems()}
        </div>
        <div className="ContentItemChildBox">
          {renderChildContentItem(currentIndexItem.children)}
        </div>
      </div>
    )
  }

  const renderChildContentItem = (children: Index[]) => {
    return (
      <>
        {_.map(children, (child) => (
          <ContentItemComponent
            currentIndexItem={child}
            key={`ContentItem-${child.id}`}
            listViewDepth={listViewDepth ? listViewDepth + 1 : 1}
          />
        ))}
      </>
    )
  }

  return renderContentItem()
}

ContentItemComponent.defaultProps = {
  currentIndexItem: undefined,
  listViewDepth: 1
}

export default ContentItemComponent
