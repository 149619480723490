import { LoadingOutlined } from '@ant-design/icons'
import { IconComponent, IconType, isEmpty } from '@cck/common'
import { Spin } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useState } from 'react'

import { ChatMessage } from 'components/chat/message/ChatMessageWrapperComponent'

interface ChatInputComponentProps {
  chatHistory: ChatMessage[]
  recentChat?: ChatMessage
  submitQuestion: (question: string) => void
}

const ChatInputComponent: React.FC<ChatInputComponentProps> = ({
  recentChat,
  submitQuestion,
  chatHistory
}) => {
  const [processCompositionEnter, setProcessCompositionEnter] = useState(false)
  const [inputQuestion, setInputQuestion] = useState<string>('')

  const isChatProcessing = () => {
    if (recentChat) {
      return !recentChat.isDone
    }
    return false
  }

  const handleSubmitQuestion = () => {
    if (isEmpty(inputQuestion)) {
      return
    }
    if (isChatProcessing()) {
      return
    }
    submitQuestion(inputQuestion)
    setInputQuestion('')
  }

  const plageholder =
    '질문을 입력해 주세요. 현재 제공하는 기준서, 지침서 및 해설서 종류는 좌측 책 아이콘을 클릭해 확인 가능합니다.'

  const leftIcon = <IconComponent isSvg iconType={IconType.BOOK_CONTENT} size={24} />

  const getSubmitIcon = () => {
    if (isChatProcessing()) {
      return <Spin indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
    }
    return (
      <IconComponent
        isSvg
        clickCallbackFunc={() => handleSubmitQuestion()}
        iconType={IconType.SUBMIT}
        size={24}
      />
    )
  }

  const questionExample = () => {
    if (!isEmpty(chatHistory) || recentChat) {
      return null
    }
    const questionExamples = [
      {
        icon: IconType.ACCIO_CHAT_EXAMPLE_1,
        text: 'K-IFRS와 K-GAAP의 차이점을 알려줘'
      },
      {
        icon: IconType.ACCIO_CHAT_EXAMPLE_2,
        text: '중소기업 내부회계 시 주의할 점을 알려줘'
      },
      {
        icon: IconType.ACCIO_CHAT_EXAMPLE_3,
        text: 'K-IFRS를 준수하는 탄소배출권 주석 작성법을 알려줘'
      },
      {
        icon: IconType.ACCIO_CHAT_EXAMPLE_4,
        text: '디플레이션 경제에서 재무보고 시 유의할 점이 뭐야?'
      }
    ]
    const renderQuestion = (iconType: IconType, text: string) => {
      return (
        <div className="chat-input-example" onClick={() => setInputQuestion(text)}>
          <IconComponent isSvg iconType={iconType} size={16} />
          {text}
        </div>
      )
    }
    return (
      <div className="chat-input-question-example">
        <div className="chat-input-question-example-header">
          <IconComponent isSvg iconType={IconType.ACCIO_CHAT} size={18} />
          아씨오에게 질문해 보세요.
        </div>
        <div className="chat-input-question-example-content">
          {questionExamples.map((question) => {
            return renderQuestion(question.icon, question.text)
          })}
        </div>
      </div>
    )
  }
  return (
    <div className="chat-input-wrapper">
      <div className="chat-input-editor">
        <TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          // bordered={false}
          className="chat-input"
          placeholder={plageholder}
          value={inputQuestion}
          onChange={(e) => {
            if (processCompositionEnter) {
              return
            }

            setInputQuestion(e.target.value)
          }}
          onPressEnter={(e) => {
            if (e.shiftKey) {
              return
            }

            setProcessCompositionEnter(e.nativeEvent.isComposing)
            e.preventDefault()
            e.stopPropagation()

            if (processCompositionEnter) {
              return
            }

            if (isEmpty(inputQuestion)) {
              return
            }

            if (e.shiftKey) {
              return
            }

            handleSubmitQuestion()
          }}
        />
        <div className="chat-input-left-icon">{leftIcon}</div>
        <div className="chat-input-right-icon">{getSubmitIcon()}</div>
      </div>

      {questionExample()}
    </div>
  )
}

export default ChatInputComponent
