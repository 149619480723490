import { Column, RowNode } from 'ag-grid-community'
import React from 'react'

import ButtonComponent, { ButtonComponentProps } from 'components/button/ButtonComponent'
import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'

interface DoubleButtonAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  leftButtonComponentProps: ButtonComponentProps
  rightButtonComponentProps: ButtonComponentProps
  leftButtonClickCallbackFunc?: (node: RowNode, column: Column) => void
  rightButtonClickCallbackFunc?: (node: RowNode, column: Column) => void
}

const DoubleButtonAgGridCellRendererComponent: React.FC<
  DoubleButtonAgGridCellRendererComponentProps
> = ({
  leftButtonComponentProps,
  rightButtonComponentProps,
  leftButtonClickCallbackFunc,
  rightButtonClickCallbackFunc,
  node,
  column
}) => {
  const leftButtonClick = () => {
    leftButtonClickCallbackFunc && leftButtonClickCallbackFunc(node, column)
  }

  const rightButtonClick = () => {
    rightButtonClickCallbackFunc && rightButtonClickCallbackFunc(node, column)
  }

  leftButtonComponentProps.clickCallbackFunc = leftButtonClick
  rightButtonComponentProps.clickCallbackFunc = rightButtonClick

  return (
    <div className="cck-table-button-cell-frame">
      <div className="cck-table-button-cell-double">
        <ButtonComponent {...leftButtonComponentProps} />
      </div>
      <div className="cck-table-button-cell-double">
        <ButtonComponent {...rightButtonComponentProps} />
      </div>
    </div>
  )
}

export default DoubleButtonAgGridCellRendererComponent
