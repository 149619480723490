/* eslint-disable no-unused-vars */
import { Column, RowNode } from 'ag-grid-community'
import classNames from 'classnames'
import React from 'react'

import { isEmpty } from 'common/utils'
import { CommonAgGridCellRendererProps } from 'components/ccktable/control/AgGridTypes'
import {
  RefinedLabelType,
  getDefaultLabelAlign,
  refinedLabelValue
} from 'components/ccktable/util/CCKTableUtil'

export enum LabelAgGridTypoStyle {
  SUBHEAD_2 = 'subhead2',
  BODY_1 = 'body1'
}

export enum LabelAhGridColor {
  GRAY = 'gray', // gray 400
  BLUE = 'blue', // blue 500
  RED = 'red', // red 500
  AMBER = 'amber', // amber 500
  GREEN = 'green', // green 500
  BLACK = 'black' // gray 800
}

export enum LabelTextAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

interface LabelAgGridCellRendererComponentProps extends CommonAgGridCellRendererProps {
  typoStyle?: LabelAgGridTypoStyle
  color?: LabelAhGridColor
  refinedValueType?: RefinedLabelType
  value: string
  placeholder?: string | ((node: RowNode, column: Column) => string | undefined)
  postfixPlaceholder?: string
  labelTextAlign?: LabelTextAlign
  getLabelClassNamesCallbackFunc?: (node: RowNode, column: Column) => LabelAhGridColor | undefined
  convertValueCallbackFunc?: (value: string, node?: RowNode) => string
  isGroupLabel?: boolean
  isTextOverflowEllipsis?: boolean
  appendPostfixCallbackFunc?: (value: string, node?: RowNode) => string
}

const LabelAgGridCellRendererComponent: React.FC<LabelAgGridCellRendererComponentProps> = ({
  typoStyle = LabelAgGridTypoStyle.BODY_1,
  node,
  column,
  refinedValueType = 'NONE',
  value,
  color,
  placeholder,
  postfixPlaceholder,
  getLabelClassNamesCallbackFunc,
  convertValueCallbackFunc,
  eGridCell,
  labelTextAlign,
  isGroupLabel = false,
  isTextOverflowEllipsis = false,
  appendPostfixCallbackFunc
}) => {
  const labelClassNames = classNames({
    'cck-renderer-common': true,
    'cck-table-label': true,
    [`cck-table-label-${typoStyle}-${
      color ||
      (getLabelClassNamesCallbackFunc && getLabelClassNamesCallbackFunc(node, column)) ||
      LabelAhGridColor.BLACK
    }`]: true,
    [`cck-table-label-align-${labelTextAlign || getDefaultLabelAlign(refinedValueType)}`]: true,
    'cck-table-label-group': isGroupLabel
  })

  let placeholderText: string | undefined
  if (typeof placeholder === 'function') {
    placeholderText = placeholder(node, column) ?? ''
  } else {
    placeholderText = placeholder
  }

  if (isEmpty(value) && !isEmpty(placeholderText)) {
    return (
      <div className={labelClassNames} style={{ width: eGridCell.clientWidth }}>
        <span className="cck-table-label-placeholder">{placeholderText}</span>
      </div>
    )
  }

  const getValue = () => {
    const newValue = refinedLabelValue(
      convertValueCallbackFunc ? convertValueCallbackFunc(value, node) : value,
      refinedValueType
    ).toString()
    return appendPostfixCallbackFunc ? appendPostfixCallbackFunc(newValue, node) : newValue
  }

  // placeholder를 끄려면 class name을 부여하여 display none 적용
  return (
    <div className={labelClassNames} style={isGroupLabel ? {} : { width: eGridCell.clientWidth }}>
      <div
        className={isTextOverflowEllipsis ? 'cck-table-label-overflow-ellipsis' : ''}
        dangerouslySetInnerHTML={{ __html: getValue().replace(/\n/g, '<br>') }}
      />
      <span className="cck-table-label-placeholder">{postfixPlaceholder}</span>
    </div>
  )
}

export default LabelAgGridCellRendererComponent
