import React from 'react'

interface LabelProps {
  value: string
  color?: string
  classNames?: string
}

// Common Label Component
const LabelComponent: React.FC<LabelProps> = ({ value, color, classNames }) => {
  return (
    <div className={classNames} style={{ color }}>
      {value}
    </div>
  )
}

export default LabelComponent
