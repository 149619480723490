import { Icon } from '@iconify/react'
import { Tooltip, type TooltipProps } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { IconBaseComponentProps } from 'components/icon/IconBaseType'

import './icon.scss'

const IconComponent: React.FC<IconBaseComponentProps> = ({
  iconType,
  size,
  enable,
  rotate,
  className,
  clickCallbackFunc,
  tooltip,
  tooltipProps = {},
  isSvg,
  timer = false,
  flip
}) => {
  const [isDisabled, setIsDisabled] = useState(false)

  const handleClick = () => {
    !isDisabled && clickCallbackFunc && clickCallbackFunc()
    if (timer) {
      setIsDisabled(true)

      // 0.5초 후에 버튼을 다시 활성화
      setTimeout(() => {
        setIsDisabled(false)
      }, 500)
    }
  }

  const defaultTooltipProps: TooltipProps = {
    placement: 'bottom',
    trigger: 'hover'
  }

  const [svgData, setSvgData] = useState('')
  useEffect(() => {
    if (!isSvg) {
      return
    }

    setSvgData(iconType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconType])

  const iconClass = `icon-${enable}`
  const resultClass = classNames({
    'cck-base-icon': true,
    [iconClass]: true,
    [className || '']: true,
    'cck-base-icon-clickable': !!clickCallbackFunc
  })
  const getStyleInfo = () => {
    const width = size ? (typeof size === 'number' ? size : size[0]) : undefined
    const height = size ? (typeof size === 'number' ? size : size[1]) : undefined
    return { width, height }
  }

  const renderIcon = () => {
    return (
      <Icon
        className={resultClass}
        flip={flip}
        icon={iconType}
        rotate={rotate}
        style={getStyleInfo()}
        onClick={handleClick}
      />
    )
  }

  const renderSVGIcon = () => {
    const sizeInfo = getStyleInfo()
    const sizeStyle = `${sizeInfo.width !== undefined ? `width: ${sizeInfo.width}px;` : ''} ${
      sizeInfo.height !== undefined ? `height: ${sizeInfo.height}px;` : ''
    }`
    const svgDataWithStyle = svgData.replace(
      /<svg/,
      `<svg class="${resultClass}" style="${sizeStyle}"`
    )
    return (
      <div
        className="cck-base-svg-icon-wrapper"
        dangerouslySetInnerHTML={{ __html: svgDataWithStyle }}
        onClick={clickCallbackFunc}
      />
    )
  }

  return (
    <Tooltip
      overlayInnerStyle={{ width: 'max-content' }}
      title={tooltip}
      {...defaultTooltipProps}
      {...tooltipProps}
    >
      {isSvg ? renderSVGIcon() : renderIcon()}
    </Tooltip>
  )
}

export default IconComponent
