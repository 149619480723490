import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { CCKBasicAgGridCellEditorComponentProps } from 'components/ccktable/control/CCKBasicAgGridCellEditorComponent'
import {
  checkValueIsUnique,
  getAllRowDatas,
  showFlashCells,
  showFlashCellsSimple
} from 'components/ccktable/util/CCKTableUtil'
import NewAutoCompleteInputComponent from 'components/input/NewAutoCompleteInputComponent'
import { NewInputState } from 'components/input/NewInputControlComponent'
import { NewInputControlType, useNewInputControl } from 'components/input/useNewInputControl'

interface AutoCompleteAgGridCellEditorComponentProps
  extends CCKBasicAgGridCellEditorComponentProps {
  autoCompleteFunc?: (text: string) => Promise<React.ReactNode[]>
  isDropdownSelect?: boolean // dropdown select한 경우만 반영할지 여부
}

const AutoCompleteAgGridCellEditorComponent = forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  AutoCompleteAgGridCellEditorComponentProps
>((props, ref) => {
  const {
    value,
    node,
    column,
    api,
    eGridCell,
    checkUniqueValueInColumn,
    isValidateCheckCallbackFunc,
    refinedInputValueCallbackFunc,
    changeInputValueFilterCallbackFunc,
    autoCompleteFunc,
    maxLength,
    isDropdownSelect = true
  } = props

  // text input value
  const [inputValue, setInputValue] = useState(value)

  // text input valid state
  const [inputValidState, setInputValidState] = useState<NewInputState>('Default')

  const [isCompleteAutoInput, setIsCompleteAutoInput] = useState(false)
  // ag-grid cell editor를 사용하기 위해선 useImperativeHandle을 사용해야 함 + forwardRef
  // 호출 순서
  // Edit 창이 열리면
  // (1) isCancelBeforeStart(true 반환 시 편집 취소) -> (2) afterGuiAttached
  // Enter 입력 시
  // (3) handleInputComplete callbackFunc 실행. 내부에서 api.stopEditing() 호출로 편집 종료 call
  // Edit 창이 닫히면
  // (4) isCancelAfterEnd(true 반환 시 편집 결과 무시) -> (5) getValue(반환된 수정 값을 Cell에 반영)
  useImperativeHandle(ref, () => {
    return {
      // 편집이 시작되기 전에 한번 호출됨. 편집을 취소할 수 있는 기회를 주기 위함
      // true 반환 시 편집 취소
      isCancelBeforeStart() {
        // eslint-disable-next-line no-console
        console.log('isCancelBeforeStart')
        return false
      },
      // 이 컴포넌트가 만들어져 Dom에 붙고 난 후 호출됨
      afterGuiAttached() {
        // eslint-disable-next-line no-console
        console.log('afterGuiAttached')
        // cell edit이 시작되면 실제 dom input에 focus를 줘 캐럿이 생기도록 함
        eGridCell.querySelector<HTMLInputElement>('.cck-input')?.focus()
      },

      // 편집이 완료되면 한번 호출됨(ex. Enter 입력 등). 편집 결과를 무시하고 싶을 때 사용
      // true 반환 시 편집 결과 무시
      isCancelAfterEnd() {
        // eslint-disable-next-line no-console
        console.log('isCancelAfterEnd')

        // 입력 값이 이전과 동일한 경우, 편집 취소
        if (inputValue === value) {
          return true
        }

        return !isValidateInput()
      },

      // Edit 최종 결과값을 반환
      getValue() {
        // eslint-disable-next-line no-console
        console.log('getValue')
        // 최종 입력값 보정

        const result = refinedInputValueCallbackFunc
          ? refinedInputValueCallbackFunc(inputValue, node, column)
          : inputValue
        showFlashCellsSimple({ api, rowNodes: [node] })
        return result
      }
    }
  })

  const handleInputChange = (input: string) => {
    // Valid State 초기화
    inputValidState !== 'Default' && setInputValidState('Default')
    setInputValue(
      changeInputValueFilterCallbackFunc
        ? changeInputValueFilterCallbackFunc(input, node, column)
        : input
    )

    setIsCompleteAutoInput(false)
  }

  const completeAutoInputFunc = (text: string, option?: any) => {
    setInputValue(text)
    node.setDataValue(column.getColId(), text)
    setIsCompleteAutoInput(true)
  }

  const isValidateInput = () => {
    // uniuqe check를 수행할지 여부
    if (isDropdownSelect && !isCompleteAutoInput) {
      return false
    }
    if (
      checkUniqueValueInColumn &&
      !checkValueIsUnique(
        // 현재 자기 자신의 row data를 제외하고 전부 검사
        getAllRowDatas(api)
          .filter((rowData) => rowData.rowIndex !== node.rowIndex)
          .map((rowData) => rowData.data),
        column.getColId(),
        inputValue
      )
    ) {
      // unique check를 통과하지 못한 경우
      // NOTE (hyeonseok 23.09.05): callback function 필요할 수 있음
      showFlashCells({ api, rowNodes: [node], targetElement: eGridCell, flashType: 'error' })

      return false
    }

    // validation check를 수행할지 여부
    if (
      isValidateCheckCallbackFunc &&
      !isValidateCheckCallbackFunc(inputValue, node, column, api)
    ) {
      // validationi check를 통과하지 못한 경우
      showFlashCells({ api, rowNodes: [node], targetElement: eGridCell, flashType: 'error' })
      return false
    }

    return true
  }
  const inputControlTypeProps = useNewInputControl(NewInputControlType.NONE)

  return (
    <div style={{ width: eGridCell.clientWidth }}>
      <NewAutoCompleteInputComponent
        autoCompleteFunc={autoCompleteFunc}
        blurInputFunc={() => api.stopEditing()}
        changeInputFunc={handleInputChange}
        className="cck-table-auto-complete-input"
        completeAutoInputFunc={completeAutoInputFunc}
        initText={inputValue}
        inputControlTypeProps={inputControlTypeProps}
        maxLength={maxLength}
      />
    </div>
  )
})

AutoCompleteAgGridCellEditorComponent.displayName = 'CCKBasicAgGridCellEditorComponent'

export default AutoCompleteAgGridCellEditorComponent
